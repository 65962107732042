import React from 'react';

const SvgTransport14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 1a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1h10zm1 9H2V7h12zm0 5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm-9 0a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm10.27-9.73l-.54-.54A2.49 2.49 0 0 0 13 4H3a2.49 2.49 0 0 0-1.77.73l-.54.54A2.49 2.49 0 0 0 0 7v12a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h10v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a2.49 2.49 0 0 0-.73-1.73z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport14 svgRef={ref} {...props} />);
export default ForwardRef;
