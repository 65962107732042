import React from 'react';

const SvgOther5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 12h-5a4 4 0 0 0-2 .54V4a2 2 0 0 1 2-2h5zm-9 .54A4 4 0 0 0 7 12H2V2h5a2 2 0 0 1 2 2zM19 0h-6a4 4 0 0 0-3 1.37A4 4 0 0 0 7 0H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h5.59a1 1 0 0 1 .7.29l.42.42a1 1 0 0 0 .7.29h3.18a1 1 0 0 0 .7-.29l.42-.42a1 1 0 0 1 .7-.29H19a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther5 svgRef={ref} {...props} />);
export default ForwardRef;
