import React from 'react';

const SvgDevices39 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 10.34a2 2 0 0 1-.59 1.42l-1.8 1.8a1.5 1.5 0 0 1-1.06.44h-3.1a1.5 1.5 0 0 1-1.06-.44l-1.8-1.8A2 2 0 0 1 3 10.34V8h10zM15.5 6H13V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1v2.34a4 4 0 0 0 1.17 2.83l1.95 2A3 3 0 0 0 6 16v3.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V16a3 3 0 0 0 1.88-.86l1.95-2a4 4 0 0 0 1.17-2.8V8h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices39 svgRef={ref} {...props} />);
export default ForwardRef;
