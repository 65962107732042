import React from 'react';

const SvgActions26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.35 7.73l-5.62 5.62a.48.48 0 0 1-.7 0L5.65 11a.5.5 0 0 1 0-.71l.53-.53a.48.48 0 0 1 .7 0l1.5 1.49 4.74-4.74a.5.5 0 0 1 .7 0l.53.53a.5.5 0 0 1 0 .69zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions26 svgRef={ref} {...props} />);
export default ForwardRef;
