import React from 'react';

const SvgActions46 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3 15.5V13H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H4a1 1 0 0 1 1 1v3.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm13.5.5h-1a.5.5 0 0 1-.5-.5V12a1 1 0 0 1 1-1h3.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H17v2.5a.5.5 0 0 1-.5.5zM.5 5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 3H3V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V4a1 1 0 0 1-1 1zM15 4V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V3h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H16a1 1 0 0 1-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions46 svgRef={ref} {...props} />);
export default ForwardRef;
