import React from 'react';

const SvgAbstract61 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 4" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6-4a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract61 svgRef={ref} {...props} />);
export default ForwardRef;
