import React from 'react';

const SvgActions119 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 4.724a7.984 7.984 0 0 1-.462 11.052 8.007 8.007 0 0 1-11.075 0A7.984 7.984 0 0 1 2 4.724a.51.51 0 0 1 .74-.02l.71.71a.509.509 0 0 1 0 .679A5.909 5.909 0 0 0 2 9.988a5.996 5.996 0 0 0 6 5.993c3.314 0 6-2.683 6-5.993a5.909 5.909 0 0 0-1.44-3.895.509.509 0 0 1 0-.68l.71-.709a.51.51 0 0 1 .37-.15.53.53 0 0 1 .36.17zm-7 5.763V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v9.987a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions119 svgRef={ref} {...props} />);
export default ForwardRef;
