import React from 'react';

const SvgDevices54 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.85 8.722l-1.68-1.6H3.5a.5.5 0 0 0-.5.5v.76a.5.5 0 0 0 .5.5h4a.48.48 0 0 0 .31-.12zm12 10.28l-.53.53a.5.5 0 0 1-.7 0l-3.19-3.19a2 2 0 0 1-1.43.66H2a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2L.44 1.382a.48.48 0 0 1 0-.7L1 .152a.48.48 0 0 1 .7 0l18.15 18.18a.5.5 0 0 1 0 .67zM7 3.002h7a2 2 0 0 1 2 2v3l2.71-2.71a1 1 0 0 1 .7-.29h.09a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.09a1 1 0 0 1-.7-.29z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices54 svgRef={ref} {...props} />);
export default ForwardRef;
