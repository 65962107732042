import React from 'react';

const SvgCommunication26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.5 11.81l-1 1a.75.75 0 0 1-1.06 0l-1-1C7.38 10.69 5 8.5 5 6.75A2.75 2.75 0 0 1 7.75 4 3.18 3.18 0 0 1 10 5a3.2 3.2 0 0 1 2.25-1A2.75 2.75 0 0 1 15 6.75c0 1.75-2.38 3.94-3.5 5.06zM20 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5.09a1 1 0 0 1 .7.29l1.49 1.49c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22l1.49-1.49a1 1 0 0 1 .7-.29H18a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication26 svgRef={ref} {...props} />);
export default ForwardRef;
