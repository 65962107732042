import React from 'react';

const SvgAlerts1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.772 10.78a.25.25 0 0 1-.25.22H4.468a.25.25 0 0 1-.249-.22L3.82 7.56A.501.501 0 0 1 4.318 7h1.354a.497.497 0 0 1 .498.56zM6 14.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM10 4a2 2 0 0 0-2-2H7V1a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts1 svgRef={ref} {...props} />);
export default ForwardRef;
