import React from 'react';

const SvgDevices33 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.5 5h-1a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm4.5 8a5 5 0 0 1-10 0V7a5 5 0 0 1 10 0zM7 0a7 7 0 0 0-7 7v6a7 7 0 0 0 14 0V7a7 7 0 0 0-7-7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices33 svgRef={ref} {...props} />);
export default ForwardRef;
