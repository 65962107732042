import React from 'react';

const SvgAbstract74 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M8 16.5v1a.5.5 0 0 1-.5.5H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2v14h5.5a.5.5 0 0 1 .5.5zm10-7.69v.38a.75.75 0 0 1-.22.53l-5.14 5.13a.5.5 0 0 1-.71 0l-.71-.7a.5.5 0 0 1 0-.71L14.67 10H4.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h10.17l-3.45-3.44a.49.49 0 0 1 0-.7l.71-.71a.5.5 0 0 1 .71 0l5.14 5.13a.77.77 0 0 1 .22.53z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract74 svgRef={ref} {...props} />);
export default ForwardRef;
