import React from 'react';

const SvgCommunication46 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 15" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2.979 7.355a.494.494 0 0 1 0-.708l4.02-4.02v-1.21a.505.505 0 0 0-.145-.354L6.646.856a.5.5 0 0 0-.707 0L.148 6.647a.498.498 0 0 0 0 .708l5.79 5.791a.5.5 0 0 0 .708 0l.208-.207A.505.505 0 0 0 7 12.585v-1.209l-4.021-4.02M12.999 4V1.417a.505.505 0 0 0-.145-.354l-.208-.207a.5.5 0 0 0-.707 0L6.148 6.647a.498.498 0 0 0 0 .708l5.79 5.791a.5.5 0 0 0 .708 0l.208-.207a.505.505 0 0 0 .146-.354v-2.584c4.718 0 9.505 2.02 9.972 4.59a.5.5 0 0 0 .49.41h.1a.438.438 0 0 0 .438-.457c-.254-5.863-5.37-10.543-11-10.543" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication46 svgRef={ref} {...props} />);
export default ForwardRef;
