import React from 'react';

const SvgArrows27 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.17 18.082a.5.5 0 0 1 0 .71l-1.06 1.06a.48.48 0 0 1-.7 0l-9.19-9.19a.75.75 0 0 1-.22-.53v-.26c0-.2.08-.39.22-.53L9.41.152a.48.48 0 0 1 .7 0l1.06 1.06a.5.5 0 0 1 0 .71l-8.08 8.08z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows27 svgRef={ref} {...props} />);
export default ForwardRef;
