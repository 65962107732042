import React from 'react';

const SvgActions124 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.98 14.53a.51.51 0 0 1-.71 0l-4-4a.49.49 0 0 1-.15-.36V4.5a.5.5 0 0 1 .5-.5h.76a.5.5 0 0 1 .5.5v5.14l3.63 3.63a.51.51 0 0 1 0 .73zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions124 svgRef={ref} {...props} />);
export default ForwardRef;
