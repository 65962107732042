import K from '../constants';
import restHelpers from '../helpers/restHelper';

const getUserFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}`, {});
const getManageableVehiclesFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles`, {});
const getManageableVehicleDataFn = (userUuid, vehicleId) => restHelpers.withBearer.get(`${K.API_URL}/users​/${userUuid}/direct_manageable/vehicles/${vehicleId}`, {});
const getZuoraHostPageTokenFn = () => restHelpers.withBearer.get(`${K.API_URL}/zuora/token`, {});
const setPaymentMethodDefaultFn = (refId) => restHelpers.withBearer.post(`${K.API_URL}/zuora/default-payment-method`, {data: { refId: refId }});
const subscribeToProductFn = (data) => restHelpers.withBearer.post(`${K.API_URL}/zuora/subscriptions`, {data: data});
const getProductsFn = () => restHelpers.withBearer.get(`${K.API_URL}/products`, {});

export default {
  getUserData: getUserFn,
  getUserManageableVehicles: getManageableVehiclesFn,
  getUserManageableVehicleData: getManageableVehicleDataFn,
  getZuoraHostedPageToken: getZuoraHostPageTokenFn,
  setPaymentMethodDefault: setPaymentMethodDefaultFn,
  subscribeToProduct: subscribeToProductFn,
  getProducts: getProductsFn
};
