import React from 'react';

const SvgDevices43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 14.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm-10 0a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM19 10h-1.15l-.08-5.51a.51.51 0 0 0-.5-.49h-.55a.51.51 0 0 0-.5.49L16.14 10H3.9L3.77.49a.51.51 0 0 0-.5-.49h-.55a.51.51 0 0 0-.5.49L2.09 10H1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices43 svgRef={ref} {...props} />);
export default ForwardRef;
