import React from 'react';

const SvgCommunication44 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M0 14.998v-8.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6.08L14.44.148a.5.5 0 0 1 .71 0l.7.7a.5.5 0 0 1 0 .71L3.41 13.998H9.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H1a.75.75 0 0 1-.53-.22l-.27-.27a.75.75 0 0 1-.2-.51z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication44 svgRef={ref} {...props} />);
export default ForwardRef;
