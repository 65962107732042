import React from 'react';

const SvgActions11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 2v17a1 1 0 0 1-1.53.85l-5.94-3.72a1 1 0 0 0-1.06 0l-5.94 3.72A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-2 0H2v15.19l4.41-2.75a3 3 0 0 1 3.18 0L14 17.19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions11 svgRef={ref} {...props} />);
export default ForwardRef;
