import React from 'react';

const SvgOther25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.996 14.001a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm8.18-4.33a4 4 0 0 0-3.8-6.58.51.51 0 0 1-.58-.34 4 4 0 0 0-7.6 0 .51.51 0 0 1-.58.34 4 4 0 0 0-3.8 6.58.49.49 0 0 1 0 .66 4 4 0 0 0 3.8 6.58.51.51 0 0 1 .58.34 4 4 0 0 0 7.6 0 .51.51 0 0 1 .58-.34 4 4 0 0 0 3.8-6.58.49.49 0 0 1 0-.66z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther25 svgRef={ref} {...props} />);
export default ForwardRef;
