import React from 'react';

const SvgAbstract66 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M15.5 2H11V1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v1H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-4.41 12.86a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0L8 14.24l-1.86 1.85a.48.48 0 0 1-.7 0l-.53-.53a.48.48 0 0 1 0-.7L6.76 13l-1.85-1.86a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0L8 11.76l1.86-1.85a.48.48 0 0 1 .7 0l.53.53a.48.48 0 0 1 0 .7L9.24 13zM1 6l.87 12.14a2 2 0 0 0 2 1.86h8.28a2 2 0 0 0 2-1.86L15 6z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract66 svgRef={ref} {...props} />);
export default ForwardRef;
