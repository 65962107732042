import React from 'react';

const SvgPremium14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.02 0c5.2 0 9.474 3.961 9.97 9.031a2.003 2.003 0 0 1-.577 1.613l-8.768 8.769a2.011 2.011 0 0 1-1.614.577C3.962 19.494 0 15.22 0 10.019 0 4.486 4.486 0 10.02 0zm7.229 9.98h-1.268c-3.309 0-6 2.692-6 6v1.265z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium14 svgRef={ref} {...props} />);
export default ForwardRef;
