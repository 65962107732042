import React from 'react';

const SvgAbstract2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.5 2H11V1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v1H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM12 13.5a.5.5 0 0 1-.5.5H9v2.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V14H4.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H7V9.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V12h2.5a.5.5 0 0 1 .5.5zM1 6l.87 12.14a2 2 0 0 0 2 1.86h8.28a2 2 0 0 0 2-1.86L15 6z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract2 svgRef={ref} {...props} />);
export default ForwardRef;
