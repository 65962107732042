import React from 'react';

const SvgTransport1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19 6a5 5 0 1 1-5 5c0-1.131.39-2.162 1.022-3L13 8.003a3.997 3.997 0 0 1-3.09 3.887.01.01 0 0 1-.002.006c-.29.068-.594.104-.905.104H4.5a.498.498 0 0 1-.5-.497v-1a.5.5 0 0 1 .5-.5h3.316A2.994 2.994 0 0 0 5 8c-1.654 0-3 1.346-3 3s1.346 3 3 3c.881 0 1.664-.391 2.209-1h2.346C8.783 14.761 7.046 16 5 16A5 5 0 1 1 5 6h10.383l-1.718-3.44A1.012 1.012 0 0 0 12.76 2H10.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h2.264c1.137 0 2.177.643 2.686 1.66L17.62 6H19m0 8c1.654 0 3-1.346 3-3s-1.346-3-3-3c-.124 0-.242.021-.362.037l1.389 2.776a.35.35 0 0 1-.313.507h-1.21a.75.75 0 0 1-.671-.415l-.99-1.981A2.985 2.985 0 0 0 16 11c0 1.654 1.346 3 3 3" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport1 svgRef={ref} {...props} />);
export default ForwardRef;
