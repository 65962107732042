import React from 'react';

const SvgOther70 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.903 17.63L11.81.37a.76.76 0 0 0-.65-.37h-.3a.76.76 0 0 0-.65.37L.097 17.63a.79.79 0 0 0 0 .76l.14.23a.73.73 0 0 0 .65.38h20.225a.73.73 0 0 0 .65-.38l.14-.23a.79.79 0 0 0 0-.76z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther70 svgRef={ref} {...props} />);
export default ForwardRef;
