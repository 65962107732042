import React from 'react';

const SvgActions2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 10.5a.5.5 0 0 1-.5.5H11v2.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V11H6.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H9V6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V9h2.5a.5.5 0 0 1 .5.5zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions2 svgRef={ref} {...props} />);
export default ForwardRef;
