import React from 'react';

const SvgAbstract55 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M10 15a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM4 6a4 4 0 1 1 8 0v2H4zm10 2V6A6 6 0 1 0 2 6v2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract55 svgRef={ref} {...props} />);
export default ForwardRef;
