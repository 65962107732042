import React from 'react';

const SvgPayment21 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.73 14.6a.5.5 0 0 1-.49.4H7.3a.25.25 0 0 1-.24-.3l.64-3.18-1.38.39a.26.26 0 0 1-.32-.24v-1a.5.5 0 0 1 .36-.48l1.71-.49.85-4.26a.5.5 0 0 1 .49-.4h2.29a.25.25 0 0 1 .24.3l-.69 3.45 1.43-.41a.26.26 0 0 1 .32.24v1a.5.5 0 0 1-.36.48l-1.76.51-.53 2.64h3.35a.25.25 0 0 1 .24.3zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment21 svgRef={ref} {...props} />);
export default ForwardRef;
