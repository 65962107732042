import React from 'react';

const SvgPayment15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.24 13.74a.51.51 0 0 1-.48 0L6.49 12a.09.09 0 0 0-.13 0 .11.11 0 0 0 0 .13l3.18 3.7a.5.5 0 0 0 .38.17h.16a.5.5 0 0 0 .38-.17l3.18-3.7a.11.11 0 0 0 0-.13.09.09 0 0 0-.13 0zm.22-9.5A.51.51 0 0 0 10 4a.51.51 0 0 0-.43.24L6.15 9.79a.26.26 0 0 0 .09.36L9.76 12c.15.08.33.08.48 0l3.52-1.89a.26.26 0 0 0 .09-.36zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment15 svgRef={ref} {...props} />);
export default ForwardRef;
