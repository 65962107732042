import React from 'react';

const SvgArrows31 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 5" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.138 3.942a.5.5 0 0 1 0 .71l-.2.2a.49.49 0 0 1-.36.15h-7.16a.49.49 0 0 1-.36-.15l-.2-.2a.5.5 0 0 1 0-.71l3.79-3.79a.48.48 0 0 1 .7 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows31 svgRef={ref} {...props} />);
export default ForwardRef;
