import React from 'react';

const SvgPayment16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.76 10.13L10.24 12a.51.51 0 0 1-.48 0l-3.52-1.85a.26.26 0 0 1-.09-.36l3.39-5.55A.51.51 0 0 1 10 4a.51.51 0 0 1 .43.24l3.39 5.55a.26.26 0 0 1-.06.36zm-.12 2l-3.18 3.7a.5.5 0 0 1-.38.17h-.16a.5.5 0 0 1-.38-.17l-3.18-3.7a.11.11 0 0 1 0-.13.09.09 0 0 1 .13 0l3.27 1.76c.15.08.33.08.48 0L13.51 12a.09.09 0 0 1 .13 0 .11.11 0 0 1 0 .13zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment16 svgRef={ref} {...props} />);
export default ForwardRef;
