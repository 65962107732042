import React from 'react';

const SvgDevices50 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 15H2V8h6zM3 2h4v4H3zm6 4V2a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v4a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices50 svgRef={ref} {...props} />);
export default ForwardRef;
