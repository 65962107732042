import React from 'react';

const SvgDevices55 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.5 5.88a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v.76a.5.5 0 0 1-.5.5zM16 2v3l2.71-2.71a1 1 0 0 1 .7-.29h.09a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.09a1 1 0 0 1-.7-.29L16 9v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-2 0H2v10h12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices55 svgRef={ref} {...props} />);
export default ForwardRef;
