import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';
import LabelInput from '../../../../../common/components/LabelInput';
import MyPageButton from '../../../../../common/components/MyPageButton';
import MyPageAlert from '../../../../../common/components/MyPageAlert';
import { ErrorIcon } from '../../../../../common/icons';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';

import './RegForm.css';
import RegPrivacyPolicy from '../RegPrivacyPolicy';
import AdditionServiceConditions from '../AdditionServiceConditions/AdditionServiceConditions';
import {emailValidator} from "../../../../../helpers";

@inject('translationsStore', 'moveinStore')
@observer
class RegForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadySubmitted: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ alreadySubmitted: true });

    this.props.form.validateFields((err, values) => {
      if (!err && values.marketingAgreement !== null) {
        this.props.onClickReg(values.email, values.phone, values.marketingAgreement, values.marketingThirdPartiesAgreement);
      }
    });
  };

  handleFreeServiceChoice = () => {
    this.setState({freeServiceSelected: true});
  };

  handleFreeServiceChoiceCancel = () => {
    const { moveinStore } = this.props;

    moveinStore.unsetVehicleAdditionalServiceConsent();
    this.setState({freeServiceSelected: false});
  };

  handleFreeServiceSubmit = () => {
    this.setState({ alreadySubmitted: true });

    this.props.form.validateFields((err, values) => {
      if (!err && values.marketingAgreement !== null) {
        this.props.onClickReg(values.email, values.phone, values.marketingAgreement, values.marketingThirdPartiesAgreement, true);
      }
    });
  };

  getFieldError = field => {
    const { isFieldTouched, getFieldError } = this.props.form;
    return (isFieldTouched(field) || this.state.alreadySubmitted) && getFieldError(field);
  };

  render() {
    const { translationsStore, moveinStore } = this.props,
      { existingData } = moveinStore;
    const { getFieldDecorator, getFieldsValue } = this.props.form;
    const { alreadySubmitted } = this.state;
    let marketingAgreementValue = getFieldsValue(['marketingAgreement'])['marketingAgreement'];
    if (marketingAgreementValue === undefined && !!existingData && existingData.hasOwnProperty('vehicle') && existingData.vehicle.hasOwnProperty('marketing_consent_accepted')) {
      marketingAgreementValue = existingData.vehicle.marketing_consent_accepted;
    }
    const emailError = this.getFieldError('email');
    const phoneError = this.getFieldError('phone');
    const initialThirdParties = undefined;
    const { marketingThirdPartiesAgreement: marketingThirdParties } = getFieldsValue(['marketingThirdPartiesAgreement']);
    const { freeServiceSelected } = this.state;
    const isSubmitWithoutFreeServiceDisabled =
      moveinStore.registered ||
      moveinStore.registering ||
      !(getFieldsValue(['email'])['email'] !== undefined && String(getFieldsValue(['email'])['email']).length > 0) ||
      !(getFieldsValue(['phone'])['phone'] !== undefined && String(getFieldsValue(['phone'])['phone']).length > 0) ||
      marketingAgreementValue === undefined ||
      marketingThirdParties === undefined;
    const isSubmitWithAdditionalServiceDisabled =
      alreadySubmitted ||
      getFieldsValue(['airclub_general'])['airclub_general'] !== true ||
      getFieldsValue(['carefully_read'])['carefully_read'] !== true ||
      getFieldsValue(['air_club_profiling'])['air_club_profiling'] === undefined ||
      getFieldsValue(['air_club_marketing'])['air_club_marketing'] === undefined ||
      getFieldsValue(['air_club_third_parties'])['air_club_third_parties'] === undefined;

    return (
      <div className="content">
        <div className="fields">
          <LoadingOverlay
            active={moveinStore.registering}
            spinner={<PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />}
            styles={{
              overlay: base => ({
                ...base,
                background: 'var(--grey-light-color-50)'
              })
            }}
          >
            <div style={{ marginBottom: 16, fontWeight: 600 }}>{translationsStore.translate('registration.title')}</div>
            <Form className="RegFormUserDataForm" layout="inline">
              <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                {getFieldDecorator(
                  'email',
                  Object.assign(
                    {},
                    {
                      rules: [
                        {
                          validator: (undefined, value) => emailValidator(value),
                          message: translationsStore.translate('registration.form.errorInvalidEmail'),
                        },
                        {
                          required: true,
                          message: translationsStore.translate('registration.form.errorMissingEmail'),
                        },
                      ],
                    },
                    !!existingData && existingData.hasOwnProperty('user') && existingData.user.hasOwnProperty('email') && {
                      initialValue: existingData.user.email
                    }
                  )
                )(<LabelInput placeholder={translationsStore.translate('registration.form.placeholderEmail')} disabled={!!existingData} />)}
              </Form.Item>
              <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                {getFieldDecorator(
                  'phone',
                  Object.assign(
                    {},
                    {
                      rules: [
                        {
                          validator: (rule, value, callback) =>
                              callback(!value.match(/^\+?[0-9]{6,15}$/) ? translationsStore.translate('registration.form.errorInvalidPhoneNumber') : undefined),
                        },
                      ],
                    },
                    !!existingData && existingData.hasOwnProperty('user') && existingData.user.hasOwnProperty('mobile') && {
                      initialValue: existingData.user.mobile
                    }
                  )
                )(<LabelInput placeholder={translationsStore.translate('registration.form.placeholderPhoneNumber')} disabled={!!existingData} />)}
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '0 1 auto' }}>
                <div className="alert">
                  {moveinStore.lastError !== null &&
                    moveinStore.lastError.key === 'registerUserRemoteError' &&
                    (() => {
                      const lastErrorString =
                        moveinStore.lastError.meta !== null
                          ? translationsStore.translate(`movein.stepRegistration.errorRemoteServer.${moveinStore.lastError.meta}`)
                          : translationsStore.translate(`movein.stepRegistration.errorRemoteServer.default`);
                      return (
                        <MyPageAlert
                          style={{ marginBottom: 24 }}
                          message={translationsStore.translate('registration.errors.submit')}
                          description={lastErrorString}
                          type="error"
                          showIcon
                          icon={<ErrorIcon width={20} height={20} />}
                        />
                      );
                    })()}
                </div>

                <RegPrivacyPolicy
                  form={this.props.form}
                  submitted={this.state.alreadySubmitted}
                  marketingValue={getFieldsValue(['marketingAgreement'])['marketingAgreement']}
                  initialValue={
                    !!existingData && existingData.hasOwnProperty('vehicle') && existingData.vehicle.hasOwnProperty('marketing_consent_accepted')
                      ? existingData.vehicle.marketing_consent_accepted
                      : undefined
                  }
                  initialThirdParties={initialThirdParties}
                  marketingThirdParties={marketingThirdParties}
                  disabled={!!existingData && existingData.hasOwnProperty('vehicle') && existingData.vehicle.hasOwnProperty('marketing_consent_accepted')}
                />
                <div className={'freeService600'}>
                  {translationsStore.translate('movein.stepRegistration.freeService.reminderService')}<span className={'freeServiceGreenColor'}>{translationsStore.translate('movein.stepRegistration.freeService.moveinExclusive')}</span> <span className={'freeServiceRedColor'}>0,00€</span>
                </div>
                <div className={'freeService400'}>
                  <span>{translationsStore.translate('movein.stepRegistration.freeService.reminderServiceDescription')}</span><span className={'freeService600'}>{translationsStore.translate('movein.stepRegistration.freeService.reminderServiceDescriptionFeatures')}</span>
                  {' '}<span>{translationsStore.translate('movein.stepRegistration.freeService.reminderServiceDescription2')}</span><span className={'freeService600'}>{translationsStore.translate('movein.stepRegistration.freeService.reminderServiceDescriptionFeatures2')}</span>
                </div>
                {!freeServiceSelected && (
                  <Form.Item className="SubmitContainer">
                  <div className={'ButtonsContainer'}>
                  <MyPageButton
                    theme="brand"
                    solid
                    style={{ height: 55, width: 355, fontSize: 16, fontWeight: 600, margin: 10 }}
                    title={translationsStore.translate('registration.form.submitWithFreeReminderService')}
                    onClick={this.handleFreeServiceChoice}
                    disabled={isSubmitWithoutFreeServiceDisabled}
                  />
                  <MyPageButton
                    theme="gray"
                    solid
                    style={{ height: 55, width: 355, fontSize: 16, fontWeight: 600, margin: 10 }}
                    title={translationsStore.translate('registration.form.submit')}
                    onClick={this.handleSubmit}
                    disabled={isSubmitWithoutFreeServiceDisabled}
                  />
                  </div>
                </Form.Item>
                )}
                {freeServiceSelected && (
                  <AdditionServiceConditions
                    form={this.props.form}
                    onSubmit={this.handleFreeServiceSubmit}
                    onCancelFreeService={this.handleFreeServiceChoiceCancel}
                    isSubmitWithAdditionalServiceDisabled={isSubmitWithAdditionalServiceDisabled}
                    alreadySubmitted={alreadySubmitted}
                  />
                )}
              </Form.Item>
            </Form>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default RegForm;
