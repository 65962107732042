import React from 'react';

const SvgActions28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.35 6.73l-5.62 5.62a.48.48 0 0 1-.7 0L4.65 10a.5.5 0 0 1 0-.71l.53-.53a.48.48 0 0 1 .7 0l1.5 1.49 4.74-4.74a.5.5 0 0 1 .7 0l.53.53a.5.5 0 0 1 0 .69zM16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions28 svgRef={ref} {...props} />);
export default ForwardRef;
