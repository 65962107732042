import React from 'react';

const SvgOther51 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h3v1a2 2 0 0 1-1.61 2 .5.5 0 0 0-.39.49v2a.52.52 0 0 0 .17.38.49.49 0 0 0 .39.12A5 5 0 0 0 18 9V2a2 2 0 0 0-2-2zM6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h3v1a2 2 0 0 1-1.61 2 .5.5 0 0 0-.39.47v2a.52.52 0 0 0 .17.41.49.49 0 0 0 .39.12A5 5 0 0 0 8 9V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther51 svgRef={ref} {...props} />);
export default ForwardRef;
