import React from 'react';

const SvgActions41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.35 4.15a.48.48 0 0 0-.7 0l-4.5 4.79a.5.5 0 0 0 0 .71l.2.2a.49.49 0 0 0 .36.15h8.58a.49.49 0 0 0 .36-.15l.2-.2a.5.5 0 0 0 0-.71zM14.5 12h-9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions41 svgRef={ref} {...props} />);
export default ForwardRef;
