import React from 'react';

const SvgCommunication23 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.5 12.66a.91.91 0 0 1-.5.15.85.85 0 0 1-.5-.16L2 7.41l7.5-5.25a.86.86 0 0 1 1 0L18 7.41zm8.65-6.89L11.65.52a2.88 2.88 0 0 0-3.3 0L.85 5.77A2 2 0 0 0 0 7.41V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7.41a2 2 0 0 0-.85-1.64z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication23 svgRef={ref} {...props} />);
export default ForwardRef;
