import React from 'react';

const SvgAbstract76 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 12" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm8-10H6a6 6 0 1 0 0 12h8a6 6 0 1 0 0-12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract76 svgRef={ref} {...props} />);
export default ForwardRef;
