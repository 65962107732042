import React from 'react';

const SvgArrows25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.85 9.636a.509.509 0 0 1 0 .709l-3 2.995a.5.5 0 0 1-.85-.35V7a.5.5 0 0 1 .85-.36zM0 10c0 5.523 4.477 10 10 10s10-4.477 10-10S15.523 0 10 0 0 4.477 0 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows25 svgRef={ref} {...props} />);
export default ForwardRef;
