import React from 'react';

const SvgActions121 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.499 6.978h-5.004a.5.5 0 0 1-.5-.5v-.29a.49.49 0 0 1 .15-.36l1.781-1.78a6.937 6.937 0 0 0-4.933-2.07 7.002 7.002 0 0 0-6.978 6.86 7.002 7.002 0 0 0 6.698 7.133 7.003 7.003 0 0 0 7.295-6.523.5.5 0 0 1 .5-.47h1a.52.52 0 0 1 .36.16.5.5 0 0 1 .13.37 9.002 9.002 0 0 1-7.603 8.385A9.007 9.007 0 0 1 .597 12.22 8.997 8.997 0 0 1 4.093 1.458a9.01 9.01 0 0 1 11.264 1.16l1.491-1.49a.49.49 0 0 1 .36-.15h.29a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zm-7.626-2.5v4.14l2.922 2.92a.5.5 0 0 1 0 .71l-.53.53a.5.5 0 0 1-.71 0l-3.293-3.29a.53.53 0 0 1-.15-.35v-4.66a.5.5 0 0 1 .5-.5h.761a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions121 svgRef={ref} {...props} />);
export default ForwardRef;
