import React from 'react';

const SvgCommunication49 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.9 7.4l-3.8 3.8c-.2.2-.5.2-.7 0l-.2-.2c-.1-.1-.2-.2-.1-.4V8.8c-3 0-6 1.3-6.3 2.9 0 .2-.2.3-.3.3h-.2c-.1 0-.2 0-.2-.1-.1 0-.1-.1-.1-.2C6.2 8 9.3 5.1 13 5V3.4c0-.1.1-.3.1-.4l.2-.2c.2-.2.5-.2.7 0l3.8 3.8c.2.2.2.5.1.8 0-.1 0-.1 0 0zm5.6 6.6H22V1c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1v13H.5c-.3 0-.5.2-.5.5v.5c0 .6.4 1 1 1h22c.6 0 1-.4 1-1v-.5c0-.3-.2-.5-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication49 svgRef={ref} {...props} />);
export default ForwardRef;
