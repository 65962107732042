import React from 'react';

const SvgTransport4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 10H2V4h12zm0 5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm-9 0a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zM15.27 1.27l-.54-.54A2.49 2.49 0 0 0 13 0H3a2.49 2.49 0 0 0-1.77.73l-.54.54A2.49 2.49 0 0 0 0 3v16a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h10v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V3a2.49 2.49 0 0 0-.73-1.73z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport4 svgRef={ref} {...props} />);
export default ForwardRef;
