import React from 'react';

const SvgActions59 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.93 7.06a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM17.71.41l-.12-.12a1 1 0 0 0-.7-.29H9a2 2 0 0 0-1.41.58L.15 8a.49.49 0 0 0-.15.36v.2a.49.49 0 0 0 .15.36l.69.69a1 1 0 0 0 .7.29H7a1 1 0 0 1 .7.3l.12.12a1 1 0 0 1 .3.7v5.44a1 1 0 0 0 .29.7l.69.69a.49.49 0 0 0 .36.15h.2a.49.49 0 0 0 .36-.15l7.42-7.42A2 2 0 0 0 18 9V1.11a1 1 0 0 0-.29-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions59 svgRef={ref} {...props} />);
export default ForwardRef;
