import React from 'react';

const SvgActions67 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.856 10.356l-3.002 3.006a.5.5 0 0 1-.85-.35v-2.005h-2.502a.5.5 0 0 1-.5-.501V9.504a.5.5 0 0 1 .5-.501h2.501V6.999a.501.501 0 0 1 .861-.35l3.002 3.005c.183.2.178.507-.01.702zm-6.39 5.953a.5.5 0 0 1-.104.545l-3.002 3.002a.51.51 0 0 1-.71 0l-3.002-3.002a.5.5 0 0 1 .35-.85H9v-2.502a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2.502h2.002a.5.5 0 0 1 .463.305zM6.541 3.687a.5.5 0 0 1 .106-.541L9.65.144a.51.51 0 0 1 .71 0l3.002 3.002a.5.5 0 0 1-.36.85H11v2.502a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V3.996H6.997a.5.5 0 0 1-.456-.31zm.456 5.817v1.002a.5.5 0 0 1-.5.501H3.996v2.004a.501.501 0 0 1-.85.35L.144 10.357a.512.512 0 0 1 0-.712l3.002-3.006a.5.5 0 0 1 .85.36v2.005h2.502a.5.5 0 0 1 .5.501z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions67 svgRef={ref} {...props} />);
export default ForwardRef;
