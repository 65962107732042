import React from 'react';

const SvgArrows12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.858 9.06a.5.5 0 0 1 0-.71l.2-.2a.49.49 0 0 1 .36-.15h3.58V.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V8h3.58a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71l-6.79 6.79a.48.48 0 0 1-.7 0zM8.998 2h-2v7.5a.5.5 0 0 1-.5.5h-1.88l3.38 3.38 3.38-3.38h-1.88a.5.5 0 0 1-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows12 svgRef={ref} {...props} />);
export default ForwardRef;
