import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './RegVehicle.css';
import { CarIcon } from '../../../../../common/icons';
import LabelInput from '../../../../../common/components/LabelInput';
import SmallDivision from '../../../../../common/components/SmallDivision';

@inject('translationsStore', 'moveinStore')
@observer
export default class RegVehicle extends Component {
  render() {
    const { translationsStore, moveinStore } = this.props;
    return (
      <div className="RegVehicle">
        <div className="header">
          <SmallDivision title={translationsStore.translate('registration.vehicleData.title')} icon={<CarIcon />} style={{ marginTop: '24px', marginBottom: '24px' }} />
        </div>
        <div className="vehicleData">
          <LabelInput label={translationsStore.translate('registration.vehicleData.plate')} value={moveinStore.vehicle.license_plate} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.vehicleData.frame')} value={moveinStore.vehicle.vin} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.vehicleData.approval')} value={moveinStore.vehicle.fuel_type} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.vehicleData.equipment')} value={moveinStore.vehicle.environmental_class} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.vehicleData.category')} value={moveinStore.vehicle.category} disabled={true} />
          <span className="masonryGhostSeparator" />
          <span className="masonryGhostSeparator" />
          <span className="masonryGhostSeparator" />
        </div>
      </div>
    );
  }
}
