import React from 'react';

const SvgDevices18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M24 1.5V6h-2V2H2v10h12v8H7.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h.24a2 2 0 0 0 1.81-1.11L10 16H1.5A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0h21A1.5 1.5 0 0 1 24 1.5zM22 10h-4v8h4zm2 0v8a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices18 svgRef={ref} {...props} />);
export default ForwardRef;
