import React from 'react';

const SvgActions61 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm4 1.07l-6.51 6.52-7.08-7.08L8.93 2H16zM17.71.41l-.12-.12a1.05 1.05 0 0 0-.71-.29H8.93a2 2 0 0 0-1.42.59L.29 7.81a1 1 0 0 0 0 1.41l8.49 8.49a1 1 0 0 0 1.41 0l7.22-7.22A2 2 0 0 0 18 9.07V1.12a1.05 1.05 0 0 0-.29-.71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions61 svgRef={ref} {...props} />);
export default ForwardRef;
