import React from 'react';

const SvgAbstract67 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M10 7.996a2 2 0 1 0 0 3.999 2 2 0 0 0 0-3.999zm0 7.515A5.505 5.505 0 0 1 4.5 10 5.505 5.505 0 0 1 10 4.489c3.038 0 5.5 2.467 5.5 5.511 0 1.462-.58 2.863-1.61 3.897A5.494 5.494 0 0 1 10 15.51zm8.21-3.437a2.006 2.006 0 0 1-.72-1.643V9.57a2.006 2.006 0 0 1 .72-1.644l.8-.661c.376-.312.474-.85.23-1.273l-1.16-2.004a1 1 0 0 0-.86-.501.839.839 0 0 0-.35.07l-1 .36c-.223.088-.46.133-.7.131a1.997 1.997 0 0 1-1.08-.32 5.477 5.477 0 0 0-.74-.431 2.004 2.004 0 0 1-1.06-1.453L12.12.842a1 1 0 0 0-1-.842h-2.3a1 1 0 0 0-1 .842l-.17 1.002a2.004 2.004 0 0 1-1.02 1.453 5.477 5.477 0 0 0-.74.43c-.322.209-.697.32-1.08.321a1.887 1.887 0 0 1-.7-.13l-1-.36a.839.839 0 0 0-.35-.071 1 1 0 0 0-.86.501L.76 5.992c-.24.426-.14.964.24 1.273l.8.66c.485.404.752 1.013.72 1.644v.861a2.006 2.006 0 0 1-.72 1.644l-.8.661c-.38.309-.48.847-.24 1.273l1.16 2.004a1 1 0 0 0 .86.501c.12.002.24-.022.35-.07l1-.36c.223-.088.46-.133.7-.131.383 0 .758.112 1.08.32.235.163.483.307.74.431.563.29.956.828 1.06 1.453l.17 1.002a1 1 0 0 0 1 .842h2.3a1 1 0 0 0 1-.842l.17-1.002a2.004 2.004 0 0 1 1.06-1.453c.258-.124.505-.268.74-.43.322-.209.697-.32 1.08-.321.24-.002.477.043.7.13l1 .36c.11.05.23.073.35.071a1 1 0 0 0 .86-.501l1.16-2.004c.228-.44.1-.981-.3-1.273z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract67 svgRef={ref} {...props} />);
export default ForwardRef;
