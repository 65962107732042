import React from 'react';

const SvgArrows9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M0 7.498v-1a.5.5 0 0 1 .5-.5h16.17l-4.45-4.44a.5.5 0 0 1 0-.71l.71-.7a.5.5 0 0 1 .71 0l6.14 6.13c.14.14.22.331.22.53v.38a.77.77 0 0 1-.22.53l-6.14 6.13a.5.5 0 0 1-.71 0l-.71-.71a.49.49 0 0 1 0-.7l4.45-4.44H.5a.5.5 0 0 1-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows9 svgRef={ref} {...props} />);
export default ForwardRef;
