import React from 'react';

const SvgAbstract26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 17" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.86 7.06a.5.5 0 0 1 0-.71l.2-.2A.49.49 0 0 1 1.42 6H4V.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V6h2.58a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71l-5.79 5.79a.48.48 0 0 1-.7 0zM14 15.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract26 svgRef={ref} {...props} />);
export default ForwardRef;
