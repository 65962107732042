import React from 'react';

const SvgPremium1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 7a2 2 0 0 1-2 2V4h2zm-4 3a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V2h6zM2 7V4h2v5a2 2 0 0 1-2-2zm15-5h-3V1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H1a1 1 0 0 0-1 1v4a4 4 0 0 0 4 4h.14A4 4 0 0 0 8 14v4H5.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H10v-4a4 4 0 0 0 3.86-3H14a4 4 0 0 0 4-4V3a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium1 svgRef={ref} {...props} />);
export default ForwardRef;
