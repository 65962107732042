import React from 'react';

const SvgArrows20 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 8 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.503 0a.5.5 0 0 1 .5.5v15.505h2a.5.5 0 0 1 .35.85l-3 3.001a.51.51 0 0 1-.71 0l-3-3a.5.5 0 0 1 .36-.851h2V.5a.5.5 0 0 1 .5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows20 svgRef={ref} {...props} />);
export default ForwardRef;
