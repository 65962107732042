import React from 'react';

const SvgActions103 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.201 7.854a.49.49 0 0 1-.18-.54l.08-.249a.49.49 0 0 1 .44-.35l6.384-.51L9.373.322a.51.51 0 0 1 .51-.32h.259a.49.49 0 0 1 .47.32l2.457 5.885 6.384.51a.49.49 0 0 1 .44.349l.08.25a.49.49 0 0 1-.15.54l-4.816 4.136 1.479 6.204a.51.51 0 0 1-.19.53l-.29.16a.5.5 0 0 1-.56 0l-5.434-3.298-5.465 3.327a.5.5 0 0 1-.56 0l-.219-.15a.51.51 0 0 1-.19-.53l1.439-6.243z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions103 svgRef={ref} {...props} />);
export default ForwardRef;
