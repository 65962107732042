import React from 'react';

const SvgAbstract68 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M17.252 10l-2.78 5a2 2 0 0 1-1.75 1h-5.44a2 2 0 0 1-1.75-1l-2.78-5a2 2 0 0 1 0-1.94L5.532 3a2 2 0 0 1 1.75-1h5.44a2 2 0 0 1 1.75 1l2.78 5a2 2 0 0 1 0 2zm-7.25 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9-4.94l-2.78-5a4 4 0 0 0-3.5-2.06h-5.44a4 4 0 0 0-3.5 2.06l-2.78 5a4 4 0 0 0 0 3.88l2.78 5a4 4 0 0 0 3.5 2.06h5.44a4 4 0 0 0 3.5-2.06l2.78-5a4 4 0 0 0 0-3.88z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract68 svgRef={ref} {...props} />);
export default ForwardRef;
