import React from 'react';

const SvgPayment5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 11.12a2 2 0 0 0-.66-1.47 2 2 0 0 0 .16-.77V8a2 2 0 0 0-2-2H11V4.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H6.5a.5.5 0 0 0-.5.5v.75a.5.5 0 0 0 .5.5h.25A.25.25 0 0 1 7 8v4a.25.25 0 0 1-.25.25H6.5a.5.5 0 0 0-.5.5v.75a.5.5 0 0 0 .5.5H9v1.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V14h1a2 2 0 0 0 2-2zm3.071-8.191A10 10 0 0 1 20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0a10 10 0 0 1 7.071 2.929zM12.25 10.88v1.37h-3.5v-1.37zm-.5-3.13v1.37h-3V7.75z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment5 svgRef={ref} {...props} />);
export default ForwardRef;
