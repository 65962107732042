import React from 'react';

const SvgActions79 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.115 9.115v-4.62a.5.5 0 0 1 .5-.5h.76a.5.5 0 0 1 .5.5v5.87a.51.51 0 0 1-.5.5h-5.88a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5zm2.33-9.01a10 10 0 1 1-11.34 11.34.5.5 0 0 1 .5-.57h1a.5.5 0 0 1 .5.41 8 8 0 1 0 9.18-9.18.5.5 0 0 1-.41-.5v-1a.5.5 0 0 1 .57-.5zm-5.26 1.26a.49.49 0 0 1 0-.41.46.46 0 0 1 .29-.3 9.3 9.3 0 0 1 2.12-.55.52.52 0 0 1 .4.11.52.52 0 0 1 .12.4v1a.5.5 0 0 1-.42.49c-.48.082-.951.206-1.41.37a.5.5 0 0 1-.61-.22zm-3.95 2.33a9.143 9.143 0 0 1 1.505-1.46.477.477 0 0 1 .398-.1c.144.023.27.112.34.24l.508.89a.481.481 0 0 1-.16.68 6.99 6.99 0 0 0-.937.94.477.477 0 0 1-.628.11l-.887-.56a.47.47 0 0 1-.239-.34.481.481 0 0 1 .1-.4zm-1.24 2.44a.49.49 0 0 1 .41 0l.89.51a.49.49 0 0 1 .22.61c-.164.459-.288.93-.37 1.41a.5.5 0 0 1-.49.42h-1a.52.52 0 0 1-.44-.09.52.52 0 0 1-.11-.4 9.3 9.3 0 0 1 .55-2.17.46.46 0 0 1 .34-.29z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions79 svgRef={ref} {...props} />);
export default ForwardRef;
