import React from 'react';

const SvgOther64 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.5 8.998h4.25a.5.5 0 0 0 .35-.85l-1.42-1.43 5.17-5.16a.5.5 0 0 0 0-.71l-.7-.7a.5.5 0 0 0-.71 0l-5.17 5.16-1.42-1.42a.5.5 0 0 0-.85.36v4.25a.52.52 0 0 0 .5.5zm19-7H16a1 1 0 0 0-1 1v3h-3a1 1 0 0 0-1 1v3H8a1 1 0 0 0-1 1v3H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H8a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1v-3h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther64 svgRef={ref} {...props} />);
export default ForwardRef;
