import React from 'react';

const SvgFiles14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 4v14a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h9a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zm-5 8.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zM1.82 7L7 1.82V6a1 1 0 0 1-1 1zM13 0H6.83a2 2 0 0 0-1.42.59L.59 5.41A2 2 0 0 0 0 6.83V16a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles14 svgRef={ref} {...props} />);
export default ForwardRef;
