import React from 'react';

const SvgActions77 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2 9v1a.5.5 0 0 1-.5.5H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V14h2.5a.5.5 0 0 1 .5.5zm15-3V14a2 2 0 0 1-2 2h-2.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H18v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm-20-7V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2v2.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM20 2v2.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V2h-2.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H18a2 2 0 0 1 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions77 svgRef={ref} {...props} />);
export default ForwardRef;
