import React from 'react';

const SvgAbstract41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.988 8.702a10.662 10.662 0 0 0-12.872-6.29l10.483 10.49a9.41 9.41 0 0 0 2.329-3.57.81.81 0 0 0 .06-.33.93.93 0 0 0 0-.3zm-12.992.3c.002-.491.13-.973.369-1.4l3.988 4a2.875 2.875 0 0 1-1.366.4 2.996 2.996 0 0 1-2.991-3zM2.379.152a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l2.599 2.62A9.659 9.659 0 0 0 .06 8.682a1 1 0 0 0-.06.32c0 .106.02.211.06.31a10.644 10.644 0 0 0 9.934 6.69 10.988 10.988 0 0 0 4.667-1.09l2.948 2.94a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract41 svgRef={ref} {...props} />);
export default ForwardRef;
