import React from 'react';

const SvgActions86 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.025 12.015c-2.761 0-5-2.241-5-5.006a5.003 5.003 0 0 1 5-5.006c2.761 0 5 2.241 5 5.006a5.009 5.009 0 0 1-1.464 3.54 4.997 4.997 0 0 1-3.536 1.466zm10.85 4.445l-4-4.005a2.44 2.44 0 0 0-.43-.35l-1-.69a7.015 7.015 0 0 0 .046-8.754A6.995 6.995 0 0 0 3.973.69a7.011 7.011 0 0 0 3.052 13.327c1.588 0 3.129-.54 4.37-1.532l.75 1.002c.089.129.19.25.3.36l4 4.005a.5.5 0 0 0 .71 0l.7-.7a.501.501 0 0 0 .02-.692z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions86 svgRef={ref} {...props} />);
export default ForwardRef;
