import React from 'react';

const SvgArrows38 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.648 13.852a.48.48 0 0 0 .7 0l3.79-3.79a.5.5 0 0 0 0-.71l-.2-.2a.49.49 0 0 0-.36-.15h-7.16a.49.49 0 0 0-.36.15l-.2.2a.5.5 0 0 0 0 .71zm.7-13.7a.48.48 0 0 0-.7 0l-3.79 3.79a.5.5 0 0 0 0 .71l.2.2a.49.49 0 0 0 .36.15h7.16a.49.49 0 0 0 .36-.15l.2-.2a.5.5 0 0 0 0-.71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows38 svgRef={ref} {...props} />);
export default ForwardRef;
