import React from 'react';

const SvgActions6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.24 11l1.61-1.62a.48.48 0 0 0 0-.7l-.53-.53a.48.48 0 0 0-.7 0L9 9.76 7.38 8.15a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7L7.76 11l-1.61 1.62a.48.48 0 0 0 0 .7l.53.53a.48.48 0 0 0 .7 0L9 12.24l1.62 1.61a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7zM16 17H2V6h14zm2-13a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions6 svgRef={ref} {...props} />);
export default ForwardRef;
