import React from 'react';

const SvgAbstract10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 9.5a.5.5 0 0 1-.78.42l-1.94-1.3a.52.52 0 0 0-.56 0l-1.94 1.3A.5.5 0 0 1 2 9.5v-7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM14 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract10 svgRef={ref} {...props} />);
export default ForwardRef;
