import React from 'react';

const SvgCommunication61 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM2 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm15-5a5 5 0 0 0-5 5 4.92 4.92 0 0 0 1 3H9a4.92 4.92 0 0 0 1-3 5 5 0 1 0-5 5h12a5 5 0 0 0 0-10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication61 svgRef={ref} {...props} />);
export default ForwardRef;
