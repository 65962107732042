import React from 'react';

const SvgAbstract49 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.996 7c0 2.21 1.788 4 3.993 4a3.996 3.996 0 0 0 3.992-4c0-2.209-1.787-4-3.992-4a3.996 3.996 0 0 0-3.993 4zm5.987 0c0 1.105-.893 2-1.994 2a1.997 1.997 0 0 1-1.994-2c0-1.104.893-2 1.994-2s1.994.896 1.994 2zm-1.99 5A8.814 8.814 0 0 1 2.07 7a8.784 8.784 0 0 1 15.85 0 8.814 8.814 0 0 1-7.925 5zm9.995-5.3A10.643 10.643 0 0 0 9.994 0 10.643 10.643 0 0 0 .06 6.67 1 1 0 0 0 0 7c0 .107.02.212.06.31A10.644 10.644 0 0 0 9.994 14a10.644 10.644 0 0 0 9.934-6.67.81.81 0 0 0 .06-.33.93.93 0 0 0 0-.3z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract49 svgRef={ref} {...props} />);
export default ForwardRef;
