import React from 'react';

const SvgCommunication37 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 4v10a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h12a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zm-4-.4L9.65 8.05a1.14 1.14 0 0 1-1.3 0L2 3.6V2h14zm0 8.4H2V5.73l5.35 3.75a2.88 2.88 0 0 0 3.3 0L16 5.73zm0-12H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication37 svgRef={ref} {...props} />);
export default ForwardRef;
