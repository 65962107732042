import React from 'react';

const SvgFiles28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M22 8v7a4 4 0 0 1-4 4H6a2 2 0 0 1-2-2h14a2 2 0 0 0 2-2V6a2 2 0 0 1 2 2zM9.29.29l1.42 1.42a1 1 0 0 0 .7.29H16a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6.59a1 1 0 0 1 .7.29zM8.17 2H2v11h14V4h-4.59a3 3 0 0 1-2.12-.88z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles28 svgRef={ref} {...props} />);
export default ForwardRef;
