import React from 'react';

const SvgPayment12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-8 9V2h16v6c0 3.31-6 6-12 6zM18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment12 svgRef={ref} {...props} />);
export default ForwardRef;
