import React from 'react';

const SvgPremium11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 5.84v.3a1 1 0 0 1-.23.64l-9 10.86A1 1 0 0 1 10 18a1 1 0 0 1-.77-.36l-9-10.86A1 1 0 0 1 0 6.14v-.3a1 1 0 0 1 .26-.68L4.41.65A2 2 0 0 1 5.88 0h8.24a2 2 0 0 1 1.47.65l4.15 4.51a1 1 0 0 1 .26.68z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium11 svgRef={ref} {...props} />);
export default ForwardRef;
