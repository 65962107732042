import React from 'react';

const SvgOther55 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 24" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.572 17.515l-4.92-.871a.999.999 0 0 0-1.16.81l-.44 2.464a4.082 4.082 0 0 0-.05.611 3.5 3.5 0 0 0 6.94.61l.43-2.462a1.001 1.001 0 0 0-.8-1.162zM5.002 0c-4 0-5 5.007-5 8.011a12.383 12.383 0 0 0 1.17 5.468 1 1 0 0 0 .88.541h6.25a1 1 0 0 0 .95-.701 18.22 18.22 0 0 0 .75-5.308c0-3.004-1-8.011-5-8.011z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther55 svgRef={ref} {...props} />);
export default ForwardRef;
