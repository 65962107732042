import React from 'react';

const SvgActions104 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15 0H5a2 2 0 0 0-2 2h14a2 2 0 0 0-2-2zM9.17 14.22A.75.75 0 0 1 8 13.6V8.4a.75.75 0 0 1 1.17-.62l3.89 2.6a.74.74 0 0 1 0 1.24zM18 4H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions104 svgRef={ref} {...props} />);
export default ForwardRef;
