import React, { Component } from 'react';
import classNames from 'classnames';
import { Input } from 'antd';

import './LabelInput.css';

export default class LabelInput extends Component {
  render() {
    const cls = classNames({
      LabelInput: true,
      ['has-error']: this.props.validateStatus === 'error'
    });
    const { label, value, type, placeholder } = this.props;
    const widget =
      type === 'password' ? (
        <Input.Password
          placeholder={placeholder}
          value={value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          maxLength={this.props.maxlength}
          disabled={this.props.disabled}
        />
      ) : (
        <Input
          placeholder={placeholder}
          value={value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          maxLength={this.props.maxlength}
          disabled={this.props.disabled}
        />
      );
    return (
      <div className={cls} style={this.props.style}>
        {label ? <div>{label}</div> : undefined}
        {widget}
        {!!this.props.help && <div className={'LabelInputExplain'}>{this.props.help}</div>}
      </div>
    );
  }
}
