import React from 'react';

const SvgActions84 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 2" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.5 2a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 0h13a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions84 svgRef={ref} {...props} />);
export default ForwardRef;
