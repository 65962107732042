import React from 'react';

const SvgAbstract32 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.47 6.42l-2 4.93a2 2 0 0 1-1.09 1.09l-4.93 2a.49.49 0 0 1-.54-.11l-.24-.24a.49.49 0 0 1-.11-.54l2-4.93a2 2 0 0 1 1.09-1.09l4.93-2a.49.49 0 0 1 .54.11l.24.24a.49.49 0 0 1 .11.54zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract32 svgRef={ref} {...props} />);
export default ForwardRef;
