import React from 'react';

const SvgPremium3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 10.68a.59.59 0 0 1-.07.26l-3 4.84a.44.44 0 0 1-.39.22h-.04a.48.48 0 0 1-.35-.16.47.47 0 0 1-.1-.36l.34-2.41H8.5a.5.5 0 0 1-.5-.5v-.16a.59.59 0 0 1 .07-.26l3-4.91a.51.51 0 0 1 .45-.24.48.48 0 0 1 .35.16.47.47 0 0 1 .1.36L11.61 10h1.89a.5.5 0 0 1 .5.5zm7.903 7.71a.79.79 0 0 0 0-.76L11.81.37a.76.76 0 0 0-.65-.37h-.3a.76.76 0 0 0-.65.37L.097 17.63a.79.79 0 0 0 0 .76l.14.23a.73.73 0 0 0 .65.38h20.225a.73.73 0 0 0 .65-.38z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium3 svgRef={ref} {...props} />);
export default ForwardRef;
