import { auth, movein, mypage } from '.';

//Rollbar is a global variable, lint complains
//https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#using-global-variables
//const Rollbar = window.Rollbar;

const api = (action, ...params) => {
  const apis = {
    getDataFromIdAdesione: movein.getDataFromIdAdesione,
    registerUser: movein.registerUser,
    getUserData: mypage.getUserData,
    getUserManageableVehicles: mypage.getUserManageableVehicles,
    getUserManageableVehicleData: mypage.getUserManageableVehicleData,
    getZuoraHostedPageToken: mypage.getZuoraHostedPageToken,
    setPaymentMethodDefault: mypage.setPaymentMethodDefault,
    subscribeToProduct: mypage.subscribeToProduct,
    getInstallersByProvince: movein.getInstallersByProvince,
    chooseInstaller: movein.chooseInstaller,
    getInstallers: movein.getInstallers,
    getProducts: mypage.getProducts
  };

  try {
    return apis[action](...params);
  } catch (error) {
    console.error(`action: ${action} - error: ${error}`);
    //Rollbar.error(error);
    return Promise.reject(Object.assign({}, error, { severity: 'high' }));
  }
};

export default api;
