import React from 'react';

const SvgPayment25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.5 12.25h-.75a.5.5 0 0 0-.5.5V13a.25.25 0 0 1-.25.25H9.14a6 6 0 0 0 .36-1.75 4.17 4.17 0 0 0 0-.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H9a4.08 4.08 0 0 1-.2-1 1.25 1.25 0 0 1 2.45-.36.5.5 0 0 0 .48.36h.77a.53.53 0 0 0 .39-.18.53.53 0 0 0 .1-.42A3.01 3.01 0 0 0 7 8c.008.338.055.673.14 1H6.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.19c.04.164.06.332.06.5A8.31 8.31 0 0 1 7 14a.51.51 0 0 0 .09.54l.26.29c.097.1.23.159.37.16H12a2 2 0 0 0 2-2v-.25a.5.5 0 0 0-.5-.49zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment25 svgRef={ref} {...props} />);
export default ForwardRef;
