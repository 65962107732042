import React from 'react';

const SvgActions69 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.856 10.36l-3 3.002a.5.5 0 0 1-.86-.36V11h-5v5.003h2a.5.5 0 0 1 .36.85l-3.001 3.002a.51.51 0 0 1-.71 0l-3-3.002a.5.5 0 0 1 .35-.85h2V11h-5v2a.5.5 0 0 1-.85.351L.143 10.35a.51.51 0 0 1 0-.71l3-3.002a.5.5 0 0 1 .85.36V9h5V3.996h-2a.5.5 0 0 1-.35-.85L9.646.144a.51.51 0 0 1 .71 0l3 3.002a.5.5 0 0 1-.36.85h-2V9h5V7a.5.5 0 0 1 .85-.361l3.001 3.002a.51.51 0 0 1 .01.72z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions69 svgRef={ref} {...props} />);
export default ForwardRef;
