import React from 'react';

const SvgAlerts6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.672 6H9.318a.497.497 0 0 0-.498.56l.398 3.22a.25.25 0 0 0 .25.22h1.055a.25.25 0 0 0 .249-.22l.398-3.22a.501.501 0 0 0-.498-.56zm-.172 6h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm6.302 2.15a5.3 5.3 0 0 0-2.66 2.66l-.19.44a1.27 1.27 0 0 1-1.161.75 1.451 1.451 0 0 1-.48-.09l-.43-.16A5.063 5.063 0 0 0 10 17.4a5.003 5.003 0 0 0-1.85.34l-.49.17a1.17 1.17 0 0 1-.45.09 1.25 1.25 0 0 1-1.151-.74l-.21-.46a5.3 5.3 0 0 0-2.661-2.66l-.44-.14a1.29 1.29 0 0 1-.66-1.64l.16-.43c.465-1.2.465-2.53 0-3.73l-.17-.49a1.25 1.25 0 0 1 .65-1.6l.46-.21a5.3 5.3 0 0 0 2.67-2.71l.19-.44A1.27 1.27 0 0 1 7.21 2c.164.002.326.033.48.09l.43.16A5.063 5.063 0 0 0 10 2.6a5.022 5.022 0 0 0 1.85-.34l.49-.17c.143-.06.296-.09.45-.09a1.25 1.25 0 0 1 1.151.74l.21.46a5.3 5.3 0 0 0 2.661 2.66l.44.19a1.29 1.29 0 0 1 .66 1.64l-.16.43a5.158 5.158 0 0 0 0 3.73l.17.49a1.25 1.25 0 0 1-.66 1.6zm2.811-2.99a3.199 3.199 0 0 1 0-2.32l.17-.46a3.27 3.27 0 0 0-1.71-4.15l-.45-.23a3.31 3.31 0 0 1-1.621-1.62l-.2-.45A3.261 3.261 0 0 0 11.65.22l-.46.17c-.38.142-.785.213-1.19.21-.396 0-.79-.071-1.16-.21L8.38.22a3.261 3.261 0 0 0-4.152 1.71l-.23.45A3.31 3.31 0 0 1 2.378 4l-.45.2A3.27 3.27 0 0 0 .216 8.35l.17.46c.29.746.29 1.573 0 2.32l-.17.46a3.27 3.27 0 0 0 1.71 4.15l.45.2a3.31 3.31 0 0 1 1.621 1.68l.2.45a3.261 3.261 0 0 0 4.151 1.71l.46-.17a3.312 3.312 0 0 1 2.321 0l.46.17a3.261 3.261 0 0 0 4.152-1.71l.2-.45a3.31 3.31 0 0 1 1.68-1.62l.45-.2a3.27 3.27 0 0 0 1.711-4.15z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts6 svgRef={ref} {...props} />);
export default ForwardRef;
