import React from 'react';

const SvgDevices49 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 23 21" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.5 8a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm.5 5.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM16 18a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm6.19-14.77a.51.51 0 0 0 0-.71l-.36-.36a.5.5 0 0 0-.7 0l-3.3 3.21-.06-4.88a.51.51 0 0 0-.5-.49h-.52a.51.51 0 0 0-.5.49L16.17 6H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-.51z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices49 svgRef={ref} {...props} />);
export default ForwardRef;
