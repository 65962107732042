import React from 'react';

const SvgUser13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.852 15.523a1 1 0 0 1-.85.477H.998a1 1 0 0 1-.89-1.45l1.44-2.89A3.001 3.001 0 0 1 4.24 10h7.522a3.001 3.001 0 0 1 2.691 1.66l1.441 2.89a1 1 0 0 1-.04.973zM3.998 4A4 4 0 0 1 8 0a4 4 0 1 1-4.002 4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser13 svgRef={ref} {...props} />);
export default ForwardRef;
