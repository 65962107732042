import React from 'react';

const SvgAbstract73 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M18 2v14a2 2 0 0 1-2 2h-5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H16V2h-5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H16a2 2 0 0 1 2 2zm-4 7.19a.75.75 0 0 1-.22.53l-5.14 5.13a.5.5 0 0 1-.71 0l-.71-.7a.5.5 0 0 1 0-.71L10.67 10H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 8h10.17L7.22 4.56a.49.49 0 0 1 0-.7l.71-.71a.5.5 0 0 1 .71 0l5.14 5.13a.77.77 0 0 1 .22.53z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract73 svgRef={ref} {...props} />);
export default ForwardRef;
