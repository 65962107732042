import React from 'react';

const SvgActions102 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.13 0a.489.489 0 0 1 .469.32l2.483 5.89 6.372.51c.205.013.38.153.439.35l.08.25a.49.49 0 0 1-.14.53L14.986 12l1.437 6.22a.51.51 0 0 1-.19.53l-.22.15a.468.468 0 0 1-.299.1.478.478 0 0 1-.259-.07L10 15.6l-5.425 3.33a.478.478 0 0 1-.26.07.468.468 0 0 1-.298-.1l-.25-.15a.51.51 0 0 1-.19-.53L5.015 12 .177 7.86a.49.49 0 0 1-.15-.54l.11-.25a.489.489 0 0 1 .44-.35l6.372-.51L9.392.32A.509.509 0 0 1 9.87 0zM10 3.41L8.145 7.87l-4.796.38 3.66 3.13-1.127 4.68L10 13.55l4.109 2.51-1.117-4.68 3.66-3.13-4.797-.38z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions102 svgRef={ref} {...props} />);
export default ForwardRef;
