import React from 'react';

const SvgAbstract24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M1 6h14l-.85 12.14a2 2 0 0 1-2 1.86H3.87a2 2 0 0 1-2-1.86zm15-3.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 2H5V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h4.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract24 svgRef={ref} {...props} />);
export default ForwardRef;
