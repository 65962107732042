import React from 'react';

const SvgTransport8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.94 1.861l-.28.77a4 4 0 0 1-.93 1.47l-4.32 4.32 2.05 10.23c.01.127.01.254 0 .38a1.89 1.89 0 0 1-.57 1.36l-.17.17a.44.44 0 0 1-.34.14.48.48 0 0 1-.41-.23l-4.15-8.47-4.15 3.45.44 5.2a1 1 0 0 1-.28.68l-.53.53a.48.48 0 0 1-.77-.13l-2.42-4.84-4.84-2.42a.48.48 0 0 1-.13-.77l.53-.53a1 1 0 0 1 .68-.28l5.13.43L10 9.181l-8.47-4.18a.48.48 0 0 1-.23-.41.44.44 0 0 1 .14-.34l.17-.17A1.89 1.89 0 0 1 3 3.501c.126-.01.253-.01.38 0l10.2 2.09 4.32-4.32a4 4 0 0 1 1.47-.93l.77-.28a1 1 0 0 1 1.05.23l.52.52a1 1 0 0 1 .23 1.05z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport8 svgRef={ref} {...props} />);
export default ForwardRef;
