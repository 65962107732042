import React from 'react';

const SvgActions94 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.002 15.975a1.998 1.998 0 1 0 0 3.994c1.104 0 2-.894 2-1.997a1.998 1.998 0 0 0-2-1.997zm13.5-13.977H5.712l9 8.975a2.94 2.94 0 0 0 2.29-1.505l2.35-4.119a4.737 4.737 0 0 0 .65-2.354v-.498a.5.5 0 0 0-.5-.499zM6.622 12.98l1-2.086c.209.055.423.085.64.09h1.46l2 1.996zM1.382.151a.48.48 0 0 0-.7 0l-.53.53a.479.479 0 0 0 0 .698l2.15 2.147 3.4 5.99c.077.125.168.242.27.35l-1.86 3.693a.768.768 0 0 0 0 .709l.22.38a.76.76 0 0 0 .67.329h8.76l1.24 1.258a1.996 1.996 0 0 0 .664 3.683c.822.148 1.65-.23 2.076-.948l.88.879a.48.48 0 0 0 .7 0l.53-.53a.479.479 0 0 0 0-.698z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions94 svgRef={ref} {...props} />);
export default ForwardRef;
