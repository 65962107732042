import React from 'react';

const SvgPayment10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 7.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5zm-10 5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zM20 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment10 svgRef={ref} {...props} />);
export default ForwardRef;
