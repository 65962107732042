import React from 'react';

const SvgArrows39 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18.082.828a.5.5 0 0 1 .71 0l1.06 1.06a.48.48 0 0 1 0 .7l-9.19 9.19a.75.75 0 0 1-.53.22h-.26a.75.75 0 0 1-.53-.22l-9.19-9.19a.48.48 0 0 1 0-.7l1.06-1.06a.5.5 0 0 1 .71 0l8.08 8.08z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows39 svgRef={ref} {...props} />);
export default ForwardRef;
