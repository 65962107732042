import React from 'react';

const SvgTransport7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20.84 19.638a.48.48 0 0 1 0 .7l-.53.531a.48.48 0 0 1-.7 0l-1.23-1.22a1.815 1.815 0 0 1-.46.76l-.17.17a.44.44 0 0 1-.34.14.48.48 0 0 1-.41-.23l-2.2-4.424-3.13-3.133-3 2.513.44 5.204a1.001 1.001 0 0 1-.28.68l-.53.531a.48.48 0 0 1-.77-.13l-2.42-4.844-4.84-2.422a.48.48 0 0 1-.13-.77l.53-.531a1 1 0 0 1 .68-.28l5.13.43 2.57-3.003-3.12-3.123-4.4-2.181a.48.48 0 0 1-.23-.42.44.44 0 0 1 .14-.341l.17-.17a1.81 1.81 0 0 1 .76-.46L1.15 2.382a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0zm1.1-17.775l-.28.77a4.004 4.004 0 0 1-.93 1.472l-4.32 4.324 1.07 5.354-9.25-9.258 5.35 1.071 4.32-4.324a4 4 0 0 1 1.47-.93l.77-.28a1 1 0 0 1 1.05.23l.52.52c.274.276.364.686.23 1.05z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport7 svgRef={ref} {...props} />);
export default ForwardRef;
