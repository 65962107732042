import React from 'react';

const SvgPayment32 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18.958.702l-6.606 14.71a.998.998 0 0 1-.918.59H8.072a.998.998 0 0 1-.998-.73L3.772 3.362a2.998 2.998 0 0 0-1.597-1.91A19.927 19.927 0 0 0 .18.662.26.26 0 0 1 0 .422v-.17a.25.25 0 0 1 .25-.25h5.657c.998 0 1.766.27 1.995 1.33l1.587 7.51.439 2.3 4.28-10.52a.998.998 0 0 1 .928-.62h3.323a.498.498 0 0 1 .499.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment32 svgRef={ref} {...props} />);
export default ForwardRef;
