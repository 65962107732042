import React from 'react';

const SvgAbstract30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M13.51 5.559a.49.49 0 0 1 .541.11l.24.24a.49.49 0 0 1 .11.54l-1.23 3.073 5.474 5.515A10.01 10.01 0 0 0 4.963 1.355l5.515 5.475zm-7.166 8.948a.49.49 0 0 1-.54-.11l-.24-.24a.49.49 0 0 1-.111-.54l2.002-4.825 3.723 3.723zM2.34 1.174a.48.48 0 0 0-.7 0l-.531.531a.48.48 0 0 0 0 .7L2.3 3.608A10.01 10.01 0 0 0 16.393 17.7l1.201 1.191a.48.48 0 0 0 .701 0l.53-.53a.48.48 0 0 0 0-.701z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract30 svgRef={ref} {...props} />);
export default ForwardRef;
