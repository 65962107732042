import React from 'react';

const SvgOther68 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 11.986a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm9.71-5.798l-.63-.629A1.001 1.001 0 0 0 18 5.3c-.317.13-.657.194-1 .19-1.38 0-2.5-1.116-2.5-2.494a2.56 2.56 0 0 1 .19-.998.997.997 0 0 0-.26-1.118L13.8.25a1.001 1.001 0 0 0-.7-.25h-1.18a1.001 1.001 0 0 0-.7.29L.29 11.198a.997.997 0 0 0-.29.698v1.178a.997.997 0 0 0 .29.699l.63.628a1 1 0 0 0 1.08.26c.317-.13.657-.194 1-.19 1.38 0 2.5 1.117 2.5 2.495a2.56 2.56 0 0 1-.19.998.997.997 0 0 0 .26 1.118l.63.629c.186.184.438.288.7.289h1.18a.999.999 0 0 0 .7-.29L19.71 8.764a.997.997 0 0 0 .29-.699V6.887a.997.997 0 0 0-.29-.699z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther68 svgRef={ref} {...props} />);
export default ForwardRef;
