import React from 'react';

const SvgOther40 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 15 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.997 0a10.01 10.01 0 0 1 4.743 1.19.51.51 0 0 1 .26.44v.13a.51.51 0 0 1-.19.4 9.998 9.998 0 0 0 0 15.68.51.51 0 0 1 .19.4v.13a.51.51 0 0 1-.26.44 10.01 10.01 0 0 1-11.32-1.281A9.996 9.996 0 0 1 .638 6.489 10.005 10.005 0 0 1 9.997 0zm0 2a8.002 8.002 0 0 0-8.004 8 8.002 8.002 0 0 0 9.865 7.78 11.995 11.995 0 0 1 0-15.56A7.708 7.708 0 0 0 9.998 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther40 svgRef={ref} {...props} />);
export default ForwardRef;
