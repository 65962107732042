import React from 'react';

const SvgPayment6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 9.45a.56.56 0 0 0 .13.39c.097.1.23.159.37.16h1a.49.49 0 0 0 .5-.44A4 4 0 0 1 9.56 6a.49.49 0 0 0 .44-.47v-1A.5.5 0 0 0 9.47 4 6 6 0 0 0 4 9.45zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment6 svgRef={ref} {...props} />);
export default ForwardRef;
