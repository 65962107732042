import React from 'react';

const SvgCommunication1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.99 15.085a.75.75 0 0 1-.197.637l-1.688 1.69c-.375.378-.885.59-1.418.59h-.708a10.042 10.042 0 0 1-4.773-1.23l-.45-.25a17.986 17.986 0 0 1-7.318-7.33l-.25-.45A10.082 10.082 0 0 1 0 4.002v-.67c0-.533.212-1.044.59-1.42L2.276.222a.748.748 0 0 1 1.178.15L5.7 4.232a1 1 0 0 1-.16 1.21l-1.887 1.89a.5.5 0 0 0-.09.59l.35.66a13.52 13.52 0 0 0 5.492 5.49l.659.36a.499.499 0 0 0 .589-.09l1.887-1.89a.997.997 0 0 1 1.208-.16l3.854 2.25a.75.75 0 0 1 .387.543zM9.895 6.862l1.85-1.86-1.85-1.86a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0l1.86 1.85 1.86-1.85a.48.48 0 0 1 .7 0l.53.53a.48.48 0 0 1 0 .7l-1.85 1.86 1.85 1.86a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0l-1.86-1.85-1.86 1.85a.48.48 0 0 1-.7 0l-.53-.53a.48.48 0 0 1 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication1 svgRef={ref} {...props} />);
export default ForwardRef;
