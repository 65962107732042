import React from 'react';

const SvgCommunication58 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2.993 4.997a4.001 4.001 0 1 1 8 0v10.006a2 2 0 1 0 4 0V3.996h-2a.5.5 0 0 1-.35-.85l3-3.002a.51.51 0 0 1 .71 0l3 3.002a.5.5 0 0 1-.36.85h-2v11.007a4.001 4.001 0 1 1-8 0V4.997a2 2 0 1 0-4 0v11.007h2a.5.5 0 0 1 .36.85l-3 3.002a.51.51 0 0 1-.71 0l-3-3.002a.5.5 0 0 1 .35-.85h2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication58 svgRef={ref} {...props} />);
export default ForwardRef;
