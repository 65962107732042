import React from 'react';

const SvgOther9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18.002 4.002h-3v-2a2 2 0 0 0-2-2h-6a2 2 0 0 0-1.9 1.39l1.9 1.9v-1.29h6v2h-5.29l12.29 12.29V6.002a2 2 0 0 0-2-2zm-9 8a1 1 0 0 1-1-1v-.5a.5.5 0 0 1 .5-.5h.26l2 2zM1.382.152a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l2.61 2.62h-.76a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14.76l1.86 1.85a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther9 svgRef={ref} {...props} />);
export default ForwardRef;
