import React from 'react';

const SvgCommunication57 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.997 17.003a4.001 4.001 0 1 1 0-8h10.006a2 2 0 1 0 0-4H3.996v2a.5.5 0 0 1-.85.35l-3.002-3a.51.51 0 0 1 0-.71l3.002-3a.5.5 0 0 1 .85.36v2h11.007a4.001 4.001 0 1 1 0 8H4.997a2 2 0 1 0 0 4h11.007v-2a.5.5 0 0 1 .85-.36l3.002 3a.51.51 0 0 1 0 .71l-3.002 3a.5.5 0 0 1-.85-.35v-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication57 svgRef={ref} {...props} />);
export default ForwardRef;
