import React from 'react';

const SvgAlerts17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.672 7H8.318a.497.497 0 0 0-.498.56l.398 3.22a.25.25 0 0 0 .25.22h1.055a.25.25 0 0 0 .249-.22l.398-3.22A.501.501 0 0 0 9.672 7zM9.5 13h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm6.5-.91a5.93 5.93 0 0 1-2.32 4.73L9.6 20H8.4l-4.08-3.18A5.93 5.93 0 0 1 2 12.09V6.24a1 1 0 0 1 .29-.7L5.83 2h6.34l3.54 3.54a1 1 0 0 1 .29.7zm2-5.85a3 3 0 0 0-.88-2.12L13.59.59A2 2 0 0 0 12.17 0H5.83a2 2 0 0 0-1.42.59L.88 4.12A3 3 0 0 0 0 6.24v5.85a8 8 0 0 0 3.09 6.31l4.08 3.18A2 2 0 0 0 8.4 22h1.2a2 2 0 0 0 1.23-.42l4.08-3.18A8 8 0 0 0 18 12.09z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts17 svgRef={ref} {...props} />);
export default ForwardRef;
