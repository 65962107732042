import React from 'react';

const SvgActions109 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 4h-7V3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1zm0 10H2V3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h8zm0-14H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions109 svgRef={ref} {...props} />);
export default ForwardRef;
