import React from 'react';

const SvgDevices17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M24 1v5h-2V2H2v12h12v6H7.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H9v-2H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1zm-2 9h-4v8h4zm2 0v8a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices17 svgRef={ref} {...props} />);
export default ForwardRef;
