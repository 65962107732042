import React from 'react';

const SvgPremium13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.02 0c5.199 0 9.474 3.961 9.97 9.031a2 2 0 0 1-.577 1.613l-8.768 8.769a2.007 2.007 0 0 1-1.614.577C3.962 19.494 0 15.22 0 10.019 0 4.486 4.486 0 10.02 0zM2 10.019a7.977 7.977 0 0 0 5.981 7.752V15.98c0-4.411 3.589-8 8-8h1.789A7.976 7.976 0 0 0 10.02 2C5.598 2 2 5.598 2 10.019zm15.249-.039h-1.268c-3.309 0-6 2.692-6 6v1.266z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium13 svgRef={ref} {...props} />);
export default ForwardRef;
