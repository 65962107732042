import React from 'react';

const SvgTransport3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 17.501a.5.5 0 0 0-.54-.51c-2.15.14-2.46 1-4.46 1-2.15 0-2.35-1-5-1s-3 1-5 1-2.31-.86-4.46-.99a.5.5 0 0 0-.54.5v1a.5.5 0 0 0 .45.5C2.19 19.14 2.54 20 5 20c2.72 0 2.85-1 5-1s2.35 1 5 1c2.45 0 2.83-.88 4.55-1a.5.5 0 0 0 .45-.5zM15 7.406l-.83-.25-3.6-1.079a2 2 0 0 0-1.14 0L5 7.407V2.998h10zm3.64 1.1L17 7.996V2.999a2.49 2.49 0 0 0-.73-1.77l-.54-.54A2.49 2.49 0 0 0 14 0H6a2.49 2.49 0 0 0-1.77.73l-.54.54A2.488 2.488 0 0 0 3 2.998v4.997l-1.64.49a.5.5 0 0 0-.34.61l1.77 6.556c.712.238 1.46.353 2.21.34 2.71 0 2.77-1 5-1s2.36 1 5 1a6.633 6.633 0 0 0 2.21-.33L19 9.115a.49.49 0 0 0-.36-.61z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport3 svgRef={ref} {...props} />);
export default ForwardRef;
