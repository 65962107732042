import React from 'react';

const SvgDevices26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 6.38a.5.5 0 0 1-.5.5h-1.62V8.5a.5.5 0 0 1-.5.5h-.76a.5.5 0 0 1-.5-.5V6.88H14.5a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h1.62V3.5a.5.5 0 0 1 .5-.5h.76a.5.5 0 0 1 .5.5v1.62h1.62a.5.5 0 0 1 .5.5zM3 5.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm18.4.11l-.83-3.34A3 3 0 0 0 17.66 0h-2.85a.51.51 0 0 0-.45.28l-.22.44a.51.51 0 0 1-.45.28H8.31a.51.51 0 0 1-.45-.28L7.64.28A.51.51 0 0 0 7.19 0H4.34a3 3 0 0 0-2.91 2.27L.6 5.61a19.88 19.88 0 0 0-.6 4.85V14a2 2 0 0 0 2 2h.63a3 3 0 0 0 3-2.51l.27-1.65a1 1 0 0 1 1-.84h8.3a1 1 0 0 1 1 .84l.27 1.65a3 3 0 0 0 3 2.51H20a2 2 0 0 0 2-2v-3.54a19.88 19.88 0 0 0-.6-4.85z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices26 svgRef={ref} {...props} />);
export default ForwardRef;
