import React from 'react';

const SvgAbstract18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 13a1 1 0 0 0-1-1h-2v-2a2 2 0 0 0-2-2h-3V6h2a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2v2H5a2 2 0 0 0-2 2v2H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H5v-2h8v2h-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract18 svgRef={ref} {...props} />);
export default ForwardRef;
