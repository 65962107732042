import React from 'react';

const SvgOther52 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.985 0h-1.994a.996.996 0 0 0-.805.372 1.002 1.002 0 0 0-.191.868l.498 2a.997.997 0 0 0 .997.76h1.495c.55 0 .997-.447.997-1V1c0-.552-.446-1-.997-1zm-3.12 18H3.23l-.999-3.72 6.415.91a.999.999 0 0 0 .83-.27l.69-.66a1 1 0 0 0 .309-.78v-.46a2.001 2.001 0 0 1 .31-1.23L13.274 8h.51zm3.128-12h-2.259a1 1 0 0 0-.83.45l-2.817 4.23a4.002 4.002 0 0 0-.65 2.46l-6.825-1h-.13a.999.999 0 0 0-.719.3l-.47.46a1 1 0 0 0-.25 1l1.26 4.63A1.999 1.999 0 0 0 3.23 20h9.453a1 1 0 0 0 1-.81l2.298-12a1 1 0 0 0-.99-1.19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther52 svgRef={ref} {...props} />);
export default ForwardRef;
