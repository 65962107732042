import React from 'react';

const SvgPremium5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.5 14H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM10 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm9.5-8h-.38a1.5 1.5 0 0 0-1.06.44L15 4.5 10.72.22a.75.75 0 0 0-.53-.22h-.38a.75.75 0 0 0-.53.22L5 4.5 1.94 1.44A1.5 1.5 0 0 0 .88 1H.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium5 svgRef={ref} {...props} />);
export default ForwardRef;
