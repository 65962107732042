import React from 'react';

const SvgActions9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.294 13.935a.5.5 0 0 1 .356.148l.2.2a.49.49 0 0 1 .15.36v2.362A6.005 6.005 0 0 0 14.006 11c0-.968-.24-1.92-.7-2.772a.51.51 0 0 1 .09-.59l.74-.741a.5.5 0 0 1 .42-.14.52.52 0 0 1 .371.24A8.003 8.003 0 0 1 14.93 15 8.009 8.009 0 0 1 8 19.006v2.291a.49.49 0 0 1-.15.36l-.2.2a.5.5 0 0 1-.711 0l-3.544-3.532a.51.51 0 0 1 0-.71l3.544-3.532a.5.5 0 0 1 .355-.148zM9.061.148l3.544 3.53a.51.51 0 0 1 0 .71L9.06 7.918a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.15-.36v-2.36a6.003 6.003 0 0 0-6.006 6 5.94 5.94 0 0 0 .69 2.79.5.5 0 0 1-.09.59l-.73.73a.5.5 0 0 1-.791-.11A7.993 7.993 0 0 1 1.07 7 8.01 8.01 0 0 1 8 2.998V.708a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .711 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions9 svgRef={ref} {...props} />);
export default ForwardRef;
