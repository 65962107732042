import React from 'react';

const SvgArrows1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 6.498v1a.5.5 0 0 1-.5.5H3.33l4.45 4.44a.5.5 0 0 1 0 .71l-.71.7a.5.5 0 0 1-.71 0L.22 7.718a.75.75 0 0 1-.22-.53v-.38a.77.77 0 0 1 .22-.53L6.36.148a.5.5 0 0 1 .71 0l.71.71a.49.49 0 0 1 0 .7l-4.45 4.44H19.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows1 svgRef={ref} {...props} />);
export default ForwardRef;
