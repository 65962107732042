import React from 'react';

const SvgAbstract72 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M18 17.003A2.999 2.999 0 0 1 15 20c-1.657 0-3-1.342-3-2.997a2.086 2.086 0 0 1 0-.25.51.51 0 0 0-.24-.48l-6.54-3.817a.5.5 0 0 0-.54 0 2.942 2.942 0 0 1-1.68.55c-1.657 0-3-1.342-3-2.997A2.999 2.999 0 0 1 3 7.01c.601 0 1.187.186 1.68.53a.5.5 0 0 0 .54 0l6.55-3.817a.51.51 0 0 0 .23-.46 2.086 2.086 0 0 1 0-.25 3 3 0 0 1 5.716-1.29 2.996 2.996 0 0 1-.888 3.65 3.002 3.002 0 0 1-3.758-.081L6.23 9.289a.51.51 0 0 0-.24.48 3.21 3.21 0 0 1 0 .5.51.51 0 0 0 .24.479l6.55 3.817a.5.5 0 0 0 .54 0 2.942 2.942 0 0 1 1.68-.56c1.657 0 3 1.342 3 2.998z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract72 svgRef={ref} {...props} />);
export default ForwardRef;
