import React from 'react';

const SvgPremium15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.08 8.26l-2.6-.2-1-2.43a.5.5 0 0 0-.92 0l-1 2.43-2.6.2a.51.51 0 0 0-.29.88l2 1.7L6 13.38a.5.5 0 0 0 .74.55L9 12.56l2.25 1.37a.5.5 0 0 0 .74-.55l-.61-2.54 2-1.7a.51.51 0 0 0-.3-.88zM16 12.12a5.93 5.93 0 0 1-2.32 4.73L9.6 20H8.4l-4.08-3.18A5.93 5.93 0 0 1 2 12.09V6.24a1 1 0 0 1 .29-.7L5.83 2h6.34l3.54 3.54a1 1 0 0 1 .29.7zm1.12-8L13.59.59A2 2 0 0 0 12.17 0H5.83a2 2 0 0 0-1.42.59L.88 4.12A3 3 0 0 0 0 6.24v5.85a8 8 0 0 0 3.09 6.31l4.08 3.18A2 2 0 0 0 8.4 22h1.2a2 2 0 0 0 1.23-.42l4.08-3.18A8 8 0 0 0 18 12.09V6.24a3 3 0 0 0-.88-2.12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium15 svgRef={ref} {...props} />);
export default ForwardRef;
