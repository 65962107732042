import React from 'react';

const SvgCommunication59 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 5.38a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM17 14H2V7.39l5.85 4.09a2.88 2.88 0 0 0 3.3 0l2.44-1.71A4.73 4.73 0 0 0 15 10a4.94 4.94 0 0 0 2-.42zM2 3h8.42a4.94 4.94 0 0 0 1.43 5.85l-1.7 1.2a1.14 1.14 0 0 1-1.3 0L2 5.25zm13-3a4.91 4.91 0 0 0-3 1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2V8a4.91 4.91 0 0 0 1-3 5 5 0 0 0-5-5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication59 svgRef={ref} {...props} />);
export default ForwardRef;
