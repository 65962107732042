import React from 'react';

const SvgCommunication18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5zm-4 4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5zM16 0H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication18 svgRef={ref} {...props} />);
export default ForwardRef;
