import React from 'react';

const SvgPayment17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 6.75a3.21 3.21 0 0 1 2 .67.5.5 0 0 0 .66-.05l.53-.54a.46.46 0 0 0 .15-.38.52.52 0 0 0-.19-.36A5 5 0 0 0 5.1 9h-.6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.6a5 5 0 0 0 8 2.91.52.52 0 0 0 .19-.36.46.46 0 0 0-.15-.38l-.53-.54a.5.5 0 0 0-.66-.05 3.21 3.21 0 0 1-2 .67A3.26 3.26 0 0 1 6.92 11h3.62a.76.76 0 0 0 .67-.41l.61-1.23a.25.25 0 0 0 0-.24.25.25 0 0 0-.22-.12H6.92A3.26 3.26 0 0 1 10 6.75zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment17 svgRef={ref} {...props} />);
export default ForwardRef;
