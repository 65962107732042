import React from 'react';

const SvgOther3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.86 11L8 15.5v-4.93l8-4.64v4.77zm-5-9h.28L15 4.2 7 8.83 3 6.55zm6.87 1.94l-.14-.25a2.13 2.13 0 0 0-.73-.69L12.14.27a2 2 0 0 0-1-.27h-.28a1.92 1.92 0 0 0-1 .27L1.14 5.32a2 2 0 0 0-.73.73l-.14.25a2 2 0 0 0-.27 1v5.76a2 2 0 0 0 .27 1l.14.25c.18.3.43.55.73.73l4.72 2.69a2 2 0 0 0 1 .27h.28a1.92 1.92 0 0 0 1-.27l8.72-5.05a2 2 0 0 0 .73-.73l.14-.25a2 2 0 0 0 .27-1V4.94a2 2 0 0 0-.27-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther3 svgRef={ref} {...props} />);
export default ForwardRef;
