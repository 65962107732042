import React from 'react';

const SvgAlerts10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 17 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.85 17.46l-.53.53a.502.502 0 0 1-.702 0l-2.003-1.999H1.002a1 1 0 0 1-1.002-1v-.589a.998.998 0 0 1 .29-.7l.832-.829a2.996 2.996 0 0 0 .882-2.119V7.996a5.86 5.86 0 0 1 .751-2.838L.15 2.559a.5.5 0 0 1 0-.71l.531-.53a.502.502 0 0 1 .702 0L16.88 16.782a.5.5 0 0 1-.03.68zm-2.824-6.03l-9.017-9A2.93 2.93 0 0 1 6.512 2h.5V.5a.5.5 0 0 1 .502-.5h1.001a.5.5 0 0 1 .501.5V2h.501a3.01 3.01 0 0 1 2.405 1.2l.901 1.2A5.993 5.993 0 0 1 14.026 8zM6.01 18h4.007c0 1.105-.897 2-2.003 2a2.002 2.002 0 0 1-2.004-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts10 svgRef={ref} {...props} />);
export default ForwardRef;
