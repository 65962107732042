import { observable, action } from 'mobx';

import { authStore, commonStore } from '.';

import { api } from '../services';

import K from '../constants';
import jsonTransformHelper from '../helpers/jsonTransformHelper';

const defaultIdAdesione = '';

class MoVeInStore {

  @observable
  exceptionFound = false;

  @observable
  draftIdAdesione = defaultIdAdesione;

  @observable
  loadingCheckIdAdesione = false;

  @observable
  idAdesione = null;

  @observable
  lastError = null;

  @observable
  registering = false;

  @observable
  registered = false;

  @observable user = {};

  @observable regionCodes;

  @observable vehicle = {};

  @observable vehicleType = {};

  @observable
  company = undefined;

  @observable
  address = {};

  @observable
  privacyPolicy = false;

  @observable
  loadingDataFromID = true;

  @action
  setExceptionFound = exceptionFound => {
    this.exceptionFound = exceptionFound;
  };

  @action
  setUser(user) {
    this.user = user;
  }

  @action
  setCompany(company) {
    this.company = company;
  }

  @action
  setRegionCodes(regionCodes) {
    this.regionCodes = regionCodes;
  }

  @action
  setVehicle(vehicle) {
    this.vehicle = vehicle;
  }

  @action
  setVehicleType(vehicleType) {
    this.vehicleType = vehicleType;
  }

  @action
  setVehicleAdditionalServiceConsent(privacyObject) {
    this.vehicle = {...this.vehicle, ...privacyObject};
  }

  @action
  unsetVehicleAdditionalServiceConsent() {
    const cleanedVehicle = this.vehicle;
    delete cleanedVehicle.reminder_product_consent;
    delete cleanedVehicle.airclub_general;
    delete cleanedVehicle.air_club_profiling;
    delete cleanedVehicle.air_club_marketing;
    delete cleanedVehicle.air_club_third_parties;

    this.vehicle = cleanedVehicle;
  }

  @action
  setAddress(address) {
    this.address = address;
  }

  @action
  setPrivacyPolicy(privacyPolicy) {
    this.lastError = null;
    this.privacyPolicy = privacyPolicy;
  }

  @observable
  installerList = [];

  @observable
  loadingInstallers = false;

  @observable
  loadingSubmitInstaller = false;

  @observable
  selectedInstaller = null;

  @observable
  existingData = undefined;

  constructor({ authStore, commonStore }) {
    this.commonStore = commonStore;
    this.authStore = authStore;

    this.authStore.setToken(K.API_STATIC_AUTHORIZATION_TOKEN);
  }

  @action
  setSelectedInstaller = selectedInstaller => {
    this.selectedInstaller = selectedInstaller;
  };

  @action
  setIdAdesione = idAdesione => {
    this.idAdesione = idAdesione;
  };

  @action
  setDraftIdAdesione = draftIdAdesione => {
    this.draftIdAdesione = draftIdAdesione;
    this.lastError = null;
  };

  @action
  setRegistered = registered => {
    this.registered = registered;
  };

  @action
  welcomeSubmit() {
    this.loadingCheckIdAdesione = true;
    return api('getDataFromIdAdesione', this.draftIdAdesione)
      .then(response => {
        this.lastError = null;
        this.idAdesione = response.idAdesione;
        this.draftIdAdesione = defaultIdAdesione;
        this.setUser(jsonTransformHelper.convertToUser(response.utente));
        this.setVehicle(jsonTransformHelper.convertToVehicle(response.veicolo));
        this.setRegionCodes(response.codiciRegione);
        if (response.societa !== undefined) {
          this.setCompany(jsonTransformHelper.convertToCompany(response.societa));
          this.setAddress(response.societa.sedeLegale);
        } else {
          this.setCompany(undefined);
        }
        this.existingData = response.existingData;
      })
      .catch(error => {
        this.lastError = { key: 'missingIdAdesione' };
        this.idAdesione = null;
        this.exceptionFound = true;
        console.log(error);
      })
      .finally(() => {
        this.loadingCheckIdAdesione = false;
      });
  }

  @action
  loadInstallers(province) {
    if (!province) {
      this.installerList = [];
      return;
    }

    this.loadingInstallers = true;
    return api('getInstallersByProvince', province)
      .then(response => {
        this.installerList = response;
      })
      .catch(error => {
        // this.lastError = { key: 'missingIdAdesione' };
        // this.idAdesione = null;
        this.exceptionFound = true;
        console.error(error);
      })
      .finally(() => {
        this.loadingInstallers = false;
      });
  }

  @action
  chooseInstaller(userUuid, vehicleUuid) {
    this.loadingSubmitInstaller = true;
    return api('chooseInstaller', userUuid, vehicleUuid, this.selectedInstaller.uuid)
      .catch(error => {
        // this.lastError = { key: 'missingIdAdesione' };
        // this.idAdesione = null;
        this.exceptionFound = true;
        console.error(error);
      })
      .finally(() => {
        this.loadingSubmitInstaller = false;
      });
  }

  @action
  registerUser(email, mobile, marketingAgreement, marketingThirdPartiesAgreement) {
    this.registering = true;

    const {airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties} = this.vehicle;
    let airClubConsents = [
      {
        identifier: 'airclub_general',
        version: 2
      },
      {
        identifier: 'air_club_profiling',
        version: 2
      },
      {
        identifier: 'air_club_marketing',
        version: 2
      },
      {
        identifier: 'air_club_third_parties',
        version: 2
      }
    ];
    const emailType = 'MOVEIN';
    const company =
      this.company !== undefined
        ? {
            ...this.company,
            registered_office: {
              ...this.company.registered_office,
              building_number: this.company.registered_office.building_number ? this.company.registered_office.building_number : 'snc'
            }
          }
        : undefined;
    const moveinUserReregistration = this.existingData !== undefined
    return api('registerUser', marketingAgreement, this.idAdesione, email, emailType, mobile, this.vehicle, this.user, company, moveinUserReregistration, this.regionCodes, marketingThirdPartiesAgreement)
      .then(() => {
        _iub.cons_instructions.push(["init", {api_key: K.IUBENDA_API_KEY}]);
        _iub.cons_instructions.push(["submit",   {
          consent: {
            subject: {
              id: this.vehicle.license_plate,
              email: email,
              mobile : mobile
            },
            preferences: {
              "privacy_policy": true,
              "marketing_policy" : marketingAgreement,
              "marketing_third_parties_policy" : marketingThirdPartiesAgreement,
              ...!!this.vehicle.reminder_product_consent && { airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties}
            },
            legal_notices: [
              {
                identifier: "privacy_policy"
              },
              {
                identifier: "marketing_policy"
              },
              {
                identifier: "marketing_third_parties_policy"
              },
              ...(this.vehicle.reminder_product_consent ? airClubConsents : [])
            ],
            proofs: [
              {
                content: JSON.stringify({ email: email, phone: mobile, privacyAgreement: true, marketingAgreement: marketingAgreement, marketingThirdPartiesAgreement, ...!!this.vehicle.reminder_product_consent && { airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties} }),
                form: document.getElementsByClassName('RegFormUserDataForm')[0].outerHTML
              }
            ]
          }
        },
          {
            error: (response) => {
              throw `iubenda error, ${response}`;
            }
          }
        ]);
        this.registered = true;
      })
      .catch(err => {
        const errorMessage = err.message || err;
        this.lastError = { key: 'registerUserRemoteError', meta: err.errorCode };
        this.exceptionFound = true;
        console.log('lastError ' + errorMessage);
      })
      .finally(() => {
        this.registering = false;
      });
  }
}

export default new MoVeInStore({ authStore, commonStore });
