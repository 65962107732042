import React from 'react';

const SvgAbstract47 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 17.955c-2.21 0-4-1.786-4-3.99 0-2.693 2.72-4.25 3-5.765a.25.25 0 0 1 .28-.2A6.577 6.577 0 0 1 12 13.965c0 2.204-1.79 3.99-4 3.99zM8.13.15A1.002 1.002 0 0 0 7.6 0h-.1a.5.5 0 0 0-.5.499v4.608a.499.499 0 0 1-.92.27A7.17 7.17 0 0 0 3.64 3.63a.471.471 0 0 0-.43 0 .488.488 0 0 0-.21.41c-.31 2.823-3 3.99-3 7.98C0 16.427 3.582 20 8 20s8-3.573 8-7.98C16 5.756 10.54 1.656 8.13.15z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract47 svgRef={ref} {...props} />);
export default ForwardRef;
