import React from 'react';

const SvgCommunication4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 18.002a10.07 10.07 0 0 1-4.78-1.23l-.45-.25a18 18 0 0 1-7.33-7.33l-.25-.45A10.07 10.07 0 0 1 0 4.002v-.67a2 2 0 0 1 .59-1.42L2.28.222a.75.75 0 0 1 1.18.15l2.25 3.86a1 1 0 0 1-.16 1.21l-1.89 1.89a.5.5 0 0 0-.09.59l.35.66a13.53 13.53 0 0 0 5.5 5.49l.66.36a.5.5 0 0 0 .59-.09l1.89-1.89a1 1 0 0 1 1.21-.16l3.86 2.25a.75.75 0 0 1 .15 1.18l-1.69 1.69a2 2 0 0 1-1.42.59zm0-10.59a.5.5 0 0 1-.5.57h-1a.49.49 0 0 1-.49-.4 2 2 0 0 0-1.59-1.58.49.49 0 0 1-.4-.49v-1a.5.5 0 0 1 .17-.38.52.52 0 0 1 .4-.13A4.05 4.05 0 0 1 14 7.412z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication4 svgRef={ref} {...props} />);
export default ForwardRef;
