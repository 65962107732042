import React from 'react';

const SvgCommunication30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.998 1v8.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V3.42L1.558 15.85a.5.5 0 0 1-.71 0l-.7-.7a.5.5 0 0 1 0-.71L12.588 2h-6.09a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h8.5c.199 0 .39.08.53.22l.27.27c.128.139.2.32.2.51z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication30 svgRef={ref} {...props} />);
export default ForwardRef;
