import React from 'react';

const SvgDevices16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 2v11h10v3H1a1 1 0 0 1-1-1v-.5a.5.5 0 0 1 .5-.5H2V1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v1zm20 4v8a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm-2 0h-4v8h4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices16 svgRef={ref} {...props} />);
export default ForwardRef;
