import React from 'react';

const SvgAbstract36 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 2.17L13.17 5H4.83L2 2.17V2h14zM17 0H1a1 1 0 0 0-1 1v1.17a2 2 0 0 0 .59 1.42l5.82 5.82A2 2 0 0 1 7 10.83v6.67a.5.5 0 0 0 .8.4l2.4-1.8a2 2 0 0 0 .8-1.6v-3.67a2 2 0 0 1 .59-1.42l5.82-5.82A2 2 0 0 0 18 2.17V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract36 svgRef={ref} {...props} />);
export default ForwardRef;
