import React from 'react';

const SvgAlerts15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.672 5H8.318a.497.497 0 0 0-.498.56l.398 3.22a.25.25 0 0 0 .25.22h1.055a.25.25 0 0 0 .249-.22l.398-3.22A.501.501 0 0 0 9.672 5zM9.5 11h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm6.5.49a1 1 0 0 1-.29.7l-3.52 3.52a1 1 0 0 1-.7.29h-5a1 1 0 0 1-.7-.29l-3.5-3.52a1 1 0 0 1-.29-.7v-5a1 1 0 0 1 .29-.7l3.52-3.5a1 1 0 0 1 .7-.29h5a1 1 0 0 1 .7.29l3.52 3.52a1 1 0 0 1 .29.7zm1.12-7.1L13.61.88A3 3 0 0 0 11.49 0h-5a3 3 0 0 0-2.12.88L.88 4.39A3 3 0 0 0 0 6.51v5a3 3 0 0 0 .88 2.12l3.51 3.51a3 3 0 0 0 2.12.86h5a3 3 0 0 0 2.12-.88l3.51-3.51a3 3 0 0 0 .86-2.12v-5a3 3 0 0 0-.88-2.1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts15 svgRef={ref} {...props} />);
export default ForwardRef;
