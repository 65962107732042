import React from 'react';

const SvgActions117 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8.622 6a.49.49 0 0 0-.49.41l1.75 1.75V6.5a.5.5 0 0 0-.5-.5zm.7-2a7 7 0 0 1 6.13 9.72l1.49 1.49a9 9 0 0 0-.94-9.84l1.13-1.13a.5.5 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.71 0L14.602 4a9 9 0 0 0-3.6-1.77V1a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v1.23a9 9 0 0 0-2.22.83l1.49 1.49A7 7 0 0 1 9.322 4zm-7.32 7a6.93 6.93 0 0 1 1.48-4.28l9.8 9.8A6.93 6.93 0 0 1 9.002 18a7 7 0 0 1-7-7zm-.62-8.85a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l1.91 1.92a9 9 0 0 0 12.64 12.64l1.92 1.91a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions117 svgRef={ref} {...props} />);
export default ForwardRef;
