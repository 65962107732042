import React from 'react';

const SvgAlerts2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.672 6H9.318a.497.497 0 0 0-.498.56l.398 3.22a.25.25 0 0 0 .25.22h1.055a.25.25 0 0 0 .249-.22l.398-3.22a.501.501 0 0 0-.498-.56zm-.172 6h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-.5 6a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts2 svgRef={ref} {...props} />);
export default ForwardRef;
