import React, { Component } from 'react';
import classNames from 'classnames';

import './SmallDivision.css';

export default class SmallDivision extends Component {
  render() {
    const cls = classNames({
      SmallDivision: true
    });
    const { title, icon } = this.props;
    return (
      <div className={cls} style={this.props.style}>
        <div>{icon}</div>
        <div>{title}</div>
      </div>
    );
  }
}
