import React from 'react';

const SvgAbstract28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.56 1.098l-.658-.659A1.5 1.5 0 0 0 15.845 0h-.758a1.496 1.496 0 0 0-1.078.44l-1.806 1.826a.5.5 0 0 0 0 .698l2.804 2.844a.499.499 0 0 0 .708 0l1.846-1.816c.286-.285.444-.674.439-1.078v-.758a1.49 1.49 0 0 0-.44-1.058zm-6.594 3.074a.499.499 0 0 0-.708 0l-8.052 8.053a1.996 1.996 0 0 0-.449.68l-1.716 4.33a.5.5 0 0 0 .19.62.499.499 0 0 0 .538.109l4.33-1.757c.255-.101.486-.254.679-.449l8.041-8.073a.5.5 0 0 0 0-.699z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract28 svgRef={ref} {...props} />);
export default ForwardRef;
