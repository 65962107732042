import React from 'react';

const SvgActions89 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 8l4-5.33L15 8zm6.85 7.62a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0L11 15.24l-1.62 1.61a.48.48 0 0 1-.7 0l-.53-.53a.48.48 0 0 1 0-.7L9.76 14l-1.61-1.62a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0L11 12.76l1.62-1.61a.48.48 0 0 1 .7 0l.53.53a.48.48 0 0 1 0 .7L12.24 14zM21.5 8h-4L11.73.3a.77.77 0 0 0-.6-.3h-.26a.77.77 0 0 0-.6.3L4.5 8h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1l1.71 7.65A3 3 0 0 0 5.6 20h10.8a3 3 0 0 0 2.92-2.35L21 10h.47a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.47-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions89 svgRef={ref} {...props} />);
export default ForwardRef;
