import React from 'react';

const SvgArrows2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 8" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.004 3.003v-2a.5.5 0 0 1 .85-.36l3.002 3a.51.51 0 0 1 0 .71l-3.002 3a.5.5 0 0 1-.85-.35v-2H3.996v2a.5.5 0 0 1-.85.35l-3.002-3a.51.51 0 0 1 0-.71l3.002-3a.5.5 0 0 1 .85.36v2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows2 svgRef={ref} {...props} />);
export default ForwardRef;
