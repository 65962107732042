import React from 'react';

const SvgCommunication48 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.969.949l-5.99 16.722a.5.5 0 0 1-.468.329h-.16a.51.51 0 0 1-.46-.29l-2.354-4.99a1.997 1.997 0 0 1 .34-2.206l2.994-3.275a.5.5 0 0 0 0-.688l-.38-.38a.499.499 0 0 0-.688 0L7.528 9.165a1.996 1.996 0 0 1-2.206.34L.33 7.147A.51.51 0 0 1 0 6.69v-.16a.5.5 0 0 1 .33-.509L17.05.03a.5.5 0 0 1 .519.11l.26.26a.5.5 0 0 1 .139.549z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication48 svgRef={ref} {...props} />);
export default ForwardRef;
