import React from 'react';

const SvgActions99 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.997.002c-.7-.001-1.38.243-1.92.69a.28.28 0 0 0-.089.19.24.24 0 0 0 .07.18l3.878 3.88a.24.24 0 0 0 .18.07.28.28 0 0 0 .19-.09 3.001 3.001 0 0 0-2.31-4.92zM4.944 1.062a.24.24 0 0 0 .06-.18.28.28 0 0 0-.09-.19 2.997 2.997 0 0 0-4.038.19 3.001 3.001 0 0 0-.18 4.04.28.28 0 0 0 .19.08.24.24 0 0 0 .18-.07zm7.555 5.94H7.502a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3.248l-3.478 3.71a1 1 0 0 0-.27.69v1.1a.5.5 0 0 0 .5.5H12.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H9.251l3.478-3.71a1 1 0 0 0 .27-.69v-1.1a.5.5 0 0 0-.5-.5zm-9.494 4a7 7 0 0 1 7-7 7 7 0 1 1-7 7zm6.996-9c-4.968 0-8.995 4.03-8.995 9s4.027 9 8.995 9c4.968 0 8.995-4.03 8.995-9s-4.027-9-8.995-9z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions99 svgRef={ref} {...props} />);
export default ForwardRef;
