import React, { Component, Fragment } from 'react';
import { withWindowSize } from 'air-with-window-size';
import GoogleMaps from '../../../../../common/components/GoogleMaps';
import { Collapse } from 'antd';
import installers from '../../installers';

import './InstallersCard.css';

const { Panel } = Collapse;

class InstallersCard extends Component {
  constructor(props) {
    super(props);
    const {
      dataCollection: {
        installersData: { areas },
        installers
      }
    } = this.props;
    this.defaultCenter = {
      lat: 45.233034,
      lng: 8.831586
    };
  }

  onMapReady = (mapProps, map) => {
    let installers = this.props.dataCollection.installers;
    const { google } = mapProps;
    installers = installers.map(installer => {
      if (installer.hasOwnProperty('lat') && installer.hasOwnProperty('lng')) {
        const marker = new google.maps.Marker({
          position: {
            lat: Number(installer.lat),
            lng: Number(installer.lng)
          },
          map: map
        });
        installer = { ...installer, marker };
      } else {
        console.log(`Installer ${installer.code} misses position`);
      }
      return installer;
    });
    this.setState({ installers });
  };

  render() {
    const {
      dataCollection: {
        installersData: { areas },
        installers
      },
      theme
    } = this.props;
    const widthMap = theme === 'mobile' ? this.props.documentWidth - 32 || 0 : (this.props.width / 3) * 2 || 0;

    return (
      <div style={{ display: 'flex', flexDirection: theme === 'mobile' ? 'column' : undefined }}>
        <div style={{ position: 'relative', height: 400, width: widthMap }}>
          <GoogleMaps className="InstallersCard" onReady={this.onMapReady} zoom={8} initialCenter={this.defaultCenter} markers={installers} />
        </div>
        <div className="InstallersList" style={theme === 'mobile' ? { flex: '1 0 0', marginTop: 20 } : { flex: '1 0 0', marginLeft: 16, height: 400, overflowY: 'scroll' }}>
          <Collapse accordion>
            {areas
              .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
              .map((area, index) => {
                return (() => {
                  const areaInstallers = installers.filter(installer => installer.admdiv2 === area.code);
                  return (
                    areaInstallers.length > 0 && (
                      <Panel header={<div className="InstallersListAreaTitle">{area.value}</div>} key={index}>
                        {areaInstallers.map((installer, index) => (
                          <Fragment key={index}>
                            <div className="InstallersListItemTitle">{installer.businessName}</div>
                            <div className="InstallersListItemDescription">
                              {installer.address}
                              <br />
                              {installer.postCode} {installer.admdiv3} {installer.admdiv2}
                              <br />
                              TEL: {installer.phone}
                            </div>
                          </Fragment>
                        ))}
                      </Panel>
                    )
                  );
                })();
              })}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withWindowSize(InstallersCard);
