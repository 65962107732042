import React from 'react';

const SvgDevices25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 3h-1v16h1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-5 16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 1 2 0zM11 0H3a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices25 svgRef={ref} {...props} />);
export default ForwardRef;
