import React from 'react';

const SvgActions17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.918 8.35l-.2-.2a.49.49 0 0 0-.36-.15h-2.36a6.003 6.003 0 0 1 6-6.006 5.825 5.825 0 0 1 2.79.7.51.51 0 0 0 .59-.09l.74-.74a.5.5 0 0 0 .14-.42.52.52 0 0 0-.26-.371A7.993 7.993 0 0 0 7 1.07 8.01 8.01 0 0 0 2.998 8H.708a.49.49 0 0 0-.36.15l-.2.2a.5.5 0 0 0 0 .711l3.53 3.544a.51.51 0 0 0 .71 0l3.53-3.544a.5.5 0 0 0 0-.71zm14.088-1.056a.5.5 0 0 0-.148-.355l-3.533-3.544a.51.51 0 0 0-.71 0L14.083 6.94a.5.5 0 0 0 0 .71l.2.2a.49.49 0 0 0 .36.151h2.362A6.007 6.007 0 0 1 11 14.006c-.97 0-1.925-.237-2.782-.69a.5.5 0 0 0-.59.09l-.731.73a.5.5 0 0 0-.14.42.52.52 0 0 0 .24.371A8.003 8.003 0 0 0 15 14.93 8.009 8.009 0 0 0 19.006 8h2.291a.49.49 0 0 0 .36-.15l.2-.2a.5.5 0 0 0 .149-.356z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions17 svgRef={ref} {...props} />);
export default ForwardRef;
