import React from 'react';

const SvgActions47 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3.5 12h1a.5.5 0 0 0 .5-.5V8a1 1 0 0 0-1-1H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H3v2.5a.5.5 0 0 0 .5.5zm15.5-.5V9h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H18a1 1 0 0 0-1 1v3.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zM5 4V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V3H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H4a1 1 0 0 0 1-1zm13 1h3.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H19V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V4a1 1 0 0 0 1 1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions47 svgRef={ref} {...props} />);
export default ForwardRef;
