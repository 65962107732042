import React from 'react';

const SvgFiles18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9 10.5a.5.5 0 0 1-.78.42l-1.94-1.3a.52.52 0 0 0-.56 0l-1.94 1.3A.5.5 0 0 1 3 10.5v-7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM18 2h-5.59a1 1 0 0 1-.7-.29L10.29.29a1 1 0 0 0-.7-.29H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles18 svgRef={ref} {...props} />);
export default ForwardRef;
