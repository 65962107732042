import React from 'react';

const SvgArrows40 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.848 12.933a.5.5 0 0 1 0 .71l-4.13 4.137a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22l-4.13-4.138a.5.5 0 0 1 0-.71l.71-.709a.49.49 0 0 1 .7 0l3.44 3.438 3.44-3.448a.5.5 0 0 1 .71 0zm-9.7-7.866a.5.5 0 0 1 0-.71L4.278.22a.75.75 0 0 1 .53-.22h.38a.77.77 0 0 1 .53.22l4.13 4.138a.5.5 0 0 1 0 .71l-.71.709a.49.49 0 0 1-.7 0l-3.44-3.448-3.44 3.448a.5.5 0 0 1-.71 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows40 svgRef={ref} {...props} />);
export default ForwardRef;
