import React from 'react';

const SvgActions90 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.37 17.22a1 1 0 0 1-1 .78H5.6a1 1 0 0 1-1-.78L3 10h16zM11 2.67L15 8H7zM21.5 8h-4L11.73.3a.77.77 0 0 0-.6-.3h-.26a.77.77 0 0 0-.6.3L4.5 8h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1l1.71 7.65A3 3 0 0 0 5.6 20h10.8a3 3 0 0 0 2.92-2.35L21 10h.47a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.47-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions90 svgRef={ref} {...props} />);
export default ForwardRef;
