import React from 'react';

const SvgAbstract51 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M4.115 13.185a.5.5 0 0 0-.71 0L.149 16.444a.5.5 0 0 0 0 .71l.7.7a.5.5 0 0 0 .71 0l3.257-3.259a.5.5 0 0 0 0-.709zm9.433-6.615a1.499 1.499 0 1 1 0-2.118 1.489 1.489 0 0 1 0 2.118zM17.695.734l-.43-.43a1 1 0 0 0-.889-.29l-5.496 1a3.887 3.887 0 0 0-2.358 1.41L6.364 5.2a2.998 2.998 0 0 0-3.458 1.09L.088 10.287a.5.5 0 0 0 .12.69l.41.289a2.998 2.998 0 0 0 4.177-.74l.63-.889 2.907 2.908-.889.63a2.998 2.998 0 0 0-.74 4.177l.29.41a.5.5 0 0 0 .69.12l3.997-2.819a2.998 2.998 0 0 0 1.09-3.457l2.747-2.159a3.887 3.887 0 0 0 1.469-2.328l1-5.496a1 1 0 0 0-.29-.89z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract51 svgRef={ref} {...props} />);
export default ForwardRef;
