import React from 'react';

const SvgOther48 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.994 10a2.998 2.998 0 0 0-2.818 2h-1.179A2 2 0 0 1 11 10V6c0-2.209-1.79-4-3.997-4h-1.18A2.997 2.997 0 0 0 0 3a2.997 2.997 0 1 0 5.823 1h1.18A2 2 0 0 1 9 6v4c0 2.21 1.79 4 3.996 4h1.18a2.997 2.997 0 0 0 5.803-1.344A2.998 2.998 0 0 0 16.994 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther48 svgRef={ref} {...props} />);
export default ForwardRef;
