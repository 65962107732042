import React from 'react';

const SvgDevices7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 8V2h14v6zm16 0V7h1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-1V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices7 svgRef={ref} {...props} />);
export default ForwardRef;
