import React from 'react';

const SvgOther53 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.986 0h-1.993a.995.995 0 0 0-.804.372 1.003 1.003 0 0 0-.192.868l.498 2a.997.997 0 0 0 .996.76h1.495c.55 0 .996-.447.996-1V1c0-.552-.446-1-.996-1zm.78 6.357a.995.995 0 0 0-.78-.357H13.73a.995.995 0 0 0-.847.42l-2.81 4.23a4.012 4.012 0 0 0-.617 2.88l.12.75-7.97-1.14a.994.994 0 0 0-.846.29l-.468.46a1.005 1.005 0 0 0-.25 1l.997 3.64A1.993 1.993 0 0 0 3.032 20h9.663a.997.997 0 0 0 .996-.81l2.29-12a1.003 1.003 0 0 0-.215-.833z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther53 svgRef={ref} {...props} />);
export default ForwardRef;
