import React from 'react';

const SvgCommunication25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 7l-6.35 4.45a2.88 2.88 0 0 1-3.3 0L2 7V4.9l7.35 5.15c.39.271.91.271 1.3 0L18 4.9zm0-7H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication25 svgRef={ref} {...props} />);
export default ForwardRef;
