import React from 'react';

const SvgArrows28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 5" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.858 1.06a.5.5 0 0 1 0-.71l.2-.2a.49.49 0 0 1 .36-.15h7.16a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71l-3.79 3.79a.48.48 0 0 1-.7 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows28 svgRef={ref} {...props} />);
export default ForwardRef;
