import React from 'react';

const SvgArrows10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.942.858a.5.5 0 0 1 .71 0l.2.2a.49.49 0 0 1 .15.36v3.58h7.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-7.5v3.58a.49.49 0 0 1-.15.36l-.2.2a.5.5 0 0 1-.71 0l-6.79-6.79a.48.48 0 0 1 0-.7zm7.06 8.14v-2h-7.5a.5.5 0 0 1-.5-.5v-1.88l-3.38 3.38 3.38 3.38v-1.88a.5.5 0 0 1 .5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows10 svgRef={ref} {...props} />);
export default ForwardRef;
