import React from 'react';

const SvgOther42 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 12.748a10.219 10.219 0 0 1-10.78 7.203A10.214 10.214 0 0 1 .05 10.79 10.21 10.21 0 0 1 7.258.019a.54.54 0 0 1 .51.13l.09.09a.55.55 0 0 1 .15.42 10.175 10.175 0 0 0 2.895 8.438 10.194 10.194 0 0 0 8.435 2.921.55.55 0 0 1 .42.15l.09.09a.54.54 0 0 1 .131.51z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther42 svgRef={ref} {...props} />);
export default ForwardRef;
