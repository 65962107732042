import React from 'react';

const SvgActions48 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.876 16.472a.5.5 0 0 1-.02.68l-.7.7a.501.501 0 0 1-.712 0l-4.006-4a2.602 2.602 0 0 1-.3-.36l-.751-1a7.017 7.017 0 0 1-9.534-.73l-1.001 1a.491.491 0 0 1-.852-.35v-3.88a.52.52 0 0 1 .501-.5h3.896a.5.5 0 0 1 .37.88l-1.462 1.47a5.012 5.012 0 0 0 8.542-2 .5.5 0 0 1 .481-.36h1.002a.49.49 0 0 1 .39.19.53.53 0 0 1 .1.42 6.996 6.996 0 0 1-1.382 2.8l1.002.69c.156.1.3.218.43.35zM.291 5.842a.53.53 0 0 1-.1-.42A7.005 7.005 0 0 1 5.25.227a7.017 7.017 0 0 1 6.957 2.065l1.001-1a.491.491 0 0 1 .852.35v3.89a.52.52 0 0 1-.501.5H9.624a.5.5 0 0 1-.35-.85l1.462-1.47a5.012 5.012 0 0 0-8.543 2 .5.5 0 0 1-.48.32H.71a.491.491 0 0 1-.42-.19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions48 svgRef={ref} {...props} />);
export default ForwardRef;
