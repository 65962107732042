import { observable, action, computed } from 'mobx';
import { createTransformer } from 'mobx-utils';
import moment from 'moment';

const en = require('date-fns/locale/en');
const it = require('date-fns/locale/it');

class CommonStore {
  constructor() {
    this.storagePreferencesNamespace = 'io.myair.mypage.preferences';
  }

  @observable
  longDateFormat;

  @action
  setLongDateFormat(longDateFormat) {
    /**
     * L:"MM/DD/YYYY"
     * LL:"MMMM D, YYYY"
     * LLL:"MMMM D, YYYY h:mm A"
     * LLLL:"dddd, MMMM D, YYYY h:mm A"
     * LT:"h:mm A"
     * LTS:"h:mm:ss A"
     */
    this.longDateFormat = longDateFormat;
  }

  @computed
  get dateFnsLocale() {
    return moment.locale() === 'en' ? en : it;
  }

  @computed
  get momentLocale() {
    return moment.locale();
  }

  @computed get getStoragePreferencesKey() {
    return createTransformer(key => {
      const storedValue = JSON.parse(window.localStorage.getItem(this.storagePreferencesNamespace) || '{}');
      return storedValue[key];
    });
  }

  @action
  setStoragePreferencesKey = (key, value) => {
    let storedValue = JSON.parse(window.localStorage.getItem(this.storagePreferencesNamespace) || '{}');
    storedValue = storedValue !== null ? { ...storedValue, ...{ [key]: value } } : { [key]: value };
    window.localStorage.setItem(this.storagePreferencesNamespace, JSON.stringify(storedValue));
  };
}

export default new CommonStore();
