import React from 'react';

const SvgOther67 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 2v6H3V2zm3-1.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1v14a4 4 0 1 0 8 0V2h.5a.5.5 0 0 0 .5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther67 svgRef={ref} {...props} />);
export default ForwardRef;
