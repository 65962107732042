import React from 'react';

const SvgPremium6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 15.46L7.22 6.7a1 1 0 0 0-1-.7H2.21l3.67-4h8.24l-2.86 3.16a.5.5 0 0 0 .37.84h6.16zm9.74-10.3L15.59.65A2 2 0 0 0 14.12 0H5.88a2 2 0 0 0-1.47.65L.26 5.16a1 1 0 0 0-.26.68v.3a1 1 0 0 0 .23.64l9 10.86a1 1 0 0 0 1.54 0l9-10.86a1 1 0 0 0 .23-.64v-.3a1 1 0 0 0-.26-.68z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium6 svgRef={ref} {...props} />);
export default ForwardRef;
