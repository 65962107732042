import React from 'react';

const SvgCommunication36 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication36 svgRef={ref} {...props} />);
export default ForwardRef;
