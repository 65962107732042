import React from 'react';

const SvgArrows34 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 7 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.778 5.278c.14.14.22.331.22.53v.38a.77.77 0 0 1-.22.53l-5.14 5.13a.5.5 0 0 1-.71 0l-.71-.71a.49.49 0 0 1 0-.7l4.45-4.44-4.45-4.44a.5.5 0 0 1 0-.71l.71-.7a.5.5 0 0 1 .71 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows34 svgRef={ref} {...props} />);
export default ForwardRef;
