import React from 'react';

const SvgAlerts14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 14.41V15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-.59a1 1 0 0 1 .29-.7l.83-.83A3 3 0 0 0 2 10.76V8a6 6 0 0 1 1.2-3.6l.9-1.2A3 3 0 0 1 6.5 2H7V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V2h.5a3 3 0 0 1 2.4 1.2l.9 1.2A6 6 0 0 1 14 8v2.76a3 3 0 0 0 .88 2.12l.83.83a1 1 0 0 1 .29.7zM6 18h4a2 2 0 1 1-4 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts14 svgRef={ref} {...props} />);
export default ForwardRef;
