import React from 'react';

const SvgOther16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9 12v-2h5v2zm-7-2V8h5v2zm5-6v2H2V4zm7 2v2H9V6zm1.5-2H9a2 2 0 0 0-2-2H2V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v19a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V12h5a2 2 0 0 0 2 2h6.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther16 svgRef={ref} {...props} />);
export default ForwardRef;
