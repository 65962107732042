import React from 'react';

const SvgOther73 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.2 13.08l-.78.75a.6.6 0 0 1-.84 0l-.78-.75C7.9 12.2 6 10.5 6 9.14A2.17 2.17 0 0 1 8.2 7a2.59 2.59 0 0 1 1.8.8 2.59 2.59 0 0 1 1.8-.8A2.17 2.17 0 0 1 14 9.14c0 1.36-1.9 3.06-2.8 3.94zM18 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H2v-5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 9H2V4H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 2H2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zM4 18h12V2H4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther73 svgRef={ref} {...props} />);
export default ForwardRef;
