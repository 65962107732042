import React from 'react';

const SvgPayment26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 13a2 2 0 0 1-2 2H7.68a.52.52 0 0 1-.37-.16l-.26-.29A.51.51 0 0 1 7 14a8.31 8.31 0 0 0 .79-2.51 2.2 2.2 0 0 0-.06-.5H6.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h.64A4.38 4.38 0 0 1 7 8a3 3 0 0 1 5.94-.6.53.53 0 0 1-.1.42.53.53 0 0 1-.39.18h-.77a.5.5 0 0 1-.48-.36A1.25 1.25 0 0 0 8.75 8c.025.34.092.676.2 1h1.55a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1c.01.167.01.333 0 .5a6 6 0 0 1-.36 1.75H12a.25.25 0 0 0 .25-.25v-.25a.5.5 0 0 1 .5-.5h.75a.5.5 0 0 1 .5.5zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment26 svgRef={ref} {...props} />);
export default ForwardRef;
