import React from 'react';

const SvgPayment22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 0a7 7 0 1 1 0 14 6.98 6.98 0 0 1-5-2.105A6.98 6.98 0 0 1 7 14 7 7 0 1 1 7 0a6.98 6.98 0 0 1 5 2.105A6.98 6.98 0 0 1 17 0m0 12c2.757 0 5-2.243 5-5s-2.243-5-5-5c-1.523 0-2.872.699-3.79 1.776a6.97 6.97 0 0 1 0 6.447C14.128 11.301 15.477 12 17 12" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment22 svgRef={ref} {...props} />);
export default ForwardRef;
