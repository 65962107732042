import React from 'react';

const SvgOther24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11 .5v1a.5.5 0 0 1-.5.499H10v3.518c-.002.67.333 1.298.89 1.67A6.995 6.995 0 0 1 7 20 6.995 6.995 0 1 1 3.11 7.187c.558-.372.893-1 .89-1.67V2h-.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5zM8 1.999H6v3.518a3.998 3.998 0 0 1-1.78 3.329A4.997 4.997 0 0 0 2 12.994h10a4.997 4.997 0 0 0-2.22-4.148A3.998 3.998 0 0 1 8 5.517z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther24 svgRef={ref} {...props} />);
export default ForwardRef;
