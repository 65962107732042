import React from 'react';

const SvgAlerts3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.772 9.78a.25.25 0 0 1-.25.22H9.468a.25.25 0 0 1-.249-.22L8.82 6.56A.501.501 0 0 1 9.318 6h1.354a.497.497 0 0 1 .498.56zM11 13.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts3 svgRef={ref} {...props} />);
export default ForwardRef;
