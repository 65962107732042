import React from 'react';

const SvgAbstract79 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M13.85 8.07l-.71.71a.49.49 0 0 1-.7 0L11 7.33v8.17a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V7.33L7.56 8.78a.5.5 0 0 1-.71 0l-.7-.71a.5.5 0 0 1 0-.71l3.13-3.14A.75.75 0 0 1 9.81 4h.38a.77.77 0 0 1 .53.22l3.13 3.14a.5.5 0 0 1 0 .71zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract79 svgRef={ref} {...props} />);
export default ForwardRef;
