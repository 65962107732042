import React from 'react';

const SvgNumeroMobile = ({ svgRef, title, ...props }) => (

    <svg xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em"
         viewBox="0 0 2279 821"
         ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}
    >
      <path id="Selection"
            fill="#56d3cb" stroke="black"
            d="M 405.00,85.00
           C 405.00,98.35 403.30,117.23 392.96,126.82
             380.21,138.63 349.32,137.61 337.47,124.82
             329.57,116.29 327.02,101.27 327.00,90.00
             327.00,90.00 327.85,35.00 327.85,35.00
             328.91,26.13 330.62,17.33 336.16,10.04
             345.52,-2.27 356.42,0.00 370.00,0.00
             380.29,0.00 386.94,-1.17 394.82,7.09
             403.62,16.32 404.03,30.03 405.00,42.00
             405.00,42.00 380.00,42.00 380.00,42.00
             379.88,36.39 378.99,26.59 375.35,22.21
             369.04,14.60 358.37,19.60 354.48,29.00
             351.49,36.24 352.00,44.34 352.00,52.00
             352.00,52.00 352.00,87.00 352.00,87.00
             352.01,93.80 352.63,102.33 356.80,107.96
             361.07,113.72 369.19,116.55 374.78,110.85
             380.47,105.06 380.00,92.65 380.00,85.00
             380.00,85.00 405.00,85.00 405.00,85.00 Z
           M 500.00,1.00
           C 500.00,1.00 523.00,1.00 523.00,1.00
             523.00,1.00 523.00,132.00 523.00,132.00
             523.00,132.00 500.00,132.00 500.00,132.00
             500.00,132.00 500.00,1.00 500.00,1.00 Z
           M 542.00,1.00
           C 542.00,1.00 565.00,1.00 565.00,1.00
             565.00,1.00 565.00,132.00 565.00,132.00
             565.00,132.00 542.00,132.00 542.00,132.00
             542.00,132.00 542.00,1.00 542.00,1.00 Z
           M 664.00,1.00
           C 664.00,1.00 687.00,1.00 687.00,1.00
             687.00,1.00 687.00,132.00 687.00,132.00
             687.00,132.00 664.00,132.00 664.00,132.00
             664.00,132.00 664.00,1.00 664.00,1.00 Z
           M 108.00,4.02
           C 113.41,2.21 121.18,3.00 127.00,3.00
             127.00,3.00 161.00,3.00 161.00,3.00
             161.00,3.00 176.00,4.00 176.00,4.00
             176.00,4.00 244.00,4.00 244.00,4.00
             245.89,4.00 249.01,3.86 250.71,4.57
             253.99,5.94 263.32,17.54 264.44,21.00
             265.19,23.31 265.03,28.40 265.00,31.00
             264.89,39.95 259.32,61.05 257.21,71.00
             257.21,71.00 238.00,159.00 238.00,159.00
             238.00,159.00 231.00,191.00 231.00,191.00
             230.16,195.05 229.12,203.28 226.47,206.25
             224.30,208.67 211.97,217.42 209.00,218.07
             207.05,218.60 202.43,218.01 200.00,218.07
             200.00,218.07 185.00,219.17 185.00,219.17
             173.42,220.31 159.45,224.17 149.00,229.26
             143.62,231.88 139.57,234.39 135.00,238.30
             117.76,253.05 112.44,268.09 110.91,290.00
             110.91,290.00 110.00,302.00 110.00,302.00
             110.00,302.00 109.00,321.00 109.00,321.00
             109.00,321.00 108.00,352.00 108.00,352.00
             108.00,352.00 107.00,367.00 107.00,367.00
             107.00,367.00 105.00,414.00 105.00,414.00
             105.00,414.00 103.96,430.00 103.96,430.00
             103.96,430.00 103.96,441.00 103.96,441.00
             103.96,441.00 103.00,452.00 103.00,452.00
             103.00,452.00 102.00,475.00 102.00,475.00
             102.00,475.00 101.00,496.00 101.00,496.00
             101.00,496.00 99.96,517.00 99.96,517.00
             99.96,517.00 99.96,528.00 99.96,528.00
             99.96,528.00 99.00,538.00 99.00,538.00
             98.85,551.06 102.62,555.68 110.37,566.00
             123.41,583.35 140.60,595.10 162.00,599.20
             162.00,599.20 197.00,601.83 197.00,601.83
             201.52,602.26 206.13,601.07 209.79,604.31
             213.33,607.45 215.22,613.53 216.37,618.00
             218.90,627.83 220.22,637.90 220.91,648.00
             220.91,648.00 227.09,732.00 227.09,732.00
             227.09,732.00 230.04,770.00 230.04,770.00
             230.04,770.00 230.91,778.00 230.91,778.00
             230.91,778.00 232.07,798.00 232.07,798.00
             232.52,801.66 233.94,807.46 232.07,810.79
             228.35,816.95 212.56,817.99 206.00,818.00
             206.00,818.00 149.00,818.00 149.00,818.00
             149.00,818.00 132.00,819.00 132.00,819.00
             132.00,819.00 80.00,819.00 80.00,819.00
             71.80,818.84 71.54,813.93 68.58,807.00
             68.58,807.00 55.58,775.00 55.58,775.00
             37.60,727.35 23.02,675.25 14.59,625.00
             8.60,589.32 4.38,554.05 1.91,518.00
             1.91,518.00 1.00,506.00 1.00,506.00
             1.00,506.00 0.00,488.00 0.00,488.00
             0.00,488.00 0.00,411.00 0.00,411.00
             0.00,411.00 1.00,389.00 1.00,389.00
             1.00,389.00 1.91,379.00 1.91,379.00
             1.91,379.00 4.83,341.00 4.83,341.00
             8.35,305.84 12.83,270.65 20.00,236.00
             25.74,208.29 33.65,181.15 41.58,154.00
             50.77,122.53 59.68,90.80 70.95,60.00
             73.99,51.69 79.38,36.03 83.96,29.00
             86.81,24.64 92.07,20.13 95.72,16.00
             99.72,11.46 102.88,7.43 108.00,4.02 Z
           M 483.00,98.00
           C 483.00,107.69 482.22,118.15 475.47,125.82
             464.83,137.91 434.78,136.72 424.21,124.96
             418.45,118.55 416.10,107.43 416.00,99.00
             416.00,99.00 416.00,62.00 416.00,62.00
             416.17,47.86 422.64,35.82 437.00,31.93
             441.68,30.66 449.00,30.95 454.00,31.00
             460.92,31.08 468.53,32.73 473.90,37.39
             485.50,47.47 483.00,73.76 483.00,88.00
             483.00,88.00 439.00,88.00 439.00,88.00
             439.00,94.85 437.90,106.22 441.99,111.84
             445.75,116.90 453.27,117.35 457.00,111.84
             459.68,107.97 459.96,102.59 460.00,98.00
             460.00,98.00 483.00,98.00 483.00,98.00 Z
           M 702.00,63.00
           C 702.00,53.71 702.41,43.93 710.09,37.39
             713.05,34.86 717.27,33.17 721.00,32.16
             731.54,29.31 753.71,29.91 761.79,38.19
             766.65,43.16 767.92,50.31 768.00,57.00
             768.00,57.00 768.00,116.00 768.00,116.00
             768.01,124.03 768.58,124.43 770.00,132.00
             770.00,132.00 754.00,132.00 754.00,132.00
             754.00,132.00 748.73,131.01 748.73,131.01
             748.73,131.01 745.00,124.00 745.00,124.00
             737.74,136.87 716.70,137.57 707.21,126.96
             701.55,120.63 700.15,107.18 701.44,99.00
             703.66,84.92 711.65,78.98 724.00,73.31
             730.49,70.32 741.53,67.19 744.26,60.00
             747.09,52.55 740.76,48.45 734.00,49.11
             725.89,49.90 724.16,56.07 724.00,63.00
             724.00,63.00 702.00,63.00 702.00,63.00 Z
           M 808.00,47.00
           C 811.38,36.41 818.60,32.40 829.00,31.00
             829.00,31.00 829.00,59.00 829.00,59.00
             821.82,59.00 815.51,58.63 810.65,65.09
             807.73,68.98 807.06,76.21 807.00,81.00
             807.00,81.00 807.00,132.00 807.00,132.00
             807.00,132.00 784.00,132.00 784.00,132.00
             784.00,132.00 784.00,33.00 784.00,33.00
             784.00,33.00 807.00,33.00 807.00,33.00
             807.00,33.00 808.00,47.00 808.00,47.00 Z
           M 902.00,98.00
           C 902.00,107.58 901.44,118.37 894.67,125.90
             884.31,137.45 854.78,136.82 844.18,125.90
             833.80,115.20 835.00,97.76 835.00,84.00
             835.00,84.00 835.00,63.00 835.00,63.00
             835.10,54.46 837.79,44.35 844.18,38.33
             854.50,28.61 882.36,27.89 892.91,37.33
             904.82,47.98 902.00,73.14 902.00,88.00
             902.00,88.00 858.00,88.00 858.00,88.00
             858.00,94.49 857.06,105.61 860.72,110.96
             864.61,116.62 872.01,117.80 876.28,111.89
             879.01,108.10 878.99,102.47 879.00,98.00
             879.00,98.00 902.00,98.00 902.00,98.00 Z
           M 581.00,33.00
           C 581.00,33.00 605.00,33.00 605.00,33.00
             605.00,33.00 605.00,101.00 605.00,101.00
             605.01,104.59 604.77,108.78 606.85,111.89
             610.65,117.57 616.82,116.09 620.27,110.96
             622.71,107.32 622.98,101.27 623.00,97.00
             623.00,97.00 623.00,33.00 623.00,33.00
             623.00,33.00 646.00,33.00 646.00,33.00
             646.00,33.00 646.00,132.00 646.00,132.00
             646.00,132.00 624.00,132.00 624.00,132.00
             624.00,132.00 623.00,120.00 623.00,120.00
             619.54,131.74 612.05,134.13 601.00,134.00
             593.17,133.90 586.81,131.82 583.61,124.00
             582.01,120.07 581.01,105.68 581.00,101.00
             581.00,101.00 581.00,33.00 581.00,33.00 Z
           M 460.00,69.00
           C 460.00,64.69 460.58,56.72 458.55,53.11
             455.23,47.22 444.44,47.76 440.85,53.11
             438.35,56.85 439.00,64.48 439.00,69.00
             439.00,69.00 460.00,69.00 460.00,69.00 Z
           M 879.00,69.00
           C 879.00,64.69 879.58,56.72 877.55,53.11
             874.27,47.27 863.56,47.67 860.09,53.11
             857.42,57.27 858.00,64.16 858.00,69.00
             858.00,69.00 879.00,69.00 879.00,69.00 Z
           M 745.00,81.00
           C 736.87,84.97 725.70,91.31 724.30,101.00
             723.28,108.04 725.58,115.91 734.00,115.91
             736.53,115.91 738.73,115.34 740.61,113.57
             746.39,108.11 745.00,88.87 745.00,81.00 Z
           M 330.00,790.00
           C 330.00,790.00 1802.00,790.00 1802.00,790.00
             1802.00,790.00 2054.00,790.00 2054.00,790.00
             2054.00,790.00 2181.00,790.00 2181.00,790.00
             2181.00,790.00 2190.00,789.17 2190.00,789.17
             2209.73,787.19 2231.07,783.03 2241.65,764.00
             2246.18,755.86 2247.99,745.23 2248.00,736.00
             2248.00,736.00 2248.00,264.00 2248.00,264.00
             2248.00,237.62 2250.10,190.87 2244.11,167.00
             2239.31,147.91 2228.68,131.63 2209.00,125.72
             2203.19,123.98 2193.13,123.01 2187.00,123.00
             2187.00,123.00 1216.00,123.00 1216.00,123.00
             1216.00,123.00 988.00,123.00 988.00,123.00
             988.00,123.00 939.00,123.00 939.00,123.00
             936.47,123.00 933.41,123.11 931.00,122.35
             924.11,120.19 919.59,113.14 920.34,106.00
             921.53,94.57 930.22,92.02 940.00,92.00
             940.00,92.00 1969.00,92.00 1969.00,92.00
             1969.00,92.00 2199.00,92.00 2199.00,92.00
             2242.03,92.07 2268.18,124.51 2276.20,164.00
             2277.69,171.32 2278.99,178.51 2279.00,186.00
             2279.00,186.00 2279.00,673.00 2279.00,673.00
             2279.00,673.00 2279.00,721.00 2279.00,721.00
             2279.00,721.00 2279.00,745.00 2279.00,745.00
             2278.96,768.58 2266.58,791.65 2247.00,804.64
             2239.02,809.94 2231.09,813.16 2222.00,815.98
             2213.00,818.76 2199.41,820.99 2190.00,821.00
             2190.00,821.00 1570.00,821.00 1570.00,821.00
             1570.00,821.00 330.00,821.00 330.00,821.00
             330.00,821.00 330.00,790.00 330.00,790.00 Z" />
      <path id="Selection #1"
            fill="black" stroke="black"
            d="M 433.00,505.00
           C 434.41,496.04 446.32,471.25 450.20,461.00
             450.20,461.00 470.86,410.00 470.86,410.00
             470.86,410.00 491.15,360.00 491.15,360.00
             491.15,360.00 502.42,333.00 502.42,333.00
             503.58,330.03 506.59,321.45 508.65,319.60
             510.80,317.66 514.28,318.01 517.00,318.00
             517.00,318.00 561.00,318.00 561.00,318.00
             568.49,318.14 567.99,320.26 568.00,327.00
             568.00,327.00 568.00,469.00 568.00,469.00
             568.00,469.00 568.00,501.00 568.00,501.00
             568.00,504.13 567.36,511.17 569.60,513.40
             572.22,516.02 583.21,514.88 587.00,514.87
             587.00,514.87 593.00,514.87 593.00,514.87
             593.00,514.87 593.00,579.00 593.00,579.00
             593.00,579.00 568.00,578.00 568.00,578.00
             568.00,578.00 568.00,629.00 568.00,629.00
             567.85,637.15 565.22,636.99 558.00,637.00
             558.00,637.00 526.00,637.00 526.00,637.00
             523.46,636.99 519.52,637.32 517.60,635.40
             515.68,633.48 516.01,629.54 516.00,627.00
             516.00,627.00 516.00,594.00 516.00,594.00
             516.00,590.97 516.49,582.58 514.98,580.31
             513.14,577.55 508.91,578.01 506.00,578.00
             506.00,578.00 451.00,578.00 451.00,578.00
             447.52,578.00 435.01,578.92 433.02,576.40
             431.77,574.81 432.00,570.99 432.00,569.00
             432.00,569.00 432.00,531.00 432.00,531.00
             431.98,521.37 430.79,523.90 430.06,518.00
             430.06,518.00 430.06,499.00 430.06,499.00
             430.00,493.74 429.28,488.68 432.00,484.00
             432.00,484.00 433.00,505.00 433.00,505.00 Z
           M 1065.00,516.00
           C 1059.80,521.20 1054.80,526.97 1048.00,530.10
             1038.29,534.57 1029.35,534.12 1019.00,534.00
             1000.78,533.78 985.57,524.29 976.06,509.00
             968.98,497.61 963.16,475.42 963.00,462.00
             963.00,462.00 963.00,406.00 963.00,406.00
             963.01,397.24 964.84,386.63 966.60,378.00
             969.92,361.76 976.41,345.44 989.00,334.17
             1006.85,318.18 1026.74,318.74 1049.00,319.00
             1054.10,319.06 1057.08,319.77 1062.00,320.80
             1088.27,326.33 1102.36,344.42 1109.98,369.00
             1117.54,393.42 1117.00,418.76 1117.00,444.00
             1117.00,444.00 1117.00,530.00 1117.00,530.00
             1117.00,530.00 1116.09,542.00 1116.09,542.00
             1114.35,567.30 1110.41,599.91 1091.91,618.96
             1072.81,638.64 1031.79,640.99 1007.00,632.64
             998.81,629.89 989.01,624.36 983.17,617.99
             969.10,602.61 968.00,585.55 968.00,566.00
             968.00,566.00 1017.00,566.00 1017.00,566.00
             1017.02,572.32 1017.24,577.77 1021.39,582.98
             1030.86,594.88 1049.11,591.52 1057.36,580.00
             1067.40,565.99 1065.00,533.27 1065.00,516.00 Z
           M 1547.00,321.00
           C 1554.20,318.00 1562.33,319.00 1570.00,319.00
             1581.29,319.00 1601.80,318.17 1612.00,320.00
             1613.49,328.91 1617.97,323.82 1622.46,327.58
             1622.46,327.58 1625.54,331.31 1625.54,331.31
             1625.54,331.31 1630.19,334.69 1630.19,334.69
             1630.19,334.69 1641.49,351.09 1641.49,351.09
             1642.42,353.14 1642.70,355.78 1643.00,358.00
             1643.00,358.00 1646.00,357.00 1646.00,357.00
             1646.00,357.00 1648.00,376.00 1648.00,376.00
             1648.00,376.00 1651.00,375.00 1651.00,375.00
             1651.00,375.00 1652.00,391.00 1652.00,391.00
             1652.00,391.00 1652.00,423.00 1652.00,423.00
             1652.00,423.00 1651.00,438.00 1651.00,438.00
             1645.08,441.34 1647.85,444.46 1646.66,449.95
             1645.78,453.98 1643.70,454.11 1642.58,457.13
             1641.77,459.30 1642.00,464.49 1642.00,467.00
             1640.63,467.45 1639.87,467.42 1638.74,468.59
             1637.31,470.07 1637.23,472.06 1636.46,473.83
             1634.88,477.50 1631.76,479.72 1632.00,487.00
             1627.11,490.19 1624.74,495.79 1621.67,500.63
             1617.75,506.79 1609.11,516.09 1608.00,523.00
             1602.83,525.59 1594.21,538.32 1593.00,544.00
             1588.75,546.52 1580.79,559.06 1580.00,564.00
             1580.00,564.00 1575.00,569.00 1575.00,569.00
             1575.00,569.00 1646.00,569.00 1646.00,569.00
             1657.75,569.02 1657.98,572.02 1658.00,583.00
             1658.00,583.00 1658.00,622.00 1658.00,622.00
             1657.99,625.50 1658.17,630.68 1655.91,633.57
             1653.64,636.48 1649.31,635.99 1646.00,636.00
             1646.00,636.00 1560.00,636.00 1560.00,636.00
             1547.98,636.00 1522.49,636.88 1512.00,635.00
             1512.00,635.00 1512.00,574.00 1512.00,574.00
             1512.00,574.00 1515.00,575.00 1515.00,575.00
             1515.00,575.00 1517.00,556.00 1517.00,556.00
             1517.00,556.00 1520.00,557.00 1520.00,557.00
             1520.00,557.00 1521.58,546.09 1521.58,546.09
             1521.58,546.09 1524.85,540.83 1524.85,540.83
             1524.85,540.83 1526.85,533.17 1526.85,533.17
             1526.85,533.17 1531.00,523.00 1531.00,523.00
             1535.07,520.76 1535.26,519.44 1536.00,515.00
             1536.00,515.00 1540.00,515.00 1540.00,515.00
             1540.00,515.00 1540.00,509.00 1540.00,509.00
             1545.44,505.61 1547.46,500.54 1550.92,495.45
             1550.92,495.45 1558.76,484.76 1558.76,484.76
             1558.76,484.76 1574.83,460.59 1574.83,460.59
             1579.11,454.47 1587.16,445.28 1588.00,438.00
             1596.02,433.47 1588.14,427.84 1596.00,425.00
             1596.00,425.00 1596.92,412.00 1596.92,412.00
             1596.92,412.00 1596.00,402.00 1596.00,402.00
             1596.00,402.00 1593.00,403.00 1593.00,403.00
             1593.00,403.00 1592.00,393.00 1592.00,393.00
             1582.50,392.23 1581.13,391.43 1571.00,391.95
             1564.78,392.27 1561.18,390.82 1559.00,398.00
             1549.77,399.98 1552.16,401.94 1548.49,404.88
             1544.85,407.79 1543.14,405.84 1543.00,412.00
             1543.00,412.00 1535.00,415.00 1535.00,415.00
             1535.00,415.00 1536.00,419.00 1536.00,419.00
             1534.00,418.99 1530.33,419.26 1528.72,418.01
             1526.59,416.34 1526.44,411.68 1525.31,409.04
             1525.31,409.04 1517.29,394.83 1517.29,394.83
             1515.08,390.39 1517.11,388.88 1512.00,386.00
             1511.87,375.63 1509.06,379.36 1507.22,373.89
             1506.85,371.93 1506.97,365.36 1507.22,363.00
             1507.03,361.20 1507.00,358.90 1507.74,357.24
             1507.74,357.24 1519.94,341.72 1519.94,341.72
             1523.27,338.24 1526.88,341.07 1527.00,334.00
             1533.46,332.40 1535.77,333.69 1536.00,327.00
             1541.95,325.55 1545.51,326.97 1547.00,321.00 Z
           M 257.00,412.00
           C 257.00,390.24 255.94,380.25 263.69,359.00
             265.51,354.02 268.16,349.33 271.21,345.00
             282.82,328.52 303.21,320.03 323.00,320.00
             323.00,320.00 340.00,320.00 340.00,320.00
             354.77,320.18 371.60,326.08 383.00,335.46
             398.14,347.93 405.97,371.81 406.00,391.00
             406.00,391.00 406.00,407.00 406.00,407.00
             405.99,413.15 405.29,418.00 403.99,424.00
             401.90,433.65 397.84,444.32 391.53,452.00
             385.01,459.94 380.39,460.64 372.00,465.00
             385.64,466.24 396.10,476.32 402.22,488.00
             412.08,506.83 412.24,528.31 412.00,549.00
             412.00,549.00 411.17,558.00 411.17,558.00
             408.73,582.33 402.59,607.51 382.00,623.10
             365.91,635.28 346.65,638.23 327.00,638.00
             314.93,637.86 298.48,634.62 288.00,628.54
             273.05,619.86 263.17,606.56 258.43,590.00
             254.96,577.91 254.74,571.16 253.91,559.00
             253.91,559.00 253.08,551.00 253.08,551.00
             253.08,551.00 253.96,543.00 253.96,543.00
             253.96,543.00 253.96,528.00 253.96,528.00
             253.96,528.00 303.00,528.00 303.00,528.00
             303.00,543.50 302.28,570.69 312.53,582.93
             321.62,593.74 341.49,594.05 350.47,582.93
             356.68,575.29 358.88,560.70 359.00,551.00
             359.17,536.34 361.47,508.57 349.96,498.21
             342.62,491.61 329.64,489.12 320.00,489.00
             317.30,488.97 309.52,489.50 307.60,487.98
             305.62,486.41 306.00,482.30 306.00,480.00
             306.00,480.00 306.00,451.00 306.00,451.00
             306.00,448.70 305.62,444.59 307.60,443.02
             309.51,441.51 316.32,442.09 319.00,441.91
             325.38,441.48 333.37,440.42 339.00,437.35
             353.41,429.50 355.17,412.66 355.00,398.00
             354.91,390.51 353.36,380.98 348.61,375.00
             339.38,363.37 322.06,362.86 312.33,374.04
             304.15,383.44 306.00,400.25 306.00,412.00
             306.00,412.00 257.00,412.00 257.00,412.00 Z
           M 1342.00,466.00
           C 1334.28,459.46 1329.64,457.68 1323.29,449.00
             1311.04,432.28 1310.77,408.88 1311.00,389.00
             1311.28,364.99 1324.25,340.41 1346.00,329.37
             1358.54,323.01 1368.38,322.24 1382.00,321.74
             1397.23,321.19 1415.05,323.49 1428.00,332.10
             1455.47,350.39 1454.33,386.05 1454.00,415.00
             1453.89,424.35 1450.93,437.00 1446.07,445.00
             1439.26,456.22 1430.85,460.75 1420.00,467.00
             1458.85,473.09 1461.36,520.11 1461.00,551.00
             1461.00,551.00 1460.09,561.00 1460.09,561.00
             1458.11,589.33 1450.15,619.51 1422.00,632.68
             1408.66,638.92 1393.51,640.17 1379.00,640.00
             1372.31,639.92 1359.48,638.05 1353.00,636.26
             1347.02,634.60 1341.27,632.21 1336.00,628.94
             1302.96,608.38 1304.61,564.04 1305.00,530.00
             1305.32,503.26 1316.29,477.14 1342.00,466.00 Z
           M 2062.00,322.00
           C 2062.00,322.00 2219.87,322.00 2219.87,322.00
             2219.87,322.00 2219.87,357.00 2219.87,357.00
             2220.00,359.19 2220.07,361.88 2219.87,364.00
             2219.87,364.00 2208.30,389.00 2208.30,389.00
             2208.30,389.00 2188.51,440.00 2188.51,440.00
             2176.87,474.95 2167.12,513.74 2160.58,550.00
             2155.94,575.69 2152.00,601.87 2152.00,628.00
             2152.00,628.00 2097.00,628.00 2097.00,628.00
             2098.96,585.26 2108.61,541.98 2120.58,501.00
             2128.84,472.72 2137.77,444.26 2149.01,417.00
             2149.01,417.00 2161.26,389.00 2161.26,389.00
             2161.26,389.00 2172.00,368.00 2172.00,368.00
             2172.00,368.00 2062.00,368.00 2062.00,368.00
             2062.00,368.00 2062.00,322.00 2062.00,322.00 Z
           M 637.00,326.00
           C 637.00,326.00 772.00,326.00 772.00,326.00
             772.00,326.00 772.00,372.00 772.00,372.00
             772.00,372.00 678.00,372.00 678.00,372.00
             678.00,372.00 676.00,416.00 676.00,416.00
             676.00,416.00 675.00,436.00 675.00,436.00
             680.17,430.85 683.24,427.49 690.00,424.32
             699.73,419.77 708.58,419.88 719.00,420.00
             739.84,420.25 759.46,430.53 769.69,449.00
             785.93,478.33 784.38,514.50 784.00,547.00
             783.74,569.00 776.94,601.45 761.83,617.99
             746.79,634.44 725.15,638.03 704.00,638.00
             685.79,637.97 663.66,633.79 650.01,620.91
             631.23,603.18 628.04,572.39 628.00,548.00
             628.00,548.00 627.00,533.00 627.00,533.00
             627.00,533.00 677.00,533.00 677.00,533.00
             677.00,546.47 676.65,569.85 684.64,581.00
             694.63,594.95 714.98,594.98 724.07,580.00
             731.07,568.47 730.94,551.12 731.00,538.00
             731.00,538.00 732.00,522.00 732.00,522.00
             732.02,509.40 730.87,488.60 723.89,478.00
             710.10,457.04 682.30,466.43 677.00,489.00
             677.00,489.00 630.00,489.00 630.00,489.00
             630.00,489.00 631.00,469.00 631.00,469.00
             631.00,469.00 632.00,438.00 632.00,438.00
             632.00,438.00 632.96,428.00 632.96,428.00
             632.96,428.00 632.96,417.00 632.96,417.00
             632.96,417.00 634.00,400.00 634.00,400.00
             634.00,400.00 636.00,347.00 636.00,347.00
             636.00,347.00 637.00,326.00 637.00,326.00 Z
           M 1724.00,326.00
           C 1724.00,326.00 1859.00,326.00 1859.00,326.00
             1859.00,326.00 1859.00,372.00 1859.00,372.00
             1859.00,372.00 1765.00,372.00 1765.00,372.00
             1765.00,372.00 1764.00,390.00 1764.00,390.00
             1764.00,390.00 1763.00,407.00 1763.00,407.00
             1763.00,407.00 1762.00,435.00 1762.00,435.00
             1767.87,429.92 1772.63,425.86 1780.00,423.06
             1788.79,419.73 1796.81,419.90 1806.00,420.00
             1829.16,420.27 1848.35,432.28 1858.73,453.00
             1864.64,464.79 1867.33,478.03 1869.13,491.00
             1871.22,506.03 1871.00,514.14 1871.00,529.00
             1871.00,559.20 1869.15,595.42 1847.71,618.96
             1835.30,632.59 1813.86,637.97 1796.00,638.00
             1772.55,638.04 1747.06,634.76 1731.52,615.00
             1727.05,609.33 1724.51,603.71 1722.05,597.00
             1715.34,578.68 1714.00,552.54 1714.00,533.00
             1714.00,533.00 1764.00,533.00 1764.00,533.00
             1764.00,546.86 1763.29,568.08 1770.97,580.00
             1780.56,594.89 1801.49,595.30 1811.01,580.00
             1818.54,567.89 1817.98,545.03 1818.00,531.00
             1818.00,531.00 1818.92,518.00 1818.92,518.00
             1818.92,518.00 1818.09,510.00 1818.09,510.00
             1817.29,498.28 1816.72,484.51 1808.66,475.04
             1794.32,458.17 1767.29,468.11 1764.00,489.00
             1764.00,489.00 1717.00,489.00 1717.00,489.00
             1717.00,489.00 1719.00,436.00 1719.00,436.00
             1719.00,436.00 1720.00,413.00 1720.00,413.00
             1720.00,413.00 1721.04,398.00 1721.04,398.00
             1721.04,398.00 1721.04,385.00 1721.04,385.00
             1721.04,385.00 1722.00,373.00 1722.00,373.00
             1722.00,373.00 1723.04,350.00 1723.04,350.00
             1723.04,350.00 1723.04,338.00 1723.04,338.00
             1723.04,338.00 1724.00,326.00 1724.00,326.00 Z
           M 1895.00,326.00
           C 1895.00,326.00 2030.00,326.00 2030.00,326.00
             2030.00,326.00 2030.00,372.00 2030.00,372.00
             2030.00,372.00 1936.00,372.00 1936.00,372.00
             1936.00,372.00 1935.00,390.00 1935.00,390.00
             1935.00,390.00 1934.00,407.00 1934.00,407.00
             1934.00,407.00 1933.00,435.00 1933.00,435.00
             1938.87,429.92 1943.63,425.86 1951.00,423.06
             1959.79,419.73 1967.81,419.90 1977.00,420.00
             2000.16,420.27 2019.35,432.28 2029.73,453.00
             2035.64,464.79 2038.33,478.03 2040.13,491.00
             2042.22,506.03 2042.00,514.14 2042.00,529.00
             2042.00,559.20 2040.15,595.42 2018.71,618.96
             2006.30,632.59 1984.86,637.97 1967.00,638.00
             1943.55,638.04 1918.06,634.76 1902.52,615.00
             1898.05,609.33 1895.51,603.71 1893.05,597.00
             1886.34,578.68 1885.00,552.54 1885.00,533.00
             1885.00,533.00 1935.00,533.00 1935.00,533.00
             1935.00,546.86 1934.29,568.08 1941.97,580.00
             1951.56,594.89 1972.49,595.30 1982.01,580.00
             1989.54,567.89 1988.98,545.03 1989.00,531.00
             1989.00,531.00 1989.92,518.00 1989.92,518.00
             1989.92,518.00 1989.09,510.00 1989.09,510.00
             1988.29,498.28 1987.72,484.51 1979.66,475.04
             1965.32,458.17 1938.29,468.11 1935.00,489.00
             1935.00,489.00 1888.00,489.00 1888.00,489.00
             1888.00,489.00 1890.00,436.00 1890.00,436.00
             1890.00,436.00 1891.00,413.00 1891.00,413.00
             1891.00,413.00 1892.04,398.00 1892.04,398.00
             1892.04,398.00 1892.04,385.00 1892.04,385.00
             1892.04,385.00 1893.00,373.00 1893.00,373.00
             1893.00,373.00 1894.04,350.00 1894.04,350.00
             1894.04,350.00 1894.04,338.00 1894.04,338.00
             1894.04,338.00 1895.00,326.00 1895.00,326.00 Z
           M 1144.00,328.00
           C 1144.00,328.00 1301.87,328.00 1301.87,328.00
             1301.87,328.00 1301.87,363.00 1301.87,363.00
             1302.00,365.19 1302.07,367.88 1301.87,370.00
             1301.87,370.00 1290.30,395.00 1290.30,395.00
             1290.30,395.00 1270.51,446.00 1270.51,446.00
             1258.87,480.95 1249.12,519.74 1242.58,556.00
             1237.94,581.69 1234.00,607.87 1234.00,634.00
             1234.00,634.00 1179.00,634.00 1179.00,634.00
             1180.96,591.26 1190.61,547.98 1202.58,507.00
             1210.84,478.72 1219.77,450.26 1231.01,423.00
             1231.01,423.00 1243.26,395.00 1243.26,395.00
             1243.26,395.00 1254.00,374.00 1254.00,374.00
             1254.00,374.00 1144.00,374.00 1144.00,374.00
             1144.00,374.00 1144.00,328.00 1144.00,328.00 Z
           M 1034.00,365.30
           C 1015.74,368.92 1015.02,386.77 1015.00,402.00
             1015.00,402.00 1014.04,418.00 1014.04,418.00
             1014.04,418.00 1014.83,426.00 1014.83,426.00
             1014.83,426.00 1014.83,440.00 1014.83,440.00
             1014.46,447.81 1014.87,460.51 1016.65,468.00
             1019.86,481.50 1028.47,489.86 1043.00,487.70
             1060.70,485.07 1063.98,463.72 1064.00,449.00
             1064.00,449.00 1064.00,432.00 1064.00,432.00
             1064.00,417.24 1065.06,399.18 1061.99,385.00
             1060.30,377.24 1056.96,369.26 1049.00,366.15
             1045.01,364.58 1038.27,364.87 1034.00,365.30 Z
           M 1379.00,368.33
           C 1373.18,369.72 1369.29,371.91 1365.85,377.00
             1358.40,388.04 1358.71,416.47 1362.44,429.00
             1365.70,439.94 1373.99,447.64 1386.00,445.68
             1397.02,443.87 1401.85,435.07 1403.74,425.00
             1405.43,415.99 1405.04,407.12 1405.00,398.00
             1404.92,381.26 1398.27,366.20 1379.00,368.33 Z
           M 1579.00,389.00
           C 1576.66,386.98 1576.05,386.35 1574.00,389.00
             1574.00,389.00 1579.00,389.00 1579.00,389.00 Z
           M 516.00,404.00
           C 516.00,404.00 509.60,417.00 509.60,417.00
             509.60,417.00 500.55,439.00 500.55,439.00
             500.55,439.00 479.55,490.00 479.55,490.00
             479.55,490.00 470.00,515.00 470.00,515.00
             470.00,515.00 516.00,515.00 516.00,515.00
             516.00,515.00 516.00,404.00 516.00,404.00 Z
           M 817.00,478.00
           C 817.00,478.00 906.00,478.00 906.00,478.00
             906.00,478.00 906.00,526.00 906.00,526.00
             906.00,526.00 817.00,526.00 817.00,526.00
             817.00,526.00 817.00,478.00 817.00,478.00 Z
           M 1380.00,488.29
           C 1372.21,489.58 1366.49,491.56 1362.41,499.00
             1359.67,504.02 1358.07,513.27 1358.00,519.00
             1358.00,519.00 1357.00,538.00 1357.00,538.00
             1357.00,538.00 1357.91,560.00 1357.91,560.00
             1358.87,573.99 1359.56,589.61 1377.00,592.75
             1379.30,593.08 1383.66,593.08 1386.00,592.75
             1398.06,591.52 1403.49,582.91 1406.10,572.00
             1407.12,567.75 1407.99,557.51 1408.00,553.00
             1408.00,553.00 1408.00,541.00 1408.00,541.00
             1408.00,521.14 1408.96,485.81 1380.00,488.29 Z" />
    </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgNumeroMobile svgRef={ref} {...props} />);
export default ForwardRef;
