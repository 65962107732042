import React from 'react';

const SvgActions29 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.56 6.91a.48.48 0 0 0-.7 0L10 8.76 8.14 6.91a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7L8.76 10l-1.85 1.86a.48.48 0 0 0 0 .7l.53.53a.48.48 0 0 0 .7 0L10 11.24l1.86 1.85a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7L11.24 10l1.85-1.86a.48.48 0 0 0 0-.7zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions29 svgRef={ref} {...props} />);
export default ForwardRef;
