import React from 'react';

const SvgAbstract71 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 15" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M11.002 9.998c-4.72 0-9.5 2-10 4.59a.5.5 0 0 1-.49.41h-.1a.46.46 0 0 1-.32-.13.5.5 0 0 1-.09-.33 11.22 11.22 0 0 1 11-10.54v-2.58a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .71 0l5.79 5.79a.48.48 0 0 1 0 .7l-5.79 5.79a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.15-.36z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract71 svgRef={ref} {...props} />);
export default ForwardRef;
