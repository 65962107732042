import React from 'react';

const SvgActions39 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 17 13" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.848 2.254l-10.6 10.6a.5.5 0 0 1-.71 0l-5.39-5.39a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0l4.33 4.33 9.55-9.55a.51.51 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions39 svgRef={ref} {...props} />);
export default ForwardRef;
