import React from 'react';

const SvgAlerts11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18.001 2a.38.38 0 0 0-.27-.16.44.44 0 0 0-.31.09l-1 .78a.36.36 0 0 0-.06.5 8.12 8.12 0 0 1 1.64 4.31.5.5 0 0 0 .49.48h1a.5.5 0 0 0 .36-.16.55.55 0 0 0 .15-.37 10.13 10.13 0 0 0-2-5.47zm-14.47.71l-1-.78a.4.4 0 0 0-.3-.09.37.37 0 0 0-.23.16 10.05 10.05 0 0 0-2 5.47.47.47 0 0 0 .14.37.48.48 0 0 0 .36.16h1a.51.51 0 0 0 .5-.48 8.12 8.12 0 0 1 1.59-4.31.36.36 0 0 0-.06-.5zM10.001 20a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2zm4-6h-8V8a4 4 0 0 1 .8-2.4l.9-1.2a1 1 0 0 1 .8-.4h3a1 1 0 0 1 .8.4l.9 1.2a4 4 0 0 1 .8 2.4zm2.88-1.12a3 3 0 0 1-.88-2.12V8a6 6 0 0 0-1.2-3.6l-.9-1.2a3 3 0 0 0-2.4-1.2h-.5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2h-.5a3 3 0 0 0-2.4 1.2l-.9 1.2a6 6 0 0 0-1.2 3.6v2.76a3 3 0 0 1-.88 2.12l-.83.83a1 1 0 0 0-.29.7V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-.59a1 1 0 0 0-.29-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts11 svgRef={ref} {...props} />);
export default ForwardRef;
