import React from 'react';

const SvgArrows23 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 8 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.493 20h-1a.5.5 0 0 1-.5-.5V3.995h-2a.5.5 0 0 1-.35-.85l3-3.001a.51.51 0 0 1 .71 0l3 3a.5.5 0 0 1-.36.851h-2V19.5a.5.5 0 0 1-.5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows23 svgRef={ref} {...props} />);
export default ForwardRef;
