import React, { Component, Fragment } from 'react';
import K from '../../../constants';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { isEqual } from 'lodash';

import './GoogleMaps.css';

class GoogleMapsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleInfoWindow: false
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    let shouldIUpdateDueToProps = !isEqual(this.props.markers, nextProps.markers);
    let shouldIUpdateDueToState = !isEqual(this.state.activeMarker, nextState.activeMarker);
    return shouldIUpdateDueToProps === true || shouldIUpdateDueToState === true;
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      activeMarker: marker,
      visibleInfoWindow: true
    });
  };

  render() {
    if (!this.props.loaded) {
      return <div>Loading...</div>;
    }
    const { visibleInfoWindow, activeMarker } = this.state;
    const markers = this.props.markers.map((marker, index) => {
      const { lat, lng } = marker;
      return <Marker key={index} position={{ lat, lng }} markerData={marker} onClick={this.onMarkerClick} />;
    });
    const { markerData = {} } = activeMarker || {};
    return (
      <Map {...this.props} className={this.props.className} google={this.props.google}>
        {markers}
        <InfoWindow className="InstallersInfoWindow" marker={activeMarker} visible={visibleInfoWindow}>
          <Fragment>
            <div className="InstallersInfoWindowTitle">{markerData.businessName}</div>
            <div className="InstallersInfoWindowDescription">
              {markerData.address}
              <br />
              {`${markerData.postCode} ${markerData.admdiv3} ${markerData.admdiv2}`}
            </div>
          </Fragment>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: K.GOOGLE_API_KEY
})(GoogleMapsContainer);
