import React from 'react';

const SvgOther23 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 4.5v9a.5.5 0 0 1-.5.5H10a2 2 0 0 1-2-2H2v7.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V.5A.5.5 0 0 1 .5 0h1a.5.5 0 0 1 .5.5V2h6a2 2 0 0 1 2 2h5.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther23 svgRef={ref} {...props} />);
export default ForwardRef;
