import React from 'react';

const SvgFiles24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-.34a.46.46 0 0 1 .11-.3L6 8.19A.52.52 0 0 1 6.35 8a.5.5 0 0 1 .39.18l1.45 1.75a.49.49 0 0 0 .38.18.51.51 0 0 0 .4-.2l2.88-3.72a.51.51 0 0 1 .8 0l4.25 5.67a.51.51 0 0 1 .1.3zm-8-8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM18 2h-5.59a1 1 0 0 1-.7-.29L10.29.29a1 1 0 0 0-.7-.29H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles24 svgRef={ref} {...props} />);
export default ForwardRef;
