import React from 'react';

const SvgPremium10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.59.65l4.15 4.51a1 1 0 0 1 .26.68v.3a1 1 0 0 1-.23.64l-9 10.86a1 1 0 0 1-1.54 0l-9-10.86A1 1 0 0 1 0 6.14v-.3a1 1 0 0 1 .26-.68L4.41.65A2 2 0 0 1 5.88 0h8.24a2 2 0 0 1 1.47.65zM14.12 2H5.88L2.19 6 10 15.46 17.81 6z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium10 svgRef={ref} {...props} />);
export default ForwardRef;
