import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { withBreakpoints } from 'react-breakpoints';
import classNames from 'classnames';
import MoVeInWelcome from './MoVeInWelcome';
import MyPageReg from './MyPageReg';
import { message } from 'antd';

import './MoVeIn.css';



@withRouter
@inject('moveinStore', 'translationsStore')
@observer
class MoVeIn extends Component {

  configMessage = () => {
    message.config({
      top: 100,
      duration: 3,
      maxCount: 3,
    });
  };

  componentDidUpdate() {
    const { moveinStore, translationsStore } = this.props; 
    this.configMessage();

    if (moveinStore.exceptionFound) {
      message.error(translationsStore.translate('common.genericError'), function(){
         moveinStore.setExceptionFound(false);
      });
    }
  }

  render() {
    const { breakpoints, currentBreakpoint, history } = this.props;
    const {
      moveinStore: { exceptionFound }
    } = this.props;

    const cls = classNames({
      MoVeIn: true,
      [currentBreakpoint]: true
    });
    return (
      <div className={cls}>
        <Switch>
          <Redirect from="/movein" exact to="/movein/idadesione" />
          <Route path="/movein/idadesione" render={() => <MoVeInWelcome />} />
          <Route path="/movein/registrazione" render={() => <MyPageReg />} />
        </Switch>
      </div>
    );
  }
}

export default withBreakpoints(MoVeIn);
