import React from 'react';

const SvgAlerts16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.772 8.79a.25.25 0 0 1-.25.22H8.468a.25.25 0 0 1-.249-.22L7.82 5.57a.501.501 0 0 1 .498-.56h1.354a.497.497 0 0 1 .498.56zM10 12.51a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm8-6a3 3 0 0 0-.88-2.12L13.61.88A3 3 0 0 0 11.49 0h-5a3 3 0 0 0-2.12.88L.88 4.39A3 3 0 0 0 0 6.51v5a3 3 0 0 0 .88 2.12l3.51 3.51a3 3 0 0 0 2.12.86h5a3 3 0 0 0 2.12-.88l3.51-3.51a3 3 0 0 0 .86-2.12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts16 svgRef={ref} {...props} />);
export default ForwardRef;
