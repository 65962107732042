import React from 'react';

const SvgActions71 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 2.83l-1.83 1.83A7.92 7.92 0 0 1 8 6.86v4.28a7.92 7.92 0 0 1 4.17 2.2L14 15.17zM16 1v16a1 1 0 0 1-1 1h-.59a1 1 0 0 1-.7-.29l-3-2.95A6 6 0 0 0 6.51 13H3a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h3.51a6 6 0 0 0 4.25-1.76l3-2.95a1 1 0 0 1 .7-.29H15a1 1 0 0 1 1 1zm3.2 10.39a.51.51 0 0 1-.75 0l-.72-.718a.498.498 0 0 1-.06-.63 1.99 1.99 0 0 0 0-2.195.498.498 0 0 1 .06-.629l.72-.718a.51.51 0 0 1 .39-.15.54.54 0 0 1 .36.2c.53.704.81 1.564.8 2.445 0 .864-.28 1.704-.8 2.395zM10 18.92v.58a.5.5 0 0 1-.5.5H7.57a1 1 0 0 1-.86-.49l-2.28-3.8a2.72 2.72 0 0 1-.36-1h2.44a4.14 4.14 0 0 1 1.57.3l.42 2.75a1 1 0 0 0 .75.57l.33.06a.5.5 0 0 1 .42.53z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions71 svgRef={ref} {...props} />);
export default ForwardRef;
