import React from 'react';

const SvgCommunication2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 9" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M22 5.314v2.913a.748.748 0 0 1-.94.719l-4.32-1.128a.998.998 0 0 1-.74-.997V3.668a.489.489 0 0 0-.36-.478l-.71-.2a13.863 13.863 0 0 0-7.86 0l-.71.22a.489.489 0 0 0-.36.478v3.173a.998.998 0 0 1-.74.997L.94 8.975A.751.751 0 0 1 0 8.227V5.324c0-.531.212-1.041.59-1.416l.5-.499A10.065 10.065 0 0 1 5.28.905l.5-.15a18.443 18.443 0 0 1 10.45 0l.49.15a10.065 10.065 0 0 1 4.19 2.504l.5.499c.376.372.588.878.59 1.406z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication2 svgRef={ref} {...props} />);
export default ForwardRef;
