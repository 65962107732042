import React, { Component } from 'react';
import classNames from 'classnames';
import { Alert } from 'antd';

import './MyPageAlert.css';

export default class MyPageAlert extends Component {
  render() {
    const cls = classNames({
      MyPageAlert: true,
      [this.props.className]: !!this.props.className
    });
    return <Alert className={cls} {...this.props} />;
  }
}
