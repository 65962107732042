import React from 'react';

const SvgTransport2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 17" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.809 13c-.524 0-1-.266-1.276-.711a1.488 1.488 0 0 1-.066-1.459l.896-1.793C5.243 9.022 5.124 9 5 9c-1.654 0-3 1.346-3 3s1.346 3 3 3c.883 0 1.67-.391 2.22-1h2.358A4.998 4.998 0 0 1 0 12a5 5 0 0 1 5-5c.448 0 .874.078 1.288.188l.607-1.213c.069-.138.105-.293.105-.447V5.25C7 4.561 7.561 4 8.25 4h7.029l-.118-.354a1.256 1.256 0 0 1 .172-1.128A1.254 1.254 0 0 1 16.347 2H19a3.003 3.003 0 0 1 2.939 2.399.497.497 0 0 1-.489.601h-1.036a.5.5 0 0 1-.471-.333A1.001 1.001 0 0 0 19 4h-1.613l1.02 3.06c.197-.024.39-.06.593-.06a5 5 0 1 1 0 10 5 5 0 0 1-5-5c0-1.85 1.016-3.446 2.509-4.312L15.946 6h-.828l-2.947 5.895A1.988 1.988 0 0 1 10.382 13zm2.006-2a2.98 2.98 0 0 0-.659-1.077L6.617 11zm5.067-5h-3.92a3.022 3.022 0 0 1-.278.869l-.609 1.216c.914.719 1.584 1.733 1.824 2.915h.483zM19 15c1.654 0 3-1.346 3-3a3 3 0 0 0-2.944-2.995l.95 2.85a.35.35 0 0 1-.332.461h-1.082a.749.749 0 0 1-.711-.512l-.719-2.158A2.987 2.987 0 0 0 16 12c0 1.654 1.346 3 3 3zM5.5 2a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport2 svgRef={ref} {...props} />);
export default ForwardRef;
