import React from 'react';

const SvgAbstract63 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M4.83 11.29l-.79 2a.5.5 0 0 0 .65.65l2-.79a2 2 0 0 0 .68-.44L14 6.15a.5.5 0 0 0 0-.7L12.55 4a.5.5 0 0 0-.7 0l-6.58 6.61a2 2 0 0 0-.44.68zM17.71.71l-.42-.42a1 1 0 0 0-.7-.29h-.3a1 1 0 0 0-.7.29l-1.8 1.8a.5.5 0 0 0 0 .7l1.42 1.42a.5.5 0 0 0 .7 0l1.8-1.8a1 1 0 0 0 .29-.7v-.3a1 1 0 0 0-.29-.7zM17.5 8h-1a.5.5 0 0 0-.5.5V16H2V2h7.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8.5a.5.5 0 0 0-.5-.5z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract63 svgRef={ref} {...props} />);
export default ForwardRef;
