import React from 'react';

const SvgDevices46 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 2h13a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7v-3H2zm16 14h-5V6h5zm0-12h-5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices46 svgRef={ref} {...props} />);
export default ForwardRef;
