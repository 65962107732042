import React from 'react';

const SvgPayment24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.546 9.975a.74.74 0 0 0-.738.632l-.758 4.751a.74.74 0 0 1-.738.632H.507a.488.488 0 0 1-.499-.582L2.352.842A.999.999 0 0 1 3.35 0h6.982a3.483 3.483 0 0 1 2.636 1.213c.662.77.96 1.79.815 2.797l-.14.892c-.461 2.923-2.97 5.075-5.914 5.073zm11.28-1.063c-.463 2.939-2.994 5.095-5.954 5.073H8.735a.75.75 0 0 0-.738.632l-.748 4.751a.76.76 0 0 1-.748.632H4.696a.488.488 0 0 1-.489-.582l1.127-7.177a.748.748 0 0 1 .698-.512h1.696c3.694-.002 6.87-2.632 7.58-6.275a3.47 3.47 0 0 1 .658 2.566z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment24 svgRef={ref} {...props} />);
export default ForwardRef;
