import React from 'react';

const SvgAbstract45 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.71 7.71L12.29.29a1 1 0 0 0-.7-.29H8.41a1 1 0 0 0-.7.29L.29 7.71a1 1 0 0 0-.29.7V9.5a.5.5 0 0 0 .5.5H2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V17a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h1.5a.5.5 0 0 0 .5-.5V8.41a1 1 0 0 0-.29-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract45 svgRef={ref} {...props} />);
export default ForwardRef;
