import React from 'react';

const SvgFiles29 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 6v9a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h12a4 4 0 0 0 4-4V8a2 2 0 0 0-2-2zm-4 9a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-4.59a1 1 0 0 1-.7-.29L9.29.29a1 1 0 0 0-.7-.29H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles29 svgRef={ref} {...props} />);
export default ForwardRef;
