import React from 'react';

const SvgArrows41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.828 1.922a.5.5 0 0 1 0-.71l1.06-1.06a.48.48 0 0 1 .7 0l9.19 9.19c.14.14.22.33.22.53v.26a.75.75 0 0 1-.22.53l-9.19 9.19a.48.48 0 0 1-.7 0l-1.06-1.06a.5.5 0 0 1 0-.71l8.08-8.08z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows41 svgRef={ref} {...props} />);
export default ForwardRef;
