import React from 'react';

const SvgUser4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 13" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.93 9.65A2.993 2.993 0 0 0 13.254 8h-.998c.183.238.343.493.479.76l.948 1.89a3.01 3.01 0 0 1 .13 2.35h2.175a.997.997 0 0 0 .888-1.45zM10.994 6a2.99 2.99 0 0 0 2.734-1.77 3.006 3.006 0 0 0-.494-3.225A2.987 2.987 0 0 0 10.097.14a5.01 5.01 0 0 1 0 5.72c.29.092.593.14.898.14zm12.502-1h-2.5V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V5h-2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2.5v2.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V7h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-17.5 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm4.94 3.65A3 3 0 0 0 8.257 8h-4.52a3 3 0 0 0-2.63 1.65l-1 1.9A1 1 0 0 0 .997 13h10a1 1 0 0 0 .89-1.45z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser4 svgRef={ref} {...props} />);
export default ForwardRef;
