import React from 'react';

const SvgArrows21 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 8" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 3.503v1a.5.5 0 0 1-.5.5H3.995v2a.5.5 0 0 1-.85.35l-3.001-3a.51.51 0 0 1 0-.71l3-3a.5.5 0 0 1 .851.36v2H19.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows21 svgRef={ref} {...props} />);
export default ForwardRef;
