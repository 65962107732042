const convertToUser = (utente) => {
    const user = {};
    user.last_name = utente.cognome;
    user.first_name = utente.nome;
    user.tax_code = utente.codFiscale;
    return user;
  };

  const convertToCompany = (societa) => {
    const company = {};
    company.name = societa.ragSociale;
    company.phone = societa.telefono;
    company.email = societa.email;
    company.tax_code = societa.partitaIva;
    const registered_office = {};
    registered_office.street = societa.sedeLegale.indirizzo;
    registered_office.building_number = societa.sedeLegale.numCivico;
    registered_office.city = societa.sedeLegale.comune;
    registered_office.postcode = societa.sedeLegale.cap;
    registered_office.province = societa.sedeLegale.provincia;
    registered_office.country = 'IT';
    company.registered_office = registered_office;
    return company;
  };

  const convertToVehicle = (veicolo) => {
    const vehicle = {};
    vehicle.vin = veicolo.telaio;
    vehicle.license_plate = veicolo.targa;
    vehicle.fuel_type = veicolo.tipoVeicolo.alimentazione;
    vehicle.category = veicolo.tipoVeicolo.categoria;
    vehicle.environmental_class = veicolo.tipoVeicolo.classeAmbientale;
    return vehicle;
  };

  export default {
    convertToUser: convertToUser,
    convertToCompany: convertToCompany,
    convertToVehicle: convertToVehicle
  }
