import React from 'react';

const SvgAbstract37 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 10.83a2 2 0 0 0-.59-1.42L.59 3.59A2 2 0 0 1 0 2.17V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v1.17a2 2 0 0 1-.59 1.42l-5.82 5.82a2 2 0 0 0-.59 1.42v3.67a2 2 0 0 1-.8 1.6l-2.4 1.8a.5.5 0 0 1-.8-.4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract37 svgRef={ref} {...props} />);
export default ForwardRef;
