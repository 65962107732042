import React from 'react';

const SvgPayment33 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 10.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM2 16V6h14v2H9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h7v2zM16 4H3a1 1 0 0 1 0-2h13a2 2 0 0 0-2-2H3a3 3 0 0 0-3 3v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment33 svgRef={ref} {...props} />);
export default ForwardRef;
