const areas = [
  { key: '001', code: 'TO', value: 'Torino', tipoUTS: 'Citta metropolitana' },
  { key: '002', code: 'VC', value: 'Vercelli', tipoUTS: 'Provincia' },
  { key: '003', code: 'NO', value: 'Novara', tipoUTS: 'Provincia' },
  { key: '004', code: 'CN', value: 'Cuneo', tipoUTS: 'Provincia' },
  { key: '005', code: 'AT', value: 'Asti', tipoUTS: 'Provincia' },
  { key: '006', code: 'AL', value: 'Alessandria', tipoUTS: 'Provincia' },
  { key: '096', code: 'BI', value: 'Biella', tipoUTS: 'Provincia' },
  { key: '103', code: 'VB', value: 'Verbano-Cusio-Ossola', tipoUTS: 'Provincia' },
  { key: '007', code: 'AO', value: "Valle d'Aosta/Vallée d'Aoste", tipoUTS: 'Provincia' },
  { key: '012', code: 'VA', value: 'Varese', tipoUTS: 'Provincia' },
  { key: '013', code: 'CO', value: 'Como', tipoUTS: 'Provincia' },
  { key: '014', code: 'SO', value: 'Sondrio', tipoUTS: 'Provincia' },
  { key: '015', code: 'MI', value: 'Milano', tipoUTS: 'Citta metropolitana' },
  { key: '016', code: 'BG', value: 'Bergamo', tipoUTS: 'Provincia' },
  { key: '017', code: 'BS', value: 'Brescia', tipoUTS: 'Provincia' },
  { key: '018', code: 'PV', value: 'Pavia', tipoUTS: 'Provincia' },
  { key: '019', code: 'CR', value: 'Cremona', tipoUTS: 'Provincia' },
  { key: '020', code: 'MN', value: 'Mantova', tipoUTS: 'Provincia' },
  { key: '097', code: 'LC', value: 'Lecco', tipoUTS: 'Provincia' },
  { key: '098', code: 'LO', value: 'Lodi', tipoUTS: 'Provincia' },
  { key: '108', code: 'MB', value: 'Monza e della Brianza', tipoUTS: 'Provincia' },
  { key: '021', code: 'BZ', value: 'Bolzano/Bozen', tipoUTS: 'Provincia autonoma' },
  { key: '022', code: 'TN', value: 'Trento', tipoUTS: 'Provincia autonoma' },
  { key: '023', code: 'VR', value: 'Verona', tipoUTS: 'Provincia' },
  { key: '024', code: 'VI', value: 'Vicenza', tipoUTS: 'Provincia' },
  { key: '025', code: 'BL', value: 'Belluno', tipoUTS: 'Provincia' },
  { key: '026', code: 'TV', value: 'Treviso', tipoUTS: 'Provincia' },
  { key: '027', code: 'VE', value: 'Venezia', tipoUTS: 'Citta metropolitana' },
  { key: '028', code: 'PD', value: 'Padova', tipoUTS: 'Provincia' },
  { key: '029', code: 'RO', value: 'Rovigo', tipoUTS: 'Provincia' },
  { key: '030', code: 'UD', value: 'Udine', tipoUTS: 'Unità non amministrativa' },
  { key: '031', code: 'GO', value: 'Gorizia', tipoUTS: 'Unità non amministrativa' },
  { key: '032', code: 'TS', value: 'Trieste', tipoUTS: 'Unità non amministrativa' },
  { key: '093', code: 'PN', value: 'Pordenone', tipoUTS: 'Unità non amministrativa' },
  { key: '008', code: 'IM', value: 'Imperia', tipoUTS: 'Provincia' },
  { key: '009', code: 'SV', value: 'Savona', tipoUTS: 'Provincia' },
  { key: '010', code: 'GE', value: 'Genova', tipoUTS: 'Citta metropolitana' },
  { key: '011', code: 'SP', value: 'La Spezia', tipoUTS: 'Provincia' },
  { key: '033', code: 'PC', value: 'Piacenza', tipoUTS: 'Provincia' },
  { key: '034', code: 'PR', value: 'Parma', tipoUTS: 'Provincia' },
  { key: '035', code: 'RE', value: "Reggio nell'Emilia", tipoUTS: 'Provincia' },
  { key: '036', code: 'MO', value: 'Modena', tipoUTS: 'Provincia' },
  { key: '037', code: 'BO', value: 'Bologna', tipoUTS: 'Citta metropolitana' },
  { key: '038', code: 'FE', value: 'Ferrara', tipoUTS: 'Provincia' },
  { key: '039', code: 'RA', value: 'Ravenna', tipoUTS: 'Provincia' },
  { key: '040', code: 'FC', value: 'Forlì-Cesena', tipoUTS: 'Provincia' },
  { key: '099', code: 'RN', value: 'Rimini', tipoUTS: 'Provincia' },
  { key: '045', code: 'MS', value: 'Massa-Carrara', tipoUTS: 'Provincia' },
  { key: '046', code: 'LU', value: 'Lucca', tipoUTS: 'Provincia' },
  { key: '047', code: 'PT', value: 'Pistoia', tipoUTS: 'Provincia' },
  { key: '048', code: 'FI', value: 'Firenze', tipoUTS: 'Citta metropolitana' },
  { key: '049', code: 'LI', value: 'Livorno', tipoUTS: 'Provincia' },
  { key: '050', code: 'PI', value: 'Pisa', tipoUTS: 'Provincia' },
  { key: '051', code: 'AR', value: 'Arezzo', tipoUTS: 'Provincia' },
  { key: '052', code: 'SI', value: 'Siena', tipoUTS: 'Provincia' },
  { key: '053', code: 'GR', value: 'Grosseto', tipoUTS: 'Provincia' },
  { key: '100', code: 'PO', value: 'Prato', tipoUTS: 'Provincia' },
  { key: '054', code: 'PG', value: 'Perugia', tipoUTS: 'Provincia' },
  { key: '055', code: 'TR', value: 'Terni', tipoUTS: 'Provincia' },
  { key: '041', code: 'PU', value: 'Pesaro e Urbino', tipoUTS: 'Provincia' },
  { key: '042', code: 'AN', value: 'Ancona', tipoUTS: 'Provincia' },
  { key: '043', code: 'MC', value: 'Macerata', tipoUTS: 'Provincia' },
  { key: '044', code: 'AP', value: 'Ascoli Piceno', tipoUTS: 'Provincia' },
  { key: '109', code: 'FM', value: 'Fermo', tipoUTS: 'Provincia' },
  { key: '056', code: 'VT', value: 'Viterbo', tipoUTS: 'Provincia' },
  { key: '057', code: 'RI', value: 'Rieti', tipoUTS: 'Provincia' },
  { key: '058', code: 'RM', value: 'Roma', tipoUTS: 'Citta metropolitana' },
  { key: '059', code: 'LT', value: 'Latina', tipoUTS: 'Provincia' },
  { key: '060', code: 'FR', value: 'Frosinone', tipoUTS: 'Provincia' },
  { key: '066', code: 'AQ', value: "L'Aquila", tipoUTS: 'Provincia' },
  { key: '067', code: 'TE', value: 'Teramo', tipoUTS: 'Provincia' },
  { key: '068', code: 'PE', value: 'Pescara', tipoUTS: 'Provincia' },
  { key: '069', code: 'CH', value: 'Chieti', tipoUTS: 'Provincia' },
  { key: '070', code: 'CB', value: 'Campobasso', tipoUTS: 'Provincia' },
  { key: '094', code: 'IS', value: 'Isernia', tipoUTS: 'Provincia' },
  { key: '061', code: 'CE', value: 'Caserta', tipoUTS: 'Provincia' },
  { key: '062', code: 'BN', value: 'Benevento', tipoUTS: 'Provincia' },
  { key: '063', code: 'NA', value: 'Napoli', tipoUTS: 'Citta metropolitana' },
  { key: '064', code: 'AV', value: 'Avellino', tipoUTS: 'Provincia' },
  { key: '065', code: 'SA', value: 'Salerno', tipoUTS: 'Provincia' },
  { key: '071', code: 'FG', value: 'Foggia', tipoUTS: 'Provincia' },
  { key: '072', code: 'BA', value: 'Bari', tipoUTS: 'Citta metropolitana' },
  { key: '073', code: 'TA', value: 'Taranto', tipoUTS: 'Provincia' },
  { key: '074', code: 'BR', value: 'Brindisi', tipoUTS: 'Provincia' },
  { key: '075', code: 'LE', value: 'Lecce', tipoUTS: 'Provincia' },
  { key: '110', code: 'BT', value: 'Barletta-Andria-Trani', tipoUTS: 'Provincia' },
  { key: '076', code: 'PZ', value: 'Potenza', tipoUTS: 'Provincia' },
  { key: '077', code: 'MT', value: 'Matera', tipoUTS: 'Provincia' },
  { key: '078', code: 'CS', value: 'Cosenza', tipoUTS: 'Provincia' },
  { key: '079', code: 'CZ', value: 'Catanzaro', tipoUTS: 'Provincia' },
  { key: '080', code: 'RC', value: 'Reggio Calabria', tipoUTS: 'Citta metropolitana' },
  { key: '101', code: 'KR', value: 'Crotone', tipoUTS: 'Provincia' },
  { key: '102', code: 'VV', value: 'Vibo Valentia', tipoUTS: 'Provincia' },
  { key: '081', code: 'TP', value: 'Trapani', tipoUTS: 'Libero consorzio di comuni' },
  { key: '082', code: 'PA', value: 'Palermo', tipoUTS: 'Citta metropolitana' },
  { key: '083', code: 'ME', value: 'Messina', tipoUTS: 'Citta metropolitana' },
  { key: '084', code: 'AG', value: 'Agrigento', tipoUTS: 'Libero consorzio di comuni' },
  { key: '085', code: 'CL', value: 'Caltanissetta', tipoUTS: 'Libero consorzio di comuni' },
  { key: '086', code: 'EN', value: 'Enna', tipoUTS: 'Libero consorzio di comuni' },
  { key: '087', code: 'CT', value: 'Catania', tipoUTS: 'Citta metropolitana' },
  { key: '088', code: 'RG', value: 'Ragusa', tipoUTS: 'Libero consorzio di comuni' },
  { key: '089', code: 'SR', value: 'Siracusa', tipoUTS: 'Libero consorzio di comuni' },
  { key: '090', code: 'SS', value: 'Sassari', tipoUTS: 'Provincia' },
  { key: '091', code: 'NU', value: 'Nuoro', tipoUTS: 'Provincia' },
  { key: '092', code: 'CA', value: 'Cagliari', tipoUTS: 'Citta metropolitana' },
  { key: '095', code: 'OR', value: 'Oristano', tipoUTS: 'Provincia' },
  { key: '104', code: 'OT', value: 'Olbia-Tempio', tipoUTS: 'Provincia soppressa' },
  { key: '105', code: 'OG', value: 'Ogliastra', tipoUTS: 'Provincia soppressa' },
  { key: '106', code: 'VS', value: 'Medio Campidano', tipoUTS: 'Provincia soppressa' },
  { key: '107', code: 'CI', value: 'Carbonia-Iglesias', tipoUTS: 'Provincia soppressa' },
  { key: '111', code: 'SU', value: 'Sud Sardegna', tipoUTS: 'Provincia' }
];

export default {
  areas
};
