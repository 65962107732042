import React from 'react';

const SvgAlerts4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.553 8a.25.25 0 0 0 .249-.22l.398-3.22a.501.501 0 0 0-.498-.56H9.318a.497.497 0 0 0-.498.56l.398 3.22a.25.25 0 0 0 .25.22zm-.053 2h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm5.5 4H6a5 5 0 0 0-3.54 1.46l-.46.42V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2zm0-14H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts4 svgRef={ref} {...props} />);
export default ForwardRef;
