import React from 'react';

const SvgUser11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.382 14l-1.22-2.45a1 1 0 0 0-.9-.55H4.738a1 1 0 0 0-.9.55L2.617 14zm2.47 1.523a1 1 0 0 1-.85.477H.998a1 1 0 0 1-.89-1.45l1.94-3.89A3.001 3.001 0 0 1 4.74 9h6.522a3.001 3.001 0 0 1 2.741 1.66l1.891 3.89a1 1 0 0 1-.04.973zM6 4a2 2 0 1 0 4 0 2 2 0 0 0-4 0zM4 4a4 4 0 0 1 4-4 4 4 0 1 1-4.002 4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser11 svgRef={ref} {...props} />);
export default ForwardRef;
