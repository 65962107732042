import React from 'react';

const SvgOther43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 15 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M0 10a9.994 9.994 0 0 1 4.87-8.584 10.004 10.004 0 0 1 9.87-.222.51.51 0 0 1 .26.44v.13a.51.51 0 0 1-.19.4 9.993 9.993 0 0 0 0 15.672.51.51 0 0 1 .19.4v.13a.51.51 0 0 1-.26.44 10.004 10.004 0 0 1-9.87-.222A9.994 9.994 0 0 1 0 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther43 svgRef={ref} {...props} />);
export default ForwardRef;
