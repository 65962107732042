import React from 'react';

const SvgActions7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.587 13.84a.48.48 0 0 0 .699 0l4.244-4.232a.509.509 0 0 0 0-.71l-.54-.528a.51.51 0 0 0-.708 0l-3.356 3.354-1.228-1.238a.51.51 0 0 0-.709 0l-.53.51a.499.499 0 0 0 0 .698zM16 17H2V6h14zm0-15h-1V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions7 svgRef={ref} {...props} />);
export default ForwardRef;
