import React from 'react';

const SvgCommunication29 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.24 12a1.5 1.5 0 0 0-1.41 1 3 3 0 0 1-5.66 0 1.5 1.5 0 0 0-1.41-1H0v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4zM16 2v2h-2.76a1.5 1.5 0 0 0-1.41 1 3 3 0 0 1-5.66 0 1.5 1.5 0 0 0-1.41-1H2V2zm2 0a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication29 svgRef={ref} {...props} />);
export default ForwardRef;
