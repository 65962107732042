import React from 'react';

const SvgAbstract27 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.85 12.64l-3.13 3.14a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22l-3.13-3.14a.5.5 0 0 1 0-.71l.71-.71a.49.49 0 0 1 .7 0L9 12.67V4.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v8.17l1.44-1.45a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract27 svgRef={ref} {...props} />);
export default ForwardRef;
