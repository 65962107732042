import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import './MoVeInMobileFooter.css';

export default class MoVeInMobileFooter extends Component {
  render() {
    const cls = classNames({
      MoVeInMobileFooter: true,
      [this.props.className]: !!this.props.className
    });

    const textFooter = (
      <Fragment>
        © {new Date().getFullYear()} Air s.r.l. Tutti diritti riservati | P.IVA: 08745320963 / REA: MI–2046088 Sito: <a href="https://www.myair.io/" target="_blank">
          www.myair.io
        </a>
      </Fragment>
    );

    return <div className={cls}>{textFooter}</div>;
  }
}
