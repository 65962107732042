import React from 'react';

const SvgActions91 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8.5 14.88a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v.76a.5.5 0 0 1-.5.5zM7 8l4-5.33L15 8zm15 .5a.5.5 0 0 0-.5-.5h-4L11.73.3a.77.77 0 0 0-.6-.3h-.26a.77.77 0 0 0-.6.3L4.5 8h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1l1.71 7.65A3 3 0 0 0 5.6 20h10.8a3 3 0 0 0 2.92-2.35L21 10h.47a.5.5 0 0 0 .5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions91 svgRef={ref} {...props} />);
export default ForwardRef;
