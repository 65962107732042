import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Radio, Form } from 'antd';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import './RegPrivacyPolicy.css';

@inject('translationsStore', 'moveinStore')
@observer
export default class RegPrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePrivacyPolicyModal: false,
      visiblePrivacyPolicyThirdPartiesModal: false,
    };
  }

  showPrivacyPolicy = () => {
    this.setState({ visiblePrivacyPolicyModal: true });
  };

  showPrivacyPolicyThirdParties = () => {
    this.setState({ visiblePrivacyPolicyThirdPartiesModal: true });
  };

  render() {
    const { translationsStore, moveinStore, form, initialValue, disabled, initialThirdParties, marketingThirdParties } = this.props;
    return (
      <div className="RegPrivacyPolicy">
        <div className="privacyBox">
          <div>
            Premendo il pulsante “Registrati ad AIR”, conferma di aver preso visione dell’
            <a href="#" className="informativa" onClick={this.showPrivacyPolicy}>informativa </a>
            per il trattamento dei suoi dati relativamente al servizio move-in.
          </div>
          <div>
            Con riferimento al trattamento dei dati personali da parte di AIR S.r.l. per le finalità di marketing di cui al punto 4 (i) del paragrafo 2 dell'<a href="#" className="informativa" onClick={this.showPrivacyPolicy}>informativa</a>, vale a dire ricerche di mercato, invio di materiale informativo, promozionale o pubblicitario, comunicazione di nuove iniziative commerciali, offerta di prodotti o servizi (anche tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a> ), come servizi e prodotti automotive tra cui anche prodotti assicurativi ed iniziative promozionali e di vendita di prodotti e/o servizi di AIR S.r.l. o di soggetti terzi via email, SMS o altri sistemi automatizzati, telefonicamente e per posta, o mediante offerta tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a>:
          </div>
          <Form.Item>
            {form.getFieldDecorator('marketingAgreement', {
              initialValue
            })(
              <Radio.Group disabled={disabled}>
                <Radio value={true}>Acconsento</Radio>
                <Radio value={false}>Non acconsento</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {this.props.submitted && (this.props.marketingValue === null || this.props.marketingValue === undefined) && <div style={{color: "red"}}> {translationsStore.translate('registration.form.errorInvalidMarketingPolicy')}</div>}
          <div>
            Con riferimento alla comunicazione dei miei dati personali da parte di AIR S.r.l. alle categorie di soggetti terzi indicati al punto 4 (ii) del paragrafo 2 dell'<a href="#" className="informativa" onClick={this.showPrivacyPolicyThirdParties}>informativa</a> e per le finalità ivi indicate, tra cui marketing e ricerche di mercato, via email, SMS o altri sistemi automatizzati o per posta cartacea o telefonicamente.
          </div>
          <Form.Item>
            {form.getFieldDecorator('marketingThirdPartiesAgreement', {
              initialValue: initialThirdParties
            })(
              <Radio.Group>
                <Radio value={true}>Acconsento</Radio>
                <Radio value={false}>Non acconsento</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {this.props.submitted && marketingThirdParties === undefined && <div style={{color: "red"}}> {translationsStore.translate('registration.form.errorInvalidMarketingPolicy')}</div>}
        </div>
        <PrivacyPolicyModal
          visible={this.state.visiblePrivacyPolicyModal}
          onCancel={() => {
            this.setState({ visiblePrivacyPolicyModal: false });
          }}
        />
        <PrivacyPolicyModal
          assetName="movein-personal-data-processing-policy-third-parties.pdf"
          visible={this.state.visiblePrivacyPolicyThirdPartiesModal}
          onCancel={() => {
            this.setState({ visiblePrivacyPolicyThirdPartiesModal: false });
          }}
        />
      </div>
    );
  }
}
