import React from 'react';

const SvgDevices37 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.002 13.29V2a2 2 0 0 0-2-2h-7.17a2 2 0 0 0-1.42.59l-1 1.05zm-3 3.21a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h.26l1.24 1.24zm-4.5-3.5h-1a.5.5 0 0 1-.5-.5v-3a.46.46 0 0 1 .05-.21l1.95 2v1.21a.5.5 0 0 1-.5.5zm.5 3.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm-4-5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm0 5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm12.85 1.82a.48.48 0 0 0 0-.7L1.382 1.15a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l1.73 1.74-1.29 1.29a2 2 0 0 0-.59 1.42V18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-1.78l.64.63a.48.48 0 0 0 .7 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices37 svgRef={ref} {...props} />);
export default ForwardRef;
