import React from 'react';

const SvgPremium12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 14a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2 1 1 0 0 1 1-1h4a1 1 0 0 1 1 1zm-.5-5a1.5 1.5 0 0 1 1.5 1.5.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5A1.5 1.5 0 0 1 12.5 9zm-4 2h-2a.5.5 0 0 1-.5-.5 1.5 1.5 0 1 1 3 0 .5.5 0 0 1-.5.5zM10.43.05A2 2 0 0 1 11 3.72V5h3a4 4 0 0 1 4 4v2a2 2 0 1 1 0 4v1a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-1a2 2 0 1 1 0-4V9a4 4 0 0 1 4-4h3V3.72A2 2 0 0 1 8 2a2 2 0 0 1 2-2c.145-.003.29.014.43.05zM4 16a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium12 svgRef={ref} {...props} />);
export default ForwardRef;
