import React from 'react';

const SvgAbstract80 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M9.989 4a2.997 2.997 0 0 0-2.993 3c0 1.657 1.34 3 2.993 3a2.997 2.997 0 0 0 2.993-3c0-1.656-1.34-3-2.993-3zm.005 8a8.814 8.814 0 0 1-7.925-5 8.784 8.784 0 0 1 15.85 0 8.814 8.814 0 0 1-7.925 5zm9.994-5.3A10.643 10.643 0 0 0 9.994 0 10.643 10.643 0 0 0 .06 6.67 1 1 0 0 0 0 7c0 .107.02.212.06.31A10.644 10.644 0 0 0 9.994 14a10.644 10.644 0 0 0 9.934-6.67.81.81 0 0 0 .06-.33.93.93 0 0 0 0-.3z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract80 svgRef={ref} {...props} />);
export default ForwardRef;
