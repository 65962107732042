import React from 'react';

const SvgPremium17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.465 6.35h.006zm-.009 0a.5.5 0 0 1 .335.14l.53.53a.5.5 0 0 1 0 .71L8.7 13.35a.49.49 0 0 1-.35.15.47.47 0 0 1-.35-.15l-2.351-2.37a.5.5 0 0 1 0-.71l.53-.53a.48.48 0 0 1 .7 0l1.5 1.49 4.742-4.74a.5.5 0 0 1 .335-.14zm-.009 0h-.006.006zm.018 0a.452.452 0 0 0-.018 0zM12.801 0a3.261 3.261 0 0 1 3 1.93l.2.45A3.31 3.31 0 0 0 17.623 4l.45.26a3.27 3.27 0 0 1 1.711 4.15l-.17.46a3.199 3.199 0 0 0 0 2.32l.17.46a3.27 3.27 0 0 1-1.71 4.15l-.45.2a3.31 3.31 0 0 0-1.681 1.62l-.2.45a3.261 3.261 0 0 1-4.151 1.71l-.46-.17a3.312 3.312 0 0 0-2.321 0l-.46.17a3.261 3.261 0 0 1-4.152-1.71l-.2-.45a3.31 3.31 0 0 0-1.62-1.68l-.45-.2a3.27 3.27 0 0 1-1.711-4.15l.17-.46c.29-.746.29-1.574 0-2.32l-.17-.46a3.27 3.27 0 0 1 1.71-4.15l.45-.2a3.31 3.31 0 0 0 1.621-1.62l.23-.45A3.261 3.261 0 0 1 8.38.22l.46.17a3.302 3.302 0 0 0 2.321 0l.46-.17c.374-.145.77-.22 1.17-.22zm-.01 2c-.155 0-.308.03-.45.09l-.49.17c-.59.23-1.218.345-1.851.34a5.063 5.063 0 0 1-1.88-.35l-.43-.16A1.451 1.451 0 0 0 7.21 2a1.27 1.27 0 0 0-1.161.75l-.19.44A5.3 5.3 0 0 1 3.188 5.9l-.46.21a1.25 1.25 0 0 0-.65 1.6l.17.49c.465 1.2.465 2.53 0 3.73l-.16.43a1.29 1.29 0 0 0 .66 1.64l.44.14a5.3 5.3 0 0 1 2.66 2.66l.21.46A1.25 1.25 0 0 0 7.21 18c.155 0 .308-.03.45-.09l.49-.17A5.003 5.003 0 0 1 10 17.4a5.063 5.063 0 0 1 1.89.35l.43.16c.155.057.317.087.48.09a1.27 1.27 0 0 0 1.161-.75l.19-.44a5.3 5.3 0 0 1 2.661-2.66l.46-.21a1.25 1.25 0 0 0 .65-1.6l-.17-.49a5.158 5.158 0 0 1 0-3.73l.16-.43a1.29 1.29 0 0 0-.66-1.64l-.44-.19a5.3 5.3 0 0 1-2.66-2.66l-.21-.46A1.25 1.25 0 0 0 12.79 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium17 svgRef={ref} {...props} />);
export default ForwardRef;
