import React from 'react';

const SvgFiles13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 4v14a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h9a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zm-6.5 7h-6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM2 8h5a1 1 0 0 0 1-1V2h5v14H2zm13 8V2a2 2 0 0 0-2-2H7.24a3 3 0 0 0-2.12.88L.88 5.12A3 3 0 0 0 0 7.24V16a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles13 svgRef={ref} {...props} />);
export default ForwardRef;
