import React from 'react';

const SvgActions68 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.73 3.69L10.41 9l5.31 5.32 1.42-1.42a.5.5 0 0 1 .85.35v4.25a.52.52 0 0 1-.5.5h-4.24a.5.5 0 0 1-.36-.85l1.42-1.42L9 10.41l-5.32 5.31 1.42 1.42a.5.5 0 0 1-.35.85H.5a.52.52 0 0 1-.5-.5v-4.24a.5.5 0 0 1 .85-.36l1.42 1.42L7.59 9 2.28 3.68.86 5.1A.5.5 0 0 1 0 4.75V.5A.52.52 0 0 1 .5 0h4.25a.5.5 0 0 1 .36.85L3.69 2.27 9 7.59l5.32-5.31L12.9.86a.5.5 0 0 1 .35-.86h4.25a.52.52 0 0 1 .5.5v4.25a.5.5 0 0 1-.85.36z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions68 svgRef={ref} {...props} />);
export default ForwardRef;
