import React, { Component } from 'react';
import MoVeInMobileHeader from '../../../../../common/components/MoVeInMobileHeader';
import MoVeInMobileFooter from "../../../../../common/components/MoVeInMobileFooter";
import RegForm from '../RegForm';
import RegPerson from '../RegPerson';
import RegLegalPerson from '../RegLegalPerson';
import RegVehicle from '../RegVehicle';

import './MobileReg.css';

export default class MobileReg extends Component {
  render() {

    return (
      <div className="MobileReg">
        <MoVeInMobileHeader />
        <div className="container">
          <div className="form">
            <RegForm onClickReg={this.props.onClickReg} form={this.props.form} />
          </div>
          <div className="person">
            <RegPerson />
          </div>
          {this.props.withLegal ? (
            <div className="legalPerson">
              <RegLegalPerson />
            </div>
          ) : (
            <div />
          )}
          <div className="vehicle">
            <RegVehicle />
          </div>
        </div>
        <MoVeInMobileFooter />
      </div>
    );
  }
}
