import React from 'react';

const SvgArrows3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 8 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.993 16.004h2a.5.5 0 0 1 .36.85l-3 3.002a.51.51 0 0 1-.71 0l-3-3.002a.5.5 0 0 1 .35-.85h2V3.996h-2a.5.5 0 0 1-.35-.85l3-3.002a.51.51 0 0 1 .71 0l3 3.002a.5.5 0 0 1-.36.85h-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows3 svgRef={ref} {...props} />);
export default ForwardRef;
