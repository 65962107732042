import React from 'react';

const SvgAbstract29 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.527 5.576l-3.062 1.266 2.683 2.683 1.266-3.062a.489.489 0 0 0-.11-.538l-.239-.24a.489.489 0 0 0-.538-.11zm-3.572-3.53a7.998 7.998 0 0 1 7.198 11.488l1.48 1.51A9.997 9.997 0 0 0 4.957 1.366l1.51 1.48a7.998 7.998 0 0 1 3.488-.8zm0 15.987a7.998 7.998 0 0 1-7.197-4.538 7.987 7.987 0 0 1 .969-8.449l3.749 3.736-2 4.825a.49.49 0 0 0 .11.54l.24.24a.49.49 0 0 0 .54.11l4.829-1.999 3.759 3.766a7.942 7.942 0 0 1-4.999 1.769zM2.337 1.197a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l1.19 1.2a9.997 9.997 0 0 0 14.077 14.076l1.2 1.19a.48.48 0 0 0 .7 0l.529-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract29 svgRef={ref} {...props} />);
export default ForwardRef;
