import React from 'react';

const SvgAbstract40 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.988 8.702a10.702 10.702 0 0 0-12.872-6.3l1.689 1.69c.393-.06.79-.09 1.189-.09a8.814 8.814 0 0 1 7.925 5 7.26 7.26 0 0 1-1.739 2.47l1.42 1.42a9.11 9.11 0 0 0 2.328-3.56.81.81 0 0 0 .06-.33.93.93 0 0 0 0-.3zm-9.994 5.3a8.814 8.814 0 0 1-7.925-5 8.109 8.109 0 0 1 3.128-3.56l2.159 2.16a3.001 3.001 0 0 0 .585 3.414 2.997 2.997 0 0 0 3.412.586l1.759 1.76a8.99 8.99 0 0 1-3.118.64zM2.379.152a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l2.599 2.62A9.659 9.659 0 0 0 .06 8.682a1 1 0 0 0-.06.32c0 .106.02.211.06.31a10.644 10.644 0 0 0 9.934 6.69 10.808 10.808 0 0 0 4.667-1.09l2.948 2.94a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract40 svgRef={ref} {...props} />);
export default ForwardRef;
