import React from 'react';

const SvgFiles12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 1.82V6a1 1 0 0 1-1 1H1.82zM2 20h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H6.83a2 2 0 0 0-1.42.59L.59 5.41A2 2 0 0 0 0 6.83V18a2 2 0 0 0 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles12 svgRef={ref} {...props} />);
export default ForwardRef;
