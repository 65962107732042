import React from 'react';

const SvgArrows42 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.78 9.148a.5.5 0 0 0 0-.71l-3.45-3.44 3.44-3.44a.49.49 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.71 0L.22 4.278a.77.77 0 0 0-.22.53v.38c0 .199.08.39.22.53l4.14 4.13a.5.5 0 0 0 .71 0zm6.44-8.3a.5.5 0 0 0 0 .71l3.45 3.44-3.45 3.44a.49.49 0 0 0 0 .7l.71.71a.5.5 0 0 0 .71 0l4.14-4.13a.77.77 0 0 0 .22-.53v-.38a.75.75 0 0 0-.22-.53L13.64.148a.5.5 0 0 0-.71 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows42 svgRef={ref} {...props} />);
export default ForwardRef;
