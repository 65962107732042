import React from 'react';

const SvgCommunication47 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.216 13.772l-1.228-2.657a1.997 1.997 0 0 1 .34-2.207l1.557-1.687a.5.5 0 0 0 0-.69l-.38-.379a.5.5 0 0 0-.688 0L9.129 7.71A1.997 1.997 0 0 1 6.872 8L4.216 6.77 15.13 2.857zM17.837.408l-.26-.26a.499.499 0 0 0-.35-.14.469.469 0 0 0-.169 0L.331 6.005a.5.5 0 0 0-.33.51v.16a.51.51 0 0 0 .29.46l7.18 3.388 3.386 7.187a.51.51 0 0 0 .459.29h.16a.5.5 0 0 0 .47-.33L17.975.927a.5.5 0 0 0-.139-.52z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication47 svgRef={ref} {...props} />);
export default ForwardRef;
