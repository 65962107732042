import React from 'react';

const SvgCommunication39 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.998 1v6.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V3.42l-6.37 6.36a.75.75 0 0 1-.53.22h-.2a.75.75 0 0 1-.53-.22L.148 1.56a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0l7.44 7.44L14.588 2h-4.09a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h6.5c.199 0 .39.08.53.22l.27.27c.128.139.2.32.2.51z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication39 svgRef={ref} {...props} />);
export default ForwardRef;
