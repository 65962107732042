import React from 'react';

const SvgArrows43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.5 0h-1a.5.5 0 0 0-.5.5V11H3.33l3.44-3.44a.49.49 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.71 0L.22 11.28a.77.77 0 0 0-.22.53v.38c0 .199.08.39.22.53l5.14 5.13a.5.5 0 0 0 .71 0l.71-.7a.5.5 0 0 0 0-.71L3.33 13H16a2 2 0 0 0 2-2V.5a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows43 svgRef={ref} {...props} />);
export default ForwardRef;
