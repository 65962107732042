import React from 'react';

const SvgOther72 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.008 5.984c-1.1 0-1.999-.901-1.999-2.002 0-1.101.9-2.003 1.999-2.003 1.099 0 1.998.902 1.998 2.003s-.9 2.002-1.998 2.002zm0 9.01c-1.1 0-1.999-.9-1.999-2.002 0-1.101.9-2.002 1.999-2.002 1.099 0 1.998.9 1.998 2.002 0 1.101-.9 2.002-1.998 2.002zM18.4 8.687c-.1-1.001-.999-1.702-1.998-1.702h-3.797c1.698-1.401 1.798-4.004.4-5.606-1.4-1.602-3.998-1.902-5.596-.4-1.6 1.501-1.899 3.904-.4 5.606.1.1.2.3.4.4H3.613c-1 0-1.899.701-1.999 1.702L.015 18.9c-.1.5.3 1.101.8 1.101H19c.6 0 .999-.4.999-1.001v-.2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther72 svgRef={ref} {...props} />);
export default ForwardRef;
