import React from 'react';

const SvgCommunication56 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.494 9.003h-7.5v-2a.5.5 0 0 0-.85-.36l-3 3a.51.51 0 0 0 0 .71l3 3a.5.5 0 0 0 .85-.35v-2h7.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm8.36-5.36l-3.003-3a.5.5 0 0 0-.85.36v2H8.494a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h7.507v2a.5.5 0 0 0 .86.36l3.003-3a.51.51 0 0 0-.01-.72z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication56 svgRef={ref} {...props} />);
export default ForwardRef;
