import React from 'react';

const SvgCommunication15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5zm-4 4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5zM20 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5.09a1 1 0 0 1 .7.29l1.49 1.49c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22l1.49-1.49a1 1 0 0 1 .7-.29H18a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication15 svgRef={ref} {...props} />);
export default ForwardRef;
