import React from 'react';

const SvgActions122 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.986 2.002v7.29l1.999 2v-9.29a2 2 0 0 0-1.999-2H6.994a1.93 1.93 0 0 0-1 .28l1.71 1.72zm6.884 6.274l-.89-1.782.68-1.301a.491.491 0 0 0-.22-.671l-.889-.46a.489.489 0 0 0-.669.21l-.77 1.481c-.072.143-.11.3-.11.46v.571c0 .16.038.318.11.46l.87 1.763-.91 1.761c-.072.143-.11.3-.11.46v.571c0 .16.038.318.11.46l.77 1.492a.509.509 0 0 0 .68.21l.889-.46a.501.501 0 0 0 .21-.67l-.64-1.322.91-1.762c.072-.142.11-.3.109-.46v-.58a1.073 1.073 0 0 0-.13-.461zM2.098 4.272a.49.49 0 0 0-.669-.21l-.89.46a.49.49 0 0 0-.219.67L1 6.495.09 8.246a1.073 1.073 0 0 0-.09.46v.58c0 .16.037.319.11.461L1 11.51l-.68 1.31a.501.501 0 0 0 .21.671l.89.46a.509.509 0 0 0 .678-.21l.77-1.491c.08-.141.124-.299.13-.46v-.571c0-.16-.037-.318-.11-.46l-.89-1.752.91-1.752c.067-.147.098-.309.09-.47v-.571c0-.16-.037-.318-.11-.46zm12.899 11.73H6.994v-9.76l8.003 8zM3.377.152a.479.479 0 0 0-.7 0l-.529.53a.48.48 0 0 0 0 .7l2.846 2.86v11.76c0 1.104.894 2 1.997 2h7.988a1.998 1.998 0 0 0 1.997-1.78l1.638 1.63a.479.479 0 0 0 .699 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions122 svgRef={ref} {...props} />);
export default ForwardRef;
