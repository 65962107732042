import React from 'react';

const SvgPayment18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.6 9a.25.25 0 0 1 .21.12.25.25 0 0 1 0 .24l-.61 1.23a.76.76 0 0 1-.67.41H6.92A3.26 3.26 0 0 0 10 13.25a3.21 3.21 0 0 0 2-.67.5.5 0 0 1 .66.05l.53.54a.46.46 0 0 1 .15.38.52.52 0 0 1-.19.36A5 5 0 0 1 5.1 11h-.6a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h.6a5 5 0 0 1 8-2.91c.11.09.178.22.19.36a.46.46 0 0 1-.15.38l-.53.54a.5.5 0 0 1-.66.05 3.21 3.21 0 0 0-2-.67A3.26 3.26 0 0 0 6.92 9zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment18 svgRef={ref} {...props} />);
export default ForwardRef;
