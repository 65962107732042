import React from 'react';

const SvgPayment28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 7.5a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5zm-3 4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5zm-6.82 8.35a.47.47 0 0 0 .35.15h.29a.47.47 0 0 0 .35-.15L5 18a.51.51 0 0 1 .71 0l1.81 1.81a.47.47 0 0 0 .35.15h.29a.47.47 0 0 0 .35-.15L10.32 18a.51.51 0 0 1 .68 0l1.81 1.81a.47.47 0 0 0 .35.15h.29a.47.47 0 0 0 .35-.15L15.71 18a1 1 0 0 0 .29-.7V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v16.26c.004.265.107.518.29.71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment28 svgRef={ref} {...props} />);
export default ForwardRef;
