import React from 'react';

const SvgAbstract1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 10.62a.5.5 0 0 0-.5-.5H9.88V8.5a.5.5 0 0 0-.5-.5h-.76a.5.5 0 0 0-.5.5v1.62H6.5a.5.5 0 0 0-.5.5v.76a.5.5 0 0 0 .5.5h1.62v1.62a.5.5 0 0 0 .5.5h.76a.5.5 0 0 0 .5-.5v-1.62h1.62a.5.5 0 0 0 .5-.5zM16 17H2V6h14zm2-13a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract1 svgRef={ref} {...props} />);
export default ForwardRef;
