import React from 'react';

const SvgDevices40 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 6.5v1a.5.5 0 0 1-.5.5H15v2.34a4 4 0 0 1-1.17 2.83l-1.95 2A3 3 0 0 1 10 16v3.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V16a3 3 0 0 1-1.88-.86l-1.95-2A4 4 0 0 1 1 10.34V8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6H3V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V6h6V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices40 svgRef={ref} {...props} />);
export default ForwardRef;
