import React from 'react';

const SvgCommunication34 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 14H6a5 5 0 0 0-3.54 1.46l-.46.42V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2zm-2.94-5.38a.74.74 0 0 0 0-1.24l-3.89-2.6A.75.75 0 0 0 8 5.4v5.2a.75.75 0 0 0 1.17.62zM16 0H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication34 svgRef={ref} {...props} />);
export default ForwardRef;
