import React from 'react';

const SvgDevices3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 15a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zM9.68 4.08L9.14.84a1 1 0 0 0-1-.84H3.85a1 1 0 0 0-1 .84l-.53 3.24A3 3 0 0 0 0 7v8a3 3 0 0 0 2.41 2.94L3 21.16A1 1 0 0 0 4 22h4.3a1 1 0 0 0 1-.84l.54-3.27A3 3 0 0 0 12 15V7a3 3 0 0 0-2.32-2.92z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices3 svgRef={ref} {...props} />);
export default ForwardRef;
