import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './RegLegalPerson.css';
import LabelInput from '../../../../../common/components/LabelInput';
import { AccountSquareOutlinedIcon } from '../../../../../common/icons';
import SmallDivision from '../../../../../common/components/SmallDivision';

// TODO verify codIstatComune
@inject('translationsStore', 'moveinStore')
@observer
export default class RegLegalPerson extends Component {
  render() {
    const { translationsStore, moveinStore } = this.props;
    return (
      <div className="RegLegalPerson">
        <div className="header">
          <SmallDivision
            title={translationsStore.translate('registration.legalPerson.title')}
            icon={<AccountSquareOutlinedIcon />}
            style={{ marginTop: '24px', marginBottom: '24px' }}
          />
        </div>
        <div className="personalContent">
          <LabelInput label={translationsStore.translate('registration.legalPerson.ragioneSociale')} value={moveinStore.company.name} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.telefono')} value={moveinStore.company.phone} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.email')} value={moveinStore.company.email} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.partitaIva')} value={moveinStore.company.tax_code} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.provincia')} value={moveinStore.company.registered_office.province} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.comune')} value={moveinStore.company.registered_office.city} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.indirizzo')} value={moveinStore.company.registered_office.street} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.numCivico')} value={moveinStore.company.registered_office.building_number} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.legalPerson.cap')} value={moveinStore.company.registered_office.postcode} disabled={true} />
          <span className="masonryGhostSeparator" />
          <span className="masonryGhostSeparator" />
          <span className="masonryGhostSeparator" />
        </div>
      </div>
    );
  }
}
