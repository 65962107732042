import React from 'react';

const SvgDevices23 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M22 12H2V2h20zm.5-12h-21A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16H10l-.45.89A2 2 0 0 1 7.76 18H7.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-.26a2 2 0 0 1-1.79-1.11L14 16h8.5a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 22.5 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices23 svgRef={ref} {...props} />);
export default ForwardRef;
