import React from 'react';

const SvgOther58 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18.85 16.622a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0l-5.86-5.85H.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h8.26l-7.61-7.62a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0zm-3.38-8.62A8 8 0 0 1 8 .532a.51.51 0 0 1 .13-.37.52.52 0 0 1 .37-.16h1a.5.5 0 0 1 .5.46 6 6 0 0 0 5.51 5.54.5.5 0 0 1 .46.5v1a.52.52 0 0 1-.16.37.51.51 0 0 1-.37.13zM14 3.532v-1.04a.5.5 0 0 1 .4-.49 2 2 0 0 0 1.57-1.6.5.5 0 0 1 .49-.4h1a.49.49 0 0 1 .42.17.49.49 0 0 1 .12.4 4 4 0 0 1-1.09 2.18 6 6 0 0 1 3.08 4.7.56.56 0 0 1-.13.39.52.52 0 0 1-.37.16h-1a.49.49 0 0 1-.49-.44 4 4 0 0 0-3.56-3.56.49.49 0 0 1-.44-.47zm2.5 5.47a.5.5 0 0 1 .5.5v2a.49.49 0 0 1-.29.5l-3-3zm3.5.5v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther58 svgRef={ref} {...props} />);
export default ForwardRef;
