import React from 'react';

const SvgAbstract25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.28 15.78a.77.77 0 0 0 .53.22h.38a.75.75 0 0 0 .53-.22l3.13-3.14a.5.5 0 0 0 0-.71l-.7-.71a.5.5 0 0 0-.71 0L11 12.67V4.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v8.17l-1.44-1.44a.49.49 0 0 0-.7 0l-.71.71a.5.5 0 0 0 0 .71zM10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm0 18c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10a10 10 0 0 0 10 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract25 svgRef={ref} {...props} />);
export default ForwardRef;
