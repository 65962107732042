import React from 'react';

const SvgCommunication22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 24" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 23a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-1H2zM18 1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1h16zm-3.75 16h-8.5a.75.75 0 0 1-.67-1.09L6 14.1A2 2 0 0 1 7.77 13h4.46A2 2 0 0 1 14 14.1l.9 1.81a.75.75 0 0 1-.65 1.09zM10 6.75A2.25 2.25 0 1 1 7.75 9 2.24 2.24 0 0 1 10 6.75zM18 4H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication22 svgRef={ref} {...props} />);
export default ForwardRef;
