import React from 'react';

const SvgAbstract75 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M6 16.9l1.5-2.6c1.5.9 3.5.9 5 0l1.5 2.6c-2.5 1.5-5.5 1.5-8 0zM6 3.1l1.5 2.6C6 6.6 5 8.2 5 10H2c0-2.9 1.5-5.5 4-6.9zM7 10c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3zm11 0h-3c0-1.8-1-3.4-2.5-4.3L14 3.1c2.5 1.4 4 4 4 6.9zM10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract75 svgRef={ref} {...props} />);
export default ForwardRef;
