import React from 'react';

const SvgActions64 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.59 4.29a1.05 1.05 0 0 0-.71-.29H20v5.9a2 2 0 0 1-.59 1.42l-7.58 7.57.89.89a.75.75 0 0 0 1.06 0l7-7A4 4 0 0 0 22 9.9V5.12a1.05 1.05 0 0 0-.29-.71zM13 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5-5.88a1.05 1.05 0 0 0-.29-.71l-.12-.12a1.05 1.05 0 0 0-.71-.29H8.93a2 2 0 0 0-1.42.59L.29 7.81a1 1 0 0 0 0 1.41l8.49 8.49a1 1 0 0 0 1.41 0l7.22-7.22A2 2 0 0 0 18 9.07z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions64 svgRef={ref} {...props} />);
export default ForwardRef;
