import React from 'react';

const SvgPremium8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3.446 6.084a5.005 5.005 0 0 1-.777-1.052h6.602a4.655 4.655 0 0 1-.767 1.052L5.975 8.627zm6.512 1.405A7 7 0 0 0 12 2.496V.5a.5.5 0 0 0-.498-.5h-.996a.499.499 0 0 0-.498.5v1.996c-.004.18-.02.361-.05.54H2.042a3.875 3.875 0 0 1-.05-.49V.55a.5.5 0 0 0-.498-.51H.498A.499.499 0 0 0 0 .54v1.997A7 7 0 0 0 2.042 7.53l2.529 2.496-2.53 2.536a7 7 0 0 0-2.04 4.943V19.5c0 .275.222.499.497.499h.996a.499.499 0 0 0 .498-.5v-1.996c.006-.164.023-.328.05-.49h7.916c.027.162.044.326.05.49V19.5c0 .275.223.499.498.499h.996a.499.499 0 0 0 .498-.5v-1.996a7 7 0 0 0-2.042-4.943l-1.294-1.298L7.26 12.67l1.294 1.298c.286.317.53.67.727 1.048H2.68c.21-.366.467-.702.767-.998z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium8 svgRef={ref} {...props} />);
export default ForwardRef;
