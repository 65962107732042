import React from 'react';

const SvgAbstract33 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M11.5 10h-7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0-6h-7a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM3 14a1 1 0 0 1-1-1V2h12v11a3 3 0 0 0 .18 1zM19.5 2h-1a.5.5 0 0 0-.5.5V13a1 1 0 0 1-2 0V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v11a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V2.5a.5.5 0 0 0-.5-.5z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract33 svgRef={ref} {...props} />);
export default ForwardRef;
