import React from 'react';

const SvgOther41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.743.15l.09.09a.55.55 0 0 1 .15.42 10.205 10.205 0 0 0 2.489 7.996 10.186 10.186 0 0 0 7.63 3.435 9.991 9.991 0 0 0 1.22-.08h.06a.51.51 0 0 1 .38.13l.09.09a.54.54 0 0 1 .13.51 10.21 10.21 0 0 1-10.77 7.21 10.214 10.214 0 0 1-9.163-9.17A10.219 10.219 0 0 1 7.253 0h.14a.51.51 0 0 1 .35.15zm-1.76 2.622A8.217 8.217 0 0 0 8.7 17.928a8.195 8.195 0 0 0 8.512-3.908 12.092 12.092 0 0 1-7.69-3.516 12.157 12.157 0 0 1-3.54-7.732z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther41 svgRef={ref} {...props} />);
export default ForwardRef;
