import React from 'react';

const SvgPayment13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11 5v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V5a2 2 0 0 0-2 2v.81a2 2 0 0 0 .73 1.54l3.43 2.83a.22.22 0 0 1 .09.19v.88h-2.5v-1a.5.5 0 0 0-.5-.5H7.5a.5.5 0 0 0-.5.5V13a2 2 0 0 0 2 2v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V15a2 2 0 0 0 2-2v-.81a2 2 0 0 0-.73-1.54L8.84 7.82a.22.22 0 0 1-.09-.19v-.88h2.5v1a.5.5 0 0 0 .5.5h.75a.5.5 0 0 0 .5-.5V7a2 2 0 0 0-2-2zm-1 13a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment13 svgRef={ref} {...props} />);
export default ForwardRef;
