import React from 'react';

const SvgUser5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.89 12.55A1 1 0 0 1 13 14H.998a1 1 0 0 1-.89-1.45l1.44-2.89A3 3 0 0 1 4.238 8H9.76a3 3 0 0 1 2.69 1.66zm7.962.973a1 1 0 0 1-.85.477h-5.17a3 3 0 0 0-.13-2.34l-1.451-2.9a4.89 4.89 0 0 0-.48-.76h4a3 3 0 0 1 2.682 1.66l1.44 2.89a1 1 0 0 1-.04.973zM12 3a3 3 0 1 1 6.001 0 3 3 0 0 1-6 0zM3.998 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser5 svgRef={ref} {...props} />);
export default ForwardRef;
