import React from 'react';

const SvgAlerts9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 20a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.772-11.22a.25.25 0 0 1-.25.22H7.468a.25.25 0 0 1-.249-.22L6.82 5.56A.501.501 0 0 1 7.318 5h1.354a.497.497 0 0 1 .498.56zM9 12.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm6.71 1.21l-.83-.83a3 3 0 0 1-.88-2.12V8a6 6 0 0 0-1.2-3.6l-.9-1.2A3 3 0 0 0 9.5 2H9V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2h-.5a3 3 0 0 0-2.4 1.2l-.9 1.2A6 6 0 0 0 2 8v2.76a3 3 0 0 1-.88 2.12l-.83.83a1 1 0 0 0-.29.7V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-.59a1 1 0 0 0-.29-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts9 svgRef={ref} {...props} />);
export default ForwardRef;
