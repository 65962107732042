import React from 'react';

const SvgUser2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.75 14h8.5a.75.75 0 0 0 .67-1.09L13 11.1a2 2 0 0 0-1.77-1.1H6.77A2 2 0 0 0 5 11.1l-.9 1.81A.75.75 0 0 0 4.75 14zM9 8.25A2.25 2.25 0 1 0 6.75 6 2.24 2.24 0 0 0 9 8.25zM16 16H2V2h14zm0-16H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser2 svgRef={ref} {...props} />);
export default ForwardRef;
