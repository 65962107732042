import React from 'react';

const SvgUser10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.852 19.523a1 1 0 0 1-.85.477H.998a1 1 0 0 1-.89-1.45l1.89-3.79A5.002 5.002 0 0 1 6.47 12h7.062a5.002 5.002 0 0 1 4.471 2.76l1.891 3.79a1 1 0 0 1-.04.973zM10 0a5 5 0 1 1-5.002 5A5 5 0 0 1 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser10 svgRef={ref} {...props} />);
export default ForwardRef;
