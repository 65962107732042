import React from 'react';

const SvgCommunication27 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.993 11.494v-7.5h2a.5.5 0 0 0 .36-.85l-3-3a.51.51 0 0 0-.71 0l-3 3a.5.5 0 0 0 .35.85h2v7.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zm5.36 8.36l3-3.003a.5.5 0 0 0-.36-.85h-2V8.494a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v7.507h-2a.5.5 0 0 0-.36.86l3 3.003a.51.51 0 0 0 .72-.01z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication27 svgRef={ref} {...props} />);
export default ForwardRef;
