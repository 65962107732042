import React from 'react';

const SvgPayment19 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3.37 9.14H3V5.75l.17-.25a1 1 0 0 1 1.35-.36 1 1 0 0 1 .36 1.35zm15.78-2.66a1 1 0 0 1 .36-1.35 1 1 0 0 1 1.35.36l.15.26V9.1h-.37zM17 5A5 5 0 1 1 7 5a5 5 0 0 1 10 0zM9 5a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm2 11.05V19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-.8a1 1 0 0 0-.37-.71l-3.69-3A2.47 2.47 0 0 1 0 12.54V5a1 1 0 0 1 2 0v4.57a2.34 2.34 0 0 0-.67.73 2.41 2.41 0 0 0-.28 1.89 2.47 2.47 0 0 0 1.14 1.54l3.59 2.13a.5.5 0 0 0 .75-.55.56.56 0 0 0-.23-.31l-3.6-2.13a1.5 1.5 0 0 1-.51-2.06 1.46 1.46 0 0 1 .81-.64 1.36 1.36 0 0 1 .49-.08 1.4 1.4 0 0 1 .75.21l5.3 3.18A3 3 0 0 1 11 16.05zM24 5v7.53a2.47 2.47 0 0 1-.94 1.95l-3.69 3a1 1 0 0 0-.37.77V19a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2.95a3 3 0 0 1 1.46-2.57l5.3-3.18a1.4 1.4 0 0 1 .75-.21 1.36 1.36 0 0 1 .49.08c.339.108.627.335.81.64a1.5 1.5 0 0 1-.51 2.06L17.7 15a.56.56 0 0 0-.23.31.5.5 0 0 0 .75.55l3.59-2.16A2.47 2.47 0 0 0 23 12.19a2.48 2.48 0 0 0-1-2.61V5a1 1 0 0 1 2 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment19 svgRef={ref} {...props} />);
export default ForwardRef;
