import React from 'react';

const SvgActions85 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 21 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20.998 9a9 9 0 0 1-9 9 8.89 8.89 0 0 1-5.16-1.63.5.5 0 0 1-.07-.76l.72-.73a.5.5 0 0 1 .63-.06 6.91 6.91 0 0 0 3.88 1.18 7 7 0 1 0-7-7h2.36a.49.49 0 0 1 .36.15l.2.2a.5.5 0 0 1 0 .71l-3.53 3.54a.51.51 0 0 1-.71 0l-3.53-3.54a.5.5 0 0 1 0-.71l.2-.2A.49.49 0 0 1 .708 9h2.29a9 9 0 0 1 18 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions85 svgRef={ref} {...props} />);
export default ForwardRef;
