import React from 'react';

const SvgPayment11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 4v11a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h12a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zM2 13V8h14v5zM16 2v3H2V2zm0-2H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment11 svgRef={ref} {...props} />);
export default ForwardRef;
