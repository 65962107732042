import React from 'react';

const SvgAlerts19 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.82 7.56l.398 3.22a.25.25 0 0 0 .25.22h1.055a.25.25 0 0 0 .249-.22l.398-3.22a.501.501 0 0 0-.498-.56h-1.354a.497.497 0 0 0-.498.56zM11.5 13h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-8.712 4L11 3l8.202 14zM11.81.37a.76.76 0 0 0-.65-.37h-.3a.76.76 0 0 0-.65.37L.097 17.63a.79.79 0 0 0 0 .76l.14.23a.73.73 0 0 0 .65.38h20.225a.73.73 0 0 0 .65-.38l.14-.23a.79.79 0 0 0 0-.76z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts19 svgRef={ref} {...props} />);
export default ForwardRef;
