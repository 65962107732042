import React from 'react';

const SvgPayment30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 13.492a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.001a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm6 2.503H9a1 1 0 0 1-1-1.001v-4.005a1 1 0 0 1 1-1.001h7zM2 4.982A1 1 0 0 1 3 3.98h2c.004.704.194 1.395.55 2.002H3a1 1 0 0 1-1-1.001zm7-3.004c1.105 0 2 .897 2 2.003a2.001 2.001 0 1 1-2-2.003zm9 6.007a2.001 2.001 0 0 0-2-2.002h-3.55A4.009 4.009 0 0 0 13 3.981h3a2.001 2.001 0 0 0-2-2.003h-1.55a4 4 0 0 0-6.9 0H3c-1.657 0-3 1.345-3 3.004v13.016C0 19.103.895 20 2 20h14c1.105 0 2-.897 2-2.002z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment30 svgRef={ref} {...props} />);
export default ForwardRef;
