import React from 'react';

const SvgAlerts13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 20a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm4-6H4V8a4 4 0 0 1 .8-2.4l.9-1.2a1 1 0 0 1 .8-.4h3a1 1 0 0 1 .8.4l.9 1.2A4 4 0 0 1 12 8zm3.71-.29l-.83-.83a3 3 0 0 1-.88-2.12V8a6 6 0 0 0-1.2-3.6l-.9-1.2A3 3 0 0 0 9.5 2H9V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2h-.5a3 3 0 0 0-2.4 1.2l-.9 1.2A6 6 0 0 0 2 8v2.76a3 3 0 0 1-.88 2.12l-.83.83a1 1 0 0 0-.29.7V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-.59a1 1 0 0 0-.29-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts13 svgRef={ref} {...props} />);
export default ForwardRef;
