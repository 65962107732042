import React from 'react';

const SvgFiles26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.92 8.14l-2.002 1.7.61 2.54a.5.5 0 0 1-.74.55l-2.243-1.37-2.242 1.37a.511.511 0 0 1-.751-.55l.61-2.54-2.002-1.7a.5.5 0 0 1 .28-.88l2.613-.2 1.001-2.43a.5.5 0 0 1 .921 0l1.002 2.43 2.602.2a.5.5 0 0 1 .34.88zM18 2h-5.59a1 1 0 0 1-.7-.29L10.29.29a1 1 0 0 0-.7-.29H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles26 svgRef={ref} {...props} />);
export default ForwardRef;
