import React from 'react';

const SvgAbstract8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8.032 14.372a.48.48 0 0 0 .7 0l5.616-5.62a.5.5 0 0 0 0-.71l-.53-.53a.5.5 0 0 0-.699 0l-4.737 4.74-1.5-1.49a.48.48 0 0 0-.699 0l-.53.53a.5.5 0 0 0 0 .71zM16.997.002c-.7-.001-1.38.243-1.92.69a.28.28 0 0 0-.089.19.24.24 0 0 0 .07.18l3.878 3.88a.24.24 0 0 0 .18.07.28.28 0 0 0 .19-.09 3.001 3.001 0 0 0-2.31-4.92zM4.944 1.062a.24.24 0 0 0 .06-.18.28.28 0 0 0-.09-.19 2.997 2.997 0 0 0-4.038.19 3.001 3.001 0 0 0-.18 4.04.28.28 0 0 0 .19.08.24.24 0 0 0 .18-.07zm-1.94 9.94a7 7 0 0 1 7.002-7 7 7 0 1 1-7.001 7zm6.997-9c-4.968 0-8.995 4.03-8.995 9s4.027 9 8.995 9c4.968 0 8.995-4.03 8.995-9s-4.027-9-8.995-9z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract8 svgRef={ref} {...props} />);
export default ForwardRef;
