import React from 'react';

const SvgArrows30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 5 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M1.06 9.138a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.15-.36v-7.16a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .71 0l3.79 3.79a.48.48 0 0 1 0 .7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows30 svgRef={ref} {...props} />);
export default ForwardRef;
