import React from 'react';

const SvgArrows6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.354 11.85a.509.509 0 0 1-.708 0l-2.996-3A.5.5 0 0 1 7 8h5.99a.5.5 0 0 1 .36.85zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows6 svgRef={ref} {...props} />);
export default ForwardRef;
