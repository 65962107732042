import React from 'react';

const SvgCommunication42 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-.34a.46.46 0 0 1 .11-.3L6 8.19A.52.52 0 0 1 6.35 8a.5.5 0 0 1 .39.18l1.45 1.75a.49.49 0 0 0 .38.18.51.51 0 0 0 .4-.2l2.88-3.72a.51.51 0 0 1 .8 0l4.25 5.67a.51.51 0 0 1 .1.3zM7.5 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM16 0H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication42 svgRef={ref} {...props} />);
export default ForwardRef;
