import React from 'react';

const SvgAbstract14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.304 18.24a6.06 6.06 0 0 1-8.548 0 5.996 5.996 0 0 1 0-8.48l8.498-8.44a4.557 4.557 0 0 1 6.416 0 4.468 4.468 0 0 1 0 6.36l-7.797 7.74a3.004 3.004 0 0 1-4.274 0 2.999 2.999 0 0 1 0-4.25l4.945-4.9a.5.5 0 0 1 .71 0l.54.53a.5.5 0 0 1 0 .71l-4.944 4.9a1.26 1.26 0 0 0 .08 1.7c.463.46 1.202.491 1.702.07l7.797-7.73a2.739 2.739 0 0 0 0-3.89 2.784 2.784 0 0 0-3.924 0L2.987 11a4.247 4.247 0 0 0 0 6 4.327 4.327 0 0 0 6.056 0l5.655-5.61a.51.51 0 0 1 .721 0l.53.53a.48.48 0 0 1 0 .7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract14 svgRef={ref} {...props} />);
export default ForwardRef;
