import React from 'react';

const SvgAbstract59 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M16 13h-3v3h3zm2 0v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2zM5 13H2v3h3zm2 3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2zm9-14h-3v3h3zm2 0v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2zM5 2H2v3h3zm2 0v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract59 svgRef={ref} {...props} />);
export default ForwardRef;
