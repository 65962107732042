import React from 'react';

const SvgActions10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 11.5c0 .3-.1.5-.3.7l-3.5 3.5c-.2.2-.4.3-.7.3h-5c-.3 0-.5-.1-.7-.3l-1.1-1.1 9.9-9.9 1.1 1.1c.2.2.3.4.3.7zm-14 0v-5c0-.3.1-.5.3-.7l3.5-3.5c.2-.2.4-.3.7-.3h5c.3 0 .5.1.7.3l1.1 1.1-9.9 9.9-1.2-1.1c-.1-.2-.2-.4-.2-.7zm15.1-7.1L13.6.9C13 .3 12.3 0 11.5 0h-5c-.8 0-1.6.3-2.1.9L.9 4.4C.3 5 0 5.7 0 6.5v5c0 .8.3 1.6.9 2.1l3.5 3.5c.6.6 1.3.9 2.1.9h5c.8 0 1.6-.3 2.1-.9l3.5-3.5c.6-.6.9-1.3.9-2.1v-5c0-.8-.3-1.6-.9-2.1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions10 svgRef={ref} {...props} />);
export default ForwardRef;
