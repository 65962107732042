import React from 'react';

const SvgArrows7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.545 10.69a.5.5 0 0 1 .105-.54l2.996-3a.509.509 0 0 1 .708 0l2.996 3a.5.5 0 0 1-.36.85H7a.5.5 0 0 1-.455-.31zM17.07 2.93A10 10 0 0 1 20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0a10 10 0 0 1 7.071 2.929zM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows7 svgRef={ref} {...props} />);
export default ForwardRef;
