import React from 'react';

const SvgAbstract7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 21 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.002 2.002a8.896 8.896 0 0 0-4.231 1.056l1.5 1.486a7.022 7.022 0 0 1 6.6.61 6.973 6.973 0 0 1 3.134 5.821 6.86 6.86 0 0 1-.56 2.722l1.5 1.496a8.82 8.82 0 0 0 1.06-4.218c0-4.956-4.03-8.973-9.003-8.973zM17.996.007c-.7 0-1.38.242-1.919.687a.278.278 0 0 0-.09.19.238.238 0 0 0 .07.178l3.879 3.861a.24.24 0 0 0 .18.07.28.28 0 0 0 .19-.09c.745-.89.904-2.13.41-3.18a3 3 0 0 0-2.72-1.716zM5.941 1.064a.239.239 0 0 0 .06-.18.279.279 0 0 0-.09-.189A3.003 3.003 0 0 0 4.002.007a1.143 1.143 0 0 0-.26 0l1.62 1.636zm5.064 16.88a7.006 7.006 0 0 1-6.288-3.905 6.949 6.949 0 0 1 .756-7.341l9.824 9.782a7.023 7.023 0 0 1-4.292 1.465zM1.383.148a.51.51 0 0 0-.71 0l-.53.528a.508.508 0 0 0 0 .708l.94.938a2.987 2.987 0 0 0 .61 2.592.28.28 0 0 0 .19.08.24.24 0 0 0 .18-.07l.82-.818 1.18 1.177a8.87 8.87 0 0 0-2.06 5.693 8.972 8.972 0 0 0 5.13 8.146 9.015 9.015 0 0 0 9.574-1.166l1.91 1.894a.48.48 0 0 0 .699 0l.53-.529a.478.478 0 0 0 0-.697z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract7 svgRef={ref} {...props} />);
export default ForwardRef;
