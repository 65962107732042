import React from 'react';

const SvgUser3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.25 15H3.75a.75.75 0 0 1-.67-1.09L4 12.1A2 2 0 0 1 5.78 11h6.44A2 2 0 0 1 14 12.1l.91 1.81a.75.75 0 0 1-.66 1.09zM9 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm7-3H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser3 svgRef={ref} {...props} />);
export default ForwardRef;
