import React from 'react';

const SvgOther10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 6H2v10h16zM7 4h6V2H7zm13 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h3a2 2 0 0 1 2 2zM8 11v-.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v.5a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther10 svgRef={ref} {...props} />);
export default ForwardRef;
