import React from 'react';

const SvgUser1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.61 14.16a6.992 6.992 0 0 1-11.23 0 1 1 0 0 1-.07-1l.21-.44A2.997 2.997 0 0 1 7.227 11h5.536a2.997 2.997 0 0 1 2.687 1.68l.23.47a1 1 0 0 1-.07 1.01zM10 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-3C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser1 svgRef={ref} {...props} />);
export default ForwardRef;
