import React from 'react';

const SvgDevices48 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M22 14H2V2h20zm1-14H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h6v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V16h6a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices48 svgRef={ref} {...props} />);
export default ForwardRef;
