import React from 'react';

const SvgOther6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.5 10h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0-4h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zM2 17a1 1 0 0 1 1-1h11v2H3a1 1 0 0 1-1-1zM2 3a1 1 0 0 1 1-1h11v12H3a3 3 0 0 0-1 .17zm13-3H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12.5a.5.5 0 0 0 .5-.5V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther6 svgRef={ref} {...props} />);
export default ForwardRef;
