import React, { Component } from 'react';
import MoVeInDesktopHeader from '../../../../../common/components/MoVeInDesktopHeader';
import MoVeInDesktopFooter from "../../../../../common/components/MoVeInDesktopFooter";
import RegForm from '../RegForm';
import RegPerson from '../RegPerson';
import RegLegalPerson from '../RegLegalPerson';
import RegVehicle from '../RegVehicle';

import './DesktopReg.css';

export default class DesktopReg extends Component {
  render() {

    return (
      <div className="DesktopReg">
        <MoVeInDesktopHeader />
        <div className="container">
          <div className="form">
            <RegForm onClickReg={this.props.onClickReg} form={this.props.form} />
          </div>
          <div className="person">
            <RegPerson />
          </div>
          {this.props.withLegal ? (
            <div className="legalPerson">
              <RegLegalPerson />
            </div>
          ) : (
            <div />
          )}
          <div className="vehicle">
            <RegVehicle />
          </div>
        </div>
        <MoVeInDesktopFooter />
      </div>
    );
  }
}
