import React from 'react';

const SvgAlerts20 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.772 10.78a.25.25 0 0 1-.25.22h-1.055a.25.25 0 0 1-.249-.22L9.82 7.56a.501.501 0 0 1 .498-.56h1.354a.497.497 0 0 1 .498.56zM12 14.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM11.81.37a.76.76 0 0 0-.65-.37h-.3a.76.76 0 0 0-.65.37L.097 17.63a.79.79 0 0 0 0 .76l.14.23a.73.73 0 0 0 .65.38h20.225a.73.73 0 0 0 .65-.38l.14-.23a.79.79 0 0 0 0-.76z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts20 svgRef={ref} {...props} />);
export default ForwardRef;
