import React from 'react';

const SvgDevices58 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 6a5 5 0 1 1 0 10A5 5 0 0 1 7 6zm3.86-.84L10.14.84a1 1 0 0 0-1-.84H4.85a1 1 0 0 0-1 .84l-.71 4.32a7 7 0 0 0 0 11.68l.72 4.32a1 1 0 0 0 1 .84h4.3a1 1 0 0 0 1-.84l.72-4.32A7 7 0 0 0 14 11a7 7 0 0 0-3.14-5.84z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices58 svgRef={ref} {...props} />);
export default ForwardRef;
