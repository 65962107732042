import React from 'react';

const SvgActions45 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 17.5V14H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H5a1 1 0 0 1 1 1v4.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm9.5.5h-1a.5.5 0 0 1-.5-.5V13a1 1 0 0 1 1-1h4.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H14v3.5a.5.5 0 0 1-.5.5zM5 6H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 4H4V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V5a1 1 0 0 1-1 1zm7-1V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V4h3.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H13a1 1 0 0 1-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions45 svgRef={ref} {...props} />);
export default ForwardRef;
