import React from 'react';

const SvgOther28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM5 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM17.91 1.13A2 2 0 0 0 16.11 0H14.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.61L18 6.11a4.94 4.94 0 0 0-5.35 2.42A3 3 0 0 0 11 8a2.94 2.94 0 0 0-1.67.54A4.95 4.95 0 0 0 4 6.11L5.89 2H7.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H5.89a2 2 0 0 0-1.82 1.15L.47 8.89A4.93 4.93 0 0 0 0 11a5 5 0 1 0 10 0 1 1 0 1 1 2 0 5 5 0 1 0 10 0 4.93 4.93 0 0 0-.49-2.13z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther28 svgRef={ref} {...props} />);
export default ForwardRef;
