import React from 'react';

const SvgDevices53 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.5 4h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-4 0h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM3 2h10v7H3zm12 7V1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0-1 1v5.34a4 4 0 0 0 1.17 2.83l.66.66A4 4 0 0 0 4.66 20h6.68a4 4 0 0 0 2.83-1.17l.66-.66A4 4 0 0 0 16 15.34V10a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices53 svgRef={ref} {...props} />);
export default ForwardRef;
