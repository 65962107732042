import React from 'react';

const SvgOther66 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.013 5.595L3.01 7.594a.482.482 0 0 1-.702 0l-2.155-2.1a.48.48 0 0 1 0-.699L3.87 1.076c.25-.25.56-.428.902-.52l2.055-.55a.25.25 0 0 1 .29.15 3.8 3.8 0 0 0 2.907 1.85 3.8 3.8 0 0 0 2.907-1.84.25.25 0 0 1 .29-.16l2.005.54c.342.092.653.27.902.52l3.72 3.719a.48.48 0 0 1 0 .7l-2.126 2.119a.482.482 0 0 1-.702 0l-2.004-2V17c0 .552-.449 1-1.003 1H6.016a1.001 1.001 0 0 1-1.003-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther66 svgRef={ref} {...props} />);
export default ForwardRef;
