import React from 'react';

const SvgActions116 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.163 6.132a.489.489 0 0 0-.154.368v4.495l-3.163 3.157a.509.509 0 0 0 .06.76 5.013 5.013 0 0 0 3.954.998 4.998 4.998 0 0 0 4.232-5.096A5 5 0 0 0 9.54 6a.491.491 0 0 0-.377.132zM2.003 11c0-3.866 3.139-7 7.011-7 3.873 0 7.013 3.134 7.013 7s-3.14 7-7.013 7c-3.872 0-7.012-3.134-7.012-7zm14.014-5.63l1.131-1.13a.5.5 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.711 0L14.615 4a9.013 9.013 0 0 0-3.603-1.77V1a1 1 0 0 0-1.001-1H8.008a1 1 0 0 0-1 1v1.23A9.002 9.002 0 0 0 0 11c.055 4.848 3.9 8.803 8.75 9a9.01 9.01 0 0 0 8.31-4.996 8.992 8.992 0 0 0-1.043-9.634z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions116 svgRef={ref} {...props} />);
export default ForwardRef;
