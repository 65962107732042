import React from 'react';

const SvgDevices15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 9.68a.59.59 0 0 1-.07.26l-3 4.84a.44.44 0 0 1-.39.22H4.5a.48.48 0 0 1-.35-.16.47.47 0 0 1-.1-.36l.34-2.41H2.5a.5.5 0 0 1-.5-.5v-.16a.59.59 0 0 1 .07-.26l3-4.91A.51.51 0 0 1 5.52 6a.48.48 0 0 1 .35.16.47.47 0 0 1 .1.36L5.61 9H7.5a.5.5 0 0 1 .5.5zM8 2H7V1a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices15 svgRef={ref} {...props} />);
export default ForwardRef;
