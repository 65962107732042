import React from 'react';

const SvgDevices38 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm10-8a2 2 0 0 0-2-2h-1.17a2 2 0 0 1-1.42-.59L13.88.88A3 3 0 0 0 11.76 0H8.24a3 3 0 0 0-2.12.88L4.59 2.41A2 2 0 0 1 3.17 3H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices38 svgRef={ref} {...props} />);
export default ForwardRef;
