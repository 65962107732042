import React from 'react';

const SvgCommunication40 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.85 1.56L9.63 9.78a.75.75 0 0 1-.53.22h-.2a.75.75 0 0 1-.53-.22L2 3.42V7.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V1C0 .801.08.61.22.47L.49.2A.75.75 0 0 1 1 0h6.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3.41L9 7.59 16.44.15a.5.5 0 0 1 .71 0l.7.7a.5.5 0 0 1 0 .71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication40 svgRef={ref} {...props} />);
export default ForwardRef;
