import React from 'react';

const SvgUser7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19.852 13.523a1 1 0 0 1-.85.477h-3.17a3 3 0 0 0-.15-2.34L14.352 9h1.91a3 3 0 0 1 2.68 1.65l.951 1.9a1 1 0 0 1-.04.973zm-5.96-.973A1 1 0 0 1 13 14H.997a1 1 0 0 1-.89-1.45l1.44-2.89A3.001 3.001 0 0 1 4.238 8H9.76a3.001 3.001 0 0 1 2.69 1.66zM3.997 3A3 3 0 1 1 10 3a3 3 0 0 1-6 0zM14.5 2a2.5 2.5 0 1 1 .001 5 2.5 2.5 0 0 1 0-5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser7 svgRef={ref} {...props} />);
export default ForwardRef;
