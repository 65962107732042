import React from 'react';

const SvgArrows17 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.138 6.942a.5.5 0 0 1 0 .71l-.2.2a.49.49 0 0 1-.36.15h-3.58v7.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-7.5h-3.58a.49.49 0 0 1-.36-.15l-.2-.2a.5.5 0 0 1 0-.71l6.79-6.79a.48.48 0 0 1 .7 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows17 svgRef={ref} {...props} />);
export default ForwardRef;
