import React from 'react';

const SvgCommunication8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 21" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20.018 3.994v7.989a3.999 3.999 0 0 1-4.003 3.994H4.004a2 2 0 0 0 2.001 1.997h11.01c.8-.002 1.567.315 2.132.88l2.002 1.996a.491.491 0 0 0 .35.15.5.5 0 0 0 .501-.5V5.992a2 2 0 0 0-1.982-1.997zm-6.005 9.986a3.999 3.999 0 0 0 4.003-3.994V3.994A3.999 3.999 0 0 0 14.013 0H4.003A3.999 3.999 0 0 0 0 3.994v12.482a.5.5 0 0 0 .5.5.491.491 0 0 0 .35-.15l2.003-1.997a3.007 3.007 0 0 1 2.152-.849z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication8 svgRef={ref} {...props} />);
export default ForwardRef;
