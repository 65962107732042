import React from 'react';

const SvgAbstract46 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 9.24V17a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5V17a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9.24a3 3 0 0 1 .88-2.12L7.71.29a1 1 0 0 1 .7-.29h1.18a1 1 0 0 1 .7.29l6.83 6.83A3 3 0 0 1 18 9.24z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract46 svgRef={ref} {...props} />);
export default ForwardRef;
