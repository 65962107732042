import React from 'react';

const SvgPremium16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.37 9.12l-2 1.7.63 2.56a.5.5 0 0 1-.74.55L9 12.56l-2.25 1.37a.5.5 0 0 1-.75-.55l.61-2.54-2-1.7a.51.51 0 0 1 .29-.88l2.6-.2 1-2.43a.5.5 0 0 1 .92 0l1 2.43 2.6.2a.51.51 0 0 1 .35.88zm3.75-5L13.59.59A2 2 0 0 0 12.17 0H5.83a2 2 0 0 0-1.42.59L.88 4.12A3 3 0 0 0 0 6.24v5.85a8 8 0 0 0 3.09 6.31l4.08 3.18A2 2 0 0 0 8.4 22h1.2a2 2 0 0 0 1.23-.42l4.08-3.18A8 8 0 0 0 18 12.09V6.24a3 3 0 0 0-.88-2.12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium16 svgRef={ref} {...props} />);
export default ForwardRef;
