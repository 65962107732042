import React from 'react';

const SvgPayment31 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.704 10.29a1 1 0 0 1 0 1.42l-5.558 5.56c-.47.468-1.106.73-1.769.73H6.298a1 1 0 0 0-.7.29L4.18 19.71a1 1 0 0 1-1.41 0L.292 17.23a1 1 0 0 1 0-1.41l2.829-2.91A3.318 3.318 0 0 1 5.709 12h4.688a1.57 1.57 0 0 1 1.6 1.35 1.49 1.49 0 0 1-1.27 1.65H6.498a.5.5 0 0 0 0 1h3.999a2.5 2.5 0 0 0 2.499-2.41l3.329-3.3a1 1 0 0 1 1.42 0zm-4.958 2.13a2.48 2.48 0 0 0-.75-.92V8a1 1 0 1 1 2 0v3.17zM4 4a2 2 0 1 0 4 0 2 2 0 0 0-4 0zM2 4a4 4 0 1 1 8-.001A4 4 0 0 1 2 4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment31 svgRef={ref} {...props} />);
export default ForwardRef;
