import React from 'react';

const SvgActions22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M1 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1V2zm18 12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1v12zm-3 0V2a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions22 svgRef={ref} {...props} />);
export default ForwardRef;
