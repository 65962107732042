import React from 'react';

const SvgActions74 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 3v12a1 1 0 0 1-1 1h-1V2h1a1 1 0 0 1 1 1zm-4-1v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2zm-2 0H2v14h10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions74 svgRef={ref} {...props} />);
export default ForwardRef;
