import React from 'react';

const SvgOther36 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.598 11.52a5 5 0 0 1-6.35.64l3.6-3.6a.5.5 0 0 0 0-.71l-.7-.7a.5.5 0 0 0-.71 0l-3.6 3.6a5 5 0 0 1 .64-6.35c2.08-2.09 5.79-2.4 7.82-2.4.55 0 1.1 0 1.64.06.14 2.01.2 6.94-2.34 9.46zm4.29-10.08a1 1 0 0 0-.29-.61l-.43-.44a1 1 0 0 0-.61-.28 22.38 22.38 0 0 0-2.26-.11c-2.68 0-6.72.46-9.24 3a7 7 0 0 0-.65 9.2l-4.26 4.24a.5.5 0 0 0 0 .71l.7.7a.5.5 0 0 0 .71 0l4.27-4.26a7 7 0 0 0 9.17-.65c3.27-3.25 3.13-9.02 2.89-11.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther36 svgRef={ref} {...props} />);
export default ForwardRef;
