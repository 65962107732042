import React from 'react';

const SvgAbstract43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 16v-5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v5H4V9.5a.5.5 0 0 0-.5-.5H2.05a.89.89 0 0 1 .24-.46L8.83 2h2.34l6.54 6.54A.89.89 0 0 1 18 9h-1.5a.5.5 0 0 0-.5.5V16zM0 10.5a.5.5 0 0 0 .5.5H2v6a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-4.75a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25V17a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-6h1.5a.5.5 0 0 0 .5-.5V8.41a1 1 0 0 0-.29-.7L12.29.29a1 1 0 0 0-.7-.29H8.41a1 1 0 0 0-.7.29L.29 7.71a1 1 0 0 0-.29.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract43 svgRef={ref} {...props} />);
export default ForwardRef;
