import React from 'react';

const SvgOther56 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.06 15.94a4.977 4.977 0 0 1-4.972-4.983A4.977 4.977 0 0 1 7.06 5.975a4.977 4.977 0 0 1 4.972 4.982A4.977 4.977 0 0 1 7.06 15.94zM17.849.6L17.4.15a.487.487 0 0 0-.397-.149h-4.475a.497.497 0 0 0-.497.498v.995c0 .275.222.498.497.498h2.068l-3.38 3.375a7.005 7.005 0 0 0-9.503 1.016 7.024 7.024 0 0 0 .34 9.56 7.005 7.005 0 0 0 9.55.34 7.023 7.023 0 0 0 1.014-9.513l3.391-3.374v2.08c0 .275.223.498.497.498h.995a.497.497 0 0 0 .497-.498V.997a.488.488 0 0 0-.15-.398z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther56 svgRef={ref} {...props} />);
export default ForwardRef;
