import React from 'react';

const SvgDevices19 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.29 4.71l-2.13 2.13a.51.51 0 0 1-.68 0 4.12 4.12 0 0 0-2-.84.5.5 0 0 1-.48-.49v-3a.5.5 0 0 1 .52-.5 8 8 0 0 1 4.75 2 .5.5 0 0 1 .02.7zM10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 5.47a.5.5 0 0 1-.52.5A8 8 0 0 1 4.73 16a.5.5 0 0 1 0-.73l2.13-2.13a.51.51 0 0 1 .68 0 4.12 4.12 0 0 0 2 .84.5.5 0 0 1 .44.51zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices19 svgRef={ref} {...props} />);
export default ForwardRef;
