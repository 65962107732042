import React from 'react';

const SvgAbstract54 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 8V6a4 4 0 1 0-8 0v2zm2-2v2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2V6a6 6 0 1 1 12 0zM2 10v8h12v-8z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract54 svgRef={ref} {...props} />);
export default ForwardRef;
