import React from 'react';

const SvgCommunication33 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 4v8a4 4 0 0 1-4 4H6a3 3 0 0 0-2.15.85l-3 3A.49.49 0 0 1 .5 20a.5.5 0 0 1-.5-.5V4a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4zm-4-2H4a2 2 0 0 0-2 2v11.88l.46-.42A5 5 0 0 1 6 14h10a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication33 svgRef={ref} {...props} />);
export default ForwardRef;
