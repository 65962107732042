import React from 'react';

const SvgArrows16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.86 6.942L7.65.152a.48.48 0 0 1 .7 0l6.79 6.79a.49.49 0 0 1 .15.35.51.51 0 0 1-.15.36l-.2.2a.49.49 0 0 1-.36.15H11v7.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-7.5H1.42a.49.49 0 0 1-.36-.15l-.2-.2a.51.51 0 0 1-.15-.36.49.49 0 0 1 .15-.35zM7 14.002h2v-7.5a.5.5 0 0 1 .5-.5h1.88L8 2.622l-3.38 3.38H6.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows16 svgRef={ref} {...props} />);
export default ForwardRef;
