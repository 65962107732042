import React from 'react';

const SvgArrows5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.99 8a.5.5 0 0 1 .35.85l-2.995 3a.509.509 0 0 1-.71 0l-2.995-3A.5.5 0 0 1 7 8zm4.081-5.071A10 10 0 0 1 20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0a10 10 0 0 1 7.071 2.929zM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows5 svgRef={ref} {...props} />);
export default ForwardRef;
