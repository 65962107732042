import React from 'react';

const SvgPayment27 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 2H2v14.84l.68.68 1.23-1.23a1 1 0 0 1 .7-.29h1.45a1 1 0 0 1 .7.29L8 17.53l1.29-1.24A1 1 0 0 1 10 16h1.4a1 1 0 0 1 .7.29l.5.5.74.73.66-.66zm2-1v16.3a1 1 0 0 1-.29.7l-1.91 1.81a.47.47 0 0 1-.35.15h-.29a.47.47 0 0 1-.35-.15L11 18a.51.51 0 0 0-.33-.15.5.5 0 0 0-.35.15L8.5 19.81a.47.47 0 0 1-.35.15h-.29a.47.47 0 0 1-.35-.15L5.7 18a.51.51 0 0 0-.35-.15A.5.5 0 0 0 5 18l-1.83 1.85a.47.47 0 0 1-.35.15h-.29a.47.47 0 0 1-.35-.15L.29 17.97a1.05 1.05 0 0 1-.29-.71V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1zm-4 6.5a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5zm-3 4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment27 svgRef={ref} {...props} />);
export default ForwardRef;
