import React from 'react';

const SvgActions44 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5 12h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1zm11 5H2V6h14zm2-13a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions44 svgRef={ref} {...props} />);
export default ForwardRef;
