import React from 'react';

const SvgTransport9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 10H2V4h12zm-8 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9.27-12.73l-.54-.54A2.49 2.49 0 0 0 13 0H3a2.49 2.49 0 0 0-1.77.73l-.54.54A2.49 2.49 0 0 0 0 3v12c-.002.664.26 1.3.73 1.77l.54.54A2.49 2.49 0 0 0 3 18h1l-1 1.62a.26.26 0 0 0 .22.38h1.47a.73.73 0 0 0 .65-.38L6.28 18h3.44l.94 1.62a.73.73 0 0 0 .65.38h1.44a.26.26 0 0 0 .22-.38L12 18h1a2.49 2.49 0 0 0 1.77-.73l.54-.54A2.49 2.49 0 0 0 16 15V3a2.49 2.49 0 0 0-.73-1.73z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport9 svgRef={ref} {...props} />);
export default ForwardRef;
