import React from 'react';

const SvgOther8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 12.5v3a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zm-11 0v3a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zm11-6v3a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5zM5.5 6h9a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5zM0 9.5v-3A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm20-9v3a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zm-20 3v-3A.5.5 0 0 1 .5 0h8a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther8 svgRef={ref} {...props} />);
export default ForwardRef;
