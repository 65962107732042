import React from 'react';

const SvgActions101 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.992 11.38l1.117 4.68L10 13.55V3.41l1.855 4.46 4.797.38zm6.901-4.31a.489.489 0 0 0-.439-.35l-6.372-.51L10.599.32a.489.489 0 0 0-.47-.32h-.258a.509.509 0 0 0-.48.32L6.95 6.21l-6.373.51a.489.489 0 0 0-.439.35l-.11.25a.49.49 0 0 0 .15.54L5.014 12l-1.436 6.22a.51.51 0 0 0 .19.53l.249.15c.085.067.19.102.299.1a.478.478 0 0 0 .26-.07L10 15.6l5.455 3.33a.478.478 0 0 0 .26.07.468.468 0 0 0 .299-.1l.219-.15a.51.51 0 0 0 .19-.53L14.986 12l4.847-4.15a.49.49 0 0 0 .14-.53z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions101 svgRef={ref} {...props} />);
export default ForwardRef;
