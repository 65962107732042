import React from 'react';

const SvgActions20 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 17H2V6h14zm0-15h-1V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions20 svgRef={ref} {...props} />);
export default ForwardRef;
