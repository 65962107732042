import React from 'react';

const SvgActions65 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 11 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.498 12h1a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5zM.07 1.205a.5.5 0 0 0 .148.355L4.668 6l-4.45 4.44a.49.49 0 0 0 0 .7l.71.71a.5.5 0 0 0 .71 0l5.14-5.13a.77.77 0 0 0 .22-.53v-.38a.75.75 0 0 0-.22-.53L1.638.15a.5.5 0 0 0-.71 0l-.71.7a.5.5 0 0 0-.148.355z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions65 svgRef={ref} {...props} />);
export default ForwardRef;
