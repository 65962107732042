import React from 'react';

const SvgActions50 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 19" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.2 14L10 16.17 7.8 14C5.77 12 2 8.26 2 5.5A3.5 3.5 0 0 1 5.5 2a5 5 0 0 1 3.71 2.21.77.77 0 0 0 .6.29h.45a.51.51 0 0 0 .4-.2A5.11 5.11 0 0 1 14.47 2 3.5 3.5 0 0 1 18 5.5c0 2.76-3.77 6.5-5.8 8.5zm2.3-14A6.36 6.36 0 0 0 10 2.07 6.36 6.36 0 0 0 5.5 0 5.5 5.5 0 0 0 0 5.5C0 9.42 4.75 13.75 7 16l2.28 2.28c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22L13 16c2.25-2.25 7-6.58 7-10.5A5.5 5.5 0 0 0 14.5 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions50 svgRef={ref} {...props} />);
export default ForwardRef;
