import React from 'react';

const SvgOther54 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 24" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2.462 16.644h.18l4.92.871a1 1 0 0 1 .81 1.162l-.43 2.463a3.5 3.5 0 0 1-6.94-.61 4.082 4.082 0 0 1 .03-.612l.44-2.463a1 1 0 0 1 1-.831zm.789 2.143l-.25 1.482a1.568 1.568 0 0 0 0 .26 1.51 1.51 0 0 0 .636 1.366c.44.309 1.01.359 1.496.13.486-.228.813-.699.86-1.235l.279-1.482-2.991-.521zm6.75-10.776a18.22 18.22 0 0 1-.75 5.308 1 1 0 0 1-.95.701h-6.25a1 1 0 0 1-.88-.54A12.383 12.383 0 0 1 .002 8.01c0-3.004 1-8.011 5-8.011s5 5.007 5 8.011zm-5-6.008c-.65 0-1.49.2-2.19 1.724a10.9 10.9 0 0 0-.81 4.29 11.171 11.171 0 0 0 .69 4.01h4.85c.308-1.314.463-2.66.46-4.01a10.9 10.9 0 0 0-.81-4.29c-.7-1.524-1.54-1.724-2.19-1.724z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther54 svgRef={ref} {...props} />);
export default ForwardRef;
