import React from 'react';

const SvgActions52 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.88 7A2.88 2.88 0 0 0 11 4.12H9A2.88 2.88 0 0 0 6.12 7v.5a.5.5 0 0 0 .5.5h.76a.5.5 0 0 0 .5-.5V7A1.12 1.12 0 0 1 9 5.88h2c.619 0 1.12.501 1.12 1.12v.56a1.13 1.13 0 0 1-.76 1.07l-.95.31a1.88 1.88 0 0 0-1.29 1.78v.78a.5.5 0 0 0 .5.5h.76a.5.5 0 0 0 .5-.5v-.78a.13.13 0 0 1 .12-.12l.95-.31a2.89 2.89 0 0 0 2-2.73zm-3.38 7h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-.5 4a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions52 svgRef={ref} {...props} />);
export default ForwardRef;
