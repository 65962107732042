import React from 'react';

const SvgAlerts12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20.001 7.47a.55.55 0 0 1-.15.37.5.5 0 0 1-.36.16h-1a.5.5 0 0 1-.49-.48 8.12 8.12 0 0 0-1.64-4.31.36.36 0 0 1 .06-.5l1-.78a.44.44 0 0 1 .31-.09.38.38 0 0 1 .27.16 10.13 10.13 0 0 1 2 5.47zM3.591 3.21a8.12 8.12 0 0 0-1.59 4.31.51.51 0 0 1-.5.48h-1a.48.48 0 0 1-.36-.16.47.47 0 0 1-.14-.37 10.05 10.05 0 0 1 2-5.47.37.37 0 0 1 .23-.16.4.4 0 0 1 .3.09l1 .78a.36.36 0 0 1 .06.5zm14.12 10.5a1 1 0 0 1 .29.7V15a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-.59a1 1 0 0 1 .29-.7l.83-.83a3 3 0 0 0 .88-2.12V8a6 6 0 0 1 1.2-3.6l.9-1.2a3 3 0 0 1 2.4-1.2h.5V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V2h.5a3 3 0 0 1 2.4 1.2l.9 1.2a6 6 0 0 1 1.2 3.6v2.76a3 3 0 0 0 .88 2.12zM8.001 18h4a2 2 0 1 1-4 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts12 svgRef={ref} {...props} />);
export default ForwardRef;
