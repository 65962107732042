import React from 'react';

const SvgPremium9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16.833 3.137c.427.665.746 1.394.944 2.16m-6.79 6.75a4.933 4.933 0 0 1-2.694-.803 51.413 51.413 0 0 0 3.955-1.266 55.422 55.422 0 0 0 3.474-1.396 5.008 5.008 0 0 1-4.736 3.466zm-6.96-4.208c.021.227.058.452.11.673.673-.076 1.342-.19 2.003-.341a5.423 5.423 0 0 1-.16-1.146 5.016 5.016 0 0 1 4.626-4.877 5.01 5.01 0 0 1 5.305 4.124A47.122 47.122 0 0 1 11.566 8.1 43.168 43.168 0 0 1 6.68 9.586c-.68.166-1.368.293-2.062.382-2.794.372-3.574-.351-.59-2.13zm17.85-5.264c-.35-.955-1.36-1.578-3.313-1.578-1.127.026-2.248.17-3.344.432a6.988 6.988 0 0 0-6.644-1 7.025 7.025 0 0 0-4.429 5.07C.564 7.425-.407 9.103.144 10.47c.63 1.718 3.093 1.799 5.766 1.327a6.991 6.991 0 0 0 7.446 1.795 7.03 7.03 0 0 0 4.638-6.115c3.434-1.9 4.375-3.557 3.885-4.903z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium9 svgRef={ref} {...props} />);
export default ForwardRef;
