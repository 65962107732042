import React from 'react';

const SvgActions5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 6.5v1a.5.5 0 0 1-.5.5H8v5.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6H6V.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V6h5.5a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions5 svgRef={ref} {...props} />);
export default ForwardRef;
