import React from 'react';

const SvgFiles25 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.5 13h-7a.5.5 0 0 1-.45-.72l.73-1.45A1.5 1.5 0 0 1 5.12 10h3.76a1.5 1.5 0 0 1 1.34.83l.78 1.45a.5.5 0 0 1-.5.72zM7 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm11-3h-5.17a2 2 0 0 1-1.42-.59l-.82-.82A2 2 0 0 0 9.17 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles25 svgRef={ref} {...props} />);
export default ForwardRef;
