import React from 'react';

const SvgActions73 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.78.5l-.27-.27A.75.75 0 0 0 17 0h-6.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h4.09l-8.44 8.44a.5.5 0 0 0 0 .71l.7.7a.5.5 0 0 0 .71 0L16 3.42V7.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V1a.75.75 0 0 0-.22-.51zM17.5 10h-1a.5.5 0 0 0-.5.5V16H2V2h5.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5.5a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions73 svgRef={ref} {...props} />);
export default ForwardRef;
