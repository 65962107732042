import React from 'react';

const SvgUser6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 14a1 1 0 0 1-.89-1.45l1-1.9A3.001 3.001 0 0 1 12.74 9h3.522a3 3 0 0 1 2.68 1.65l.951 1.9a1 1 0 0 1-.89 1.45zm-.24-6a4.91 4.91 0 0 0-1.49 1.76l-.95 1.89A3 3 0 0 0 7.18 14H.996a1 1 0 0 1-.89-1.45l1.44-2.89A3.001 3.001 0 0 1 4.238 8zM3.998 3A3 3 0 1 1 10 3a3 3 0 0 1-6 0zm8.732 3.27A2.49 2.49 0 0 1 12 4.5 2.5 2.5 0 1 1 14.502 7a2.491 2.491 0 0 1-1.77-.73z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser6 svgRef={ref} {...props} />);
export default ForwardRef;
