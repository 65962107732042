import React from 'react';

const SvgActions31 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.91 11.56l.53.53a.48.48 0 0 0 .7 0L9 10.24l1.86 1.85a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7L10.24 9l1.85-1.86a.48.48 0 0 0 0-.7l-.53-.53a.48.48 0 0 0-.7 0L9 7.76 7.14 5.91a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7L7.76 9l-1.85 1.86a.48.48 0 0 0 0 .7zM16 16H2V2h14zm0-16H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions31 svgRef={ref} {...props} />);
export default ForwardRef;
