import React from 'react';

const SvgActions54 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 21 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.378 4h-.76a.5.5 0 0 0-.5.5v4.66a.53.53 0 0 0 .15.35l3.29 3.29a.5.5 0 0 0 .71 0l.53-.53a.5.5 0 0 0 0-.71l-2.92-2.92V4.5a.5.5 0 0 0-.5-.5zm8.62 5a9 9 0 0 0-18 0H.708a.49.49 0 0 0-.36.15l-.2.2a.5.5 0 0 0 0 .71l3.53 3.54a.51.51 0 0 0 .71 0l3.53-3.54a.5.5 0 0 0 0-.71l-.2-.2a.49.49 0 0 0-.36-.15h-2.36a7 7 0 1 1 7 7 6.91 6.91 0 0 1-3.88-1.18.5.5 0 0 0-.63.06l-.72.73a.5.5 0 0 0 .07.76 8.89 8.89 0 0 0 5.16 1.63 9 9 0 0 0 9-9z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions54 svgRef={ref} {...props} />);
export default ForwardRef;
