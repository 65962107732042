import React from 'react';

const SvgOther26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 11 24" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.27 9.73A2.5 2.5 0 0 1 10 11.5c0 1.95-2 5.78-2 9.5a3 3 0 1 1-6 0c0-3.5 4-5.19 4-8a2 2 0 0 0-2-2H2.5a2.5 2.5 0 0 1 0-5h1.38a4 4 0 0 1 2.83 1.17zM0 3V2a2 2 0 1 1 4 0v1a2 2 0 1 1-4 0zm11 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM5 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther26 svgRef={ref} {...props} />);
export default ForwardRef;
