import React from 'react';

const SvgOther50 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 14h-1a1 1 0 0 0-1 1v3a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-1a2 2 0 1 0-4 0v1a1 1 0 0 1-1 1H2a2 2 0 0 1-2-2v-3a1 1 0 0 1 1-1h1a2 2 0 1 0 0-4H1a1 1 0 0 1-1-1V6a2 2 0 0 1 2-2h3a1 1 0 0 0 1-1V2a2 2 0 1 1 4 0v1a1 1 0 0 0 1 1h3a2 2 0 0 1 2 2v3a1 1 0 0 0 1 1h1a2 2 0 1 1 0 4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther50 svgRef={ref} {...props} />);
export default ForwardRef;
