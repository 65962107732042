import { observable, action } from 'mobx';

class AuthStore {
  @observable
  token;
  @observable
  user;

  @action
  setToken(token) {
    this.token = `Bearer ${token}`;
  }

  @action
  setUser(user) {
    this.user = user;
  }

}

export default new AuthStore();
