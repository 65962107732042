import React from 'react';

const SvgOther65 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.85 8.86a.51.51 0 0 0 .71 0l5.17-5.17 1.42 1.42A.5.5 0 0 0 9 4.75V.5a.52.52 0 0 0-.5-.5H4.25a.5.5 0 0 0-.35.85l1.42 1.43L.15 7.44a.5.5 0 0 0 0 .71zM19.5 2H16a1 1 0 0 0-1 1v3h-3a1 1 0 0 0-1 1v3H8a1 1 0 0 0-1 1v3H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H8a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1V8h3a1 1 0 0 0 1-1V4h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther65 svgRef={ref} {...props} />);
export default ForwardRef;
