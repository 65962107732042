import React from 'react';

const SvgOther46 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11 6.33v-.321a2 2 0 0 1 1.997-2.003h1.18A2.997 2.997 0 0 0 20 3.005a2.997 2.997 0 1 0-5.824-1.001h-1.179a4 4 0 0 0-3.936 3.374c.71.164 1.373.49 1.938.951zm8.992 6.689a2.997 2.997 0 1 0-5.815-1.001h-1.18A2 2 0 0 1 11 10.013a3.008 3.008 0 0 0-.78-2.002A2.994 2.994 0 0 0 8.003 7.01H5.824A2.997 2.997 0 0 0 0 8.012a2.997 2.997 0 1 0 5.824 1.001h2.178A1 1 0 0 1 9 10.014a4.001 4.001 0 0 0 3.996 4.006h1.18a2.997 2.997 0 0 0 5.815-1.001z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther46 svgRef={ref} {...props} />);
export default ForwardRef;
