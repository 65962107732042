import React from 'react';

const SvgOther39 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.003 18.59a1 1 0 0 0 .29.7l.42.42a1 1 0 0 0 .7.29h1.18a1 1 0 0 0 .7-.29l.42-.42a1 1 0 0 0 .29-.7V18h-4zM8.293 13h-2.58a4.08 4.08 0 0 0-1.51-1.86 5 5 0 0 1-2.2-4.33A5.12 5.12 0 0 1 6.913 2h.09a5 5 0 0 1 2.79 9.14 4 4 0 0 0-1.5 1.86zM7.003 0h-.12a7.1 7.1 0 0 0-6.88 6.74 7 7 0 0 0 3.08 6 2 2 0 0 1 .92 1.74V15a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-.52a2 2 0 0 1 .91-1.68 7 7 0 0 0 3.09-5.8 7 7 0 0 0-7-7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther39 svgRef={ref} {...props} />);
export default ForwardRef;
