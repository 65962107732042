import React from 'react';

const SvgActions100 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 17" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.854.146A.5.5 0 0 1 18 .5v1a.5.5 0 0 1-.5.5H7a5 5 0 0 0 0 10v-2a.5.5 0 0 1 .85-.36l3 3a.51.51 0 0 1 0 .71l-3 3A.5.5 0 0 1 7 16v-2A7 7 0 0 1 7 0h10.5a.5.5 0 0 1 .354.146zM18 12.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5zm0-6v1a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions100 svgRef={ref} {...props} />);
export default ForwardRef;
