import React from 'react';

const SvgActions24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 19a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-1H2zM14 1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1h12zm0 3H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions24 svgRef={ref} {...props} />);
export default ForwardRef;
