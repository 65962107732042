import React from 'react';

const SvgArrows44 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.5 0a.5.5 0 0 0-.5.5V11a2 2 0 0 0 2 2h12.67l-3.45 3.44a.5.5 0 0 0 0 .71l.71.7a.5.5 0 0 0 .71 0l5.14-5.13a.75.75 0 0 0 .22-.53v-.38a.77.77 0 0 0-.22-.53l-5.14-5.13a.5.5 0 0 0-.71 0l-.71.71a.49.49 0 0 0 0 .7L14.67 11H2V.5a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows44 svgRef={ref} {...props} />);
export default ForwardRef;
