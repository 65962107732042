import React from 'react';

const SvgPayment23 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-4 9a4 4 0 0 0-4-4V6a4 4 0 0 0 4-4h8a4 4 0 0 0 4 4v4a4 4 0 0 0-4 4zM19 0H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment23 svgRef={ref} {...props} />);
export default ForwardRef;
