import React from 'react';

const SvgUser12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 21" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.892 15.104a.517.517 0 0 1 .177.372v3.884a.49.49 0 0 1-.85.35l-1-.999a7.007 7.007 0 0 1-6.947 2.063A6.994 6.994 0 0 1 .22 15.586a.529.529 0 0 1 .1-.42.49.49 0 0 1 .39-.189h1a.5.5 0 0 1 .48.32 5.005 5.005 0 0 0 8.53 1.997l-1.46-1.468a.499.499 0 0 1 .35-.849h3.89a.52.52 0 0 1 .393.127zm-12.742-2.6a.996.996 0 0 1-.04-.972l.95-1.897a3.003 3.003 0 0 1 2.682-1.647h4.524c1.134 0 2.172.637 2.682 1.647l.951 1.897a.996.996 0 0 1-.89 1.448H2a1.001 1.001 0 0 1-.85-.476zM4 2.995A2.998 2.998 0 0 1 7 0c1.656 0 3 1.341 3 2.995a2.998 2.998 0 0 1-3 2.996c-1.658 0-3-1.341-3-2.996z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser12 svgRef={ref} {...props} />);
export default ForwardRef;
