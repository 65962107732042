import React from 'react';

const SvgDevices35 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 10.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm0-6.41V3h.5A1.5 1.5 0 0 0 9 1.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V1h-.5A1.5 1.5 0 0 0 5 2.5v1.59A6 6 0 0 0 0 10v4a6 6 0 1 0 12 0v-4a6 6 0 0 0-5-5.91z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices35 svgRef={ref} {...props} />);
export default ForwardRef;
