import React from 'react';

const SvgAlerts18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.772 10.78a.25.25 0 0 1-.25.22H8.468a.25.25 0 0 1-.249-.22L7.82 7.56A.501.501 0 0 1 8.318 7h1.354a.497.497 0 0 1 .498.56zM10 14.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm7.12-10.38L13.59.59A2 2 0 0 0 12.17 0H5.83a2 2 0 0 0-1.42.59L.88 4.12A3 3 0 0 0 0 6.24v5.85a8 8 0 0 0 3.09 6.31l4.08 3.18A2 2 0 0 0 8.4 22h1.2a2 2 0 0 0 1.23-.42l4.08-3.18A8 8 0 0 0 18 12.09V6.24a3 3 0 0 0-.88-2.12z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts18 svgRef={ref} {...props} />);
export default ForwardRef;
