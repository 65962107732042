import React from 'react';

const SvgFiles7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11 12.62a.5.5 0 0 0-.5-.5H8.88V10.5a.5.5 0 0 0-.5-.5h-.76a.5.5 0 0 0-.5.5v1.62H5.5a.5.5 0 0 0-.5.5v.76a.5.5 0 0 0 .5.5h1.62v1.62a.5.5 0 0 0 .5.5h.76a.5.5 0 0 0 .5-.5v-1.62h1.62a.5.5 0 0 0 .5-.5zM14 18H2V8h5a1 1 0 0 0 1-1V2h6zm0-18H7.24a3 3 0 0 0-2.12.88L.88 5.12A3 3 0 0 0 0 7.24V18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles7 svgRef={ref} {...props} />);
export default ForwardRef;
