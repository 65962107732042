import React from 'react';

const SvgCommunication35 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.17 11.22A.75.75 0 0 1 8 10.6V5.4a.75.75 0 0 1 1.17-.62l3.89 2.6a.74.74 0 0 1 0 1.24zM16 0H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication35 svgRef={ref} {...props} />);
export default ForwardRef;
