import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import Tailor from 'react-tailor';
import { AccountSquareIcon } from '../../../../../common/icons';

import './SupportTile.css';

@inject('translationsStore', 'moveinStore')
@observer
export default class SupportTile extends Component {
  render() {
    const { translationsStore, moveinStore } = this.props;
    const cls = classNames({
      SupportTile: true
    });
    return (
      <div className={cls}>
        <div
          style={{
            backgroundColor: this.props.themeColor ? this.props.themeColor : 'var(--action-custom-color)',
            color: 'white',
            borderRadius: 8,
            width: 48,
            height: 48,
            marginRight: 8
          }}
        >
          {this.props.icon}
        </div>
        <div style={{cursor: this.props.onClick ? 'pointer': 'auto'}} onClick={this.props.onClick}>
          {this.props.children ? (
            this.props.children
          ) : (
            <Fragment>
              {
                Array.isArray(this.props.title) ? this.props.title.map((elm, index) => <div key={index} style={{fontWeight: 'bold'}}>{elm}</div>) : <div>{this.props.title}</div>
              }
              {this.props.description && <div>{this.props.description}</div>}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
