import React from 'react';

const SvgActions4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 9.5a.5.5 0 0 1-.5.5H10v2.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V10H5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5H8V5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V8h2.5a.5.5 0 0 1 .5.5zM16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions4 svgRef={ref} {...props} />);
export default ForwardRef;
