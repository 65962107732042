import React from 'react';

const SvgOther11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 4H7V2h6zm-1 7a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zm6-7h-3V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther11 svgRef={ref} {...props} />);
export default ForwardRef;
