import React from 'react';

const SvgDevices45 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 11a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-5 2a5 5 0 1 1 10 0 5 5 0 0 1-10 0zM7 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm5-2H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices45 svgRef={ref} {...props} />);
export default ForwardRef;
