import React from 'react';

const SvgOther30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.62 14.18A1 1 0 0 1 11 15v1H3v-1a1 1 0 0 1 .38-.78L7 11.28zM11 2v3H3V2zm2 3V2h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1v3a3 3 0 0 0 1.13 2.38L5.4 10l-3.27 2.62A3 3 0 0 0 1 15v3H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H13v-3a3 3 0 0 0-1.13-2.34L8.6 10l3.27-2.62A3 3 0 0 0 13 5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther30 svgRef={ref} {...props} />);
export default ForwardRef;
