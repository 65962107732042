import React from 'react';

const SvgOther7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 17a1 1 0 0 1 1-1h11v2H3a1 1 0 0 1-1-1zm1-8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5zM15 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12.5a.5.5 0 0 0 .5-.5V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther7 svgRef={ref} {...props} />);
export default ForwardRef;
