import React from 'react';

const SvgCommunication12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 10.6a.75.75 0 0 0 1.17.62l3.89-2.6a.74.74 0 0 0 0-1.24l-3.89-2.6A.75.75 0 0 0 8 5.4zM18 14h-5.09a3 3 0 0 0-2.12.88l-.79.79-.79-.79A3 3 0 0 0 7.09 14H2V2h16zm2-12a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5.09a1 1 0 0 1 .7.29l1.49 1.49c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22l1.49-1.49a1 1 0 0 1 .7-.29H18a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication12 svgRef={ref} {...props} />);
export default ForwardRef;
