import React from 'react';

const SvgAbstract15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 8.5V16a6 6 0 1 1-12 0V4.5a4.5 4.5 0 0 1 9 0V15a3 3 0 0 1-6 0V8.5a.5.5 0 0 1 .5-.5h.75a.5.5 0 0 1 .5.5V15a1.25 1.25 0 1 0 2.5 0V4.5a2.75 2.75 0 0 0-5.5 0V16a4.25 4.25 0 0 0 8.5 0V8.5a.5.5 0 0 1 .5-.5h.75a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract15 svgRef={ref} {...props} />);
export default ForwardRef;
