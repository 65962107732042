import React from 'react';

const SvgFiles5 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 4v14a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h9a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zM7 11v5H2V2h11v8H8a1 1 0 0 0-1 1zm2.88 6.12l4.24-4.24a3 3 0 0 0 .88-2.12V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h5.76a3 3 0 0 0 2.12-.88z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles5 svgRef={ref} {...props} />);
export default ForwardRef;
