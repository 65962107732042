import React from 'react';

const SvgTransport10 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5V2h6zM16 14a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zM5 14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zM3.27 5.93a.25.25 0 0 1 .24-.18h11a.25.25 0 0 1 .24.18L15.89 10H2.11zM18 11.56c0-.372-.05-.742-.15-1.1l-1.44-5A2 2 0 0 0 14.49 4h-11a2 2 0 0 0-1.9 1.45l-1.44 5c-.1.361-.15.735-.15 1.11V19a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport10 svgRef={ref} {...props} />);
export default ForwardRef;
