import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LabelInput from '../../../../../common/components/LabelInput';
import { AccountSquareOutlinedIcon } from '../../../../../common/icons';
import SmallDivision from '../../../../../common/components/SmallDivision';

import './RegPerson.css';

@inject('translationsStore', 'moveinStore')
@observer
export default class RegPerson extends Component {
  render() {
    const { translationsStore, moveinStore } = this.props;
    return (
      <div className="RegPerson">
        <div className="header">
          <SmallDivision
            title={translationsStore.translate('registration.personalData.title')}
            icon={<AccountSquareOutlinedIcon />}
            style={{ marginTop: '24px', marginBottom: '24px' }}
          />
        </div>
        <div className="personalContent">
          <LabelInput label={translationsStore.translate('registration.personalData.name')} value={moveinStore.user.first_name} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.personalData.surname')} value={moveinStore.user.last_name} disabled={true} />
          <LabelInput label={translationsStore.translate('registration.personalData.taxNumber')} value={moveinStore.user.tax_code} disabled={true} />
          <span className="masonryGhostSeparator" />
          <span className="masonryGhostSeparator" />
        </div>
      </div>
    );
  }
}
