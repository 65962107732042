import React from 'react';

const SvgActions14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19 2h-1v16h1a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-3 0a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v17a1 1 0 0 0 1.53.85l5.94-3.72a1 1 0 0 1 1.06 0l5.94 3.72A1 1 0 0 0 16 19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions14 svgRef={ref} {...props} />);
export default ForwardRef;
