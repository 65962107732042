import React from 'react';

const SvgPremium7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.07 6a.25.25 0 0 1-.19-.42l3.065-3.38a.25.25 0 0 1 .37 0l3.115 3.38a.25.25 0 0 1-.18.42zm-8.54.47A.253.253 0 1 1 2.72 6h3.555c.452-.022.862.26 1.002.69l2.393 7.55a.266.266 0 1 1-.48.23zm17.21-1.31L15.59.65A2 2 0 0 0 14.12 0H5.88a2 2 0 0 0-1.47.65L.26 5.16a1 1 0 0 0-.26.68v.3a1 1 0 0 0 .23.64l9 10.86a1 1 0 0 0 1.54 0l9-10.86a1 1 0 0 0 .23-.64v-.3a1 1 0 0 0-.26-.68z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium7 svgRef={ref} {...props} />);
export default ForwardRef;
