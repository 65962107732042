import React from 'react';

const SvgAbstract70 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M10 13.016c-1.657 0-3-1.348-3-3.011a3.006 3.006 0 0 1 3-3.011c1.657 0 3 1.348 3 3.011 0 .799-.316 1.564-.878 2.13a2.994 2.994 0 0 1-2.122.881zm9-.28l-.8-.662a2.006 2.006 0 0 1-.72-1.643V9.57a2.006 2.006 0 0 1 .72-1.644l.8-.661c.38-.309.481-.847.24-1.273l-1.16-2.004a.999.999 0 0 0-1.21-.43l-1 .36c-.59.22-1.25.15-1.78-.19a5.477 5.477 0 0 0-.74-.431 2.004 2.004 0 0 1-1.06-1.453L12.12.842a1 1 0 0 0-1-.842h-2.3a1 1 0 0 0-1 .842l-.17 1.002a2.004 2.004 0 0 1-1.02 1.453 5.477 5.477 0 0 0-.74.43c-.53.341-1.19.412-1.78.19l-1-.36a.999.999 0 0 0-1.19.431L.76 5.992c-.24.426-.14.964.24 1.273l.8.66c.485.404.752 1.013.72 1.644v.861a2.006 2.006 0 0 1-.72 1.644l-.8.661c-.38.309-.48.847-.24 1.273l1.16 2.004c.246.418.756.6 1.21.43l1-.36c.59-.22 1.25-.15 1.78.19.235.163.483.307.74.431.563.29.956.828 1.06 1.453l.17 1.002a1 1 0 0 0 1 .842h2.3a1 1 0 0 0 1-.842l.17-1.002a2.004 2.004 0 0 1 1.06-1.453c.258-.124.505-.268.74-.43a1.993 1.993 0 0 1 1.78-.19l1 .36a1 1 0 0 0 1.15-.431l1.16-2.004c.241-.426.14-.964-.24-1.273z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract70 svgRef={ref} {...props} />);
export default ForwardRef;
