import React from 'react';

const SvgOther38 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2.5 10h-2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm19 0h-2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM5.7 4.28L4.28 2.87a.48.48 0 0 0-.7 0l-.71.71a.48.48 0 0 0 0 .7L4.28 5.7a.51.51 0 0 0 .72 0l.7-.7a.51.51 0 0 0 0-.72zm12.72-1.41a.48.48 0 0 0-.7 0L16.3 4.275a.507.507 0 0 0 0 .708l.7.707a.511.511 0 0 0 .71 0l1.41-1.415a.478.478 0 0 0 .01-.698zM10.5 3h1a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5zM9 20.59a1 1 0 0 0 .29.7l.42.42a1 1 0 0 0 .7.29h1.18a1 1 0 0 0 .7-.29l.42-.42a1 1 0 0 0 .29-.7V20H9zM11 15a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-10a6 6 0 0 0-3.2 11.07c.436.275.7.755.7 1.27v.16a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-.16a1.48 1.48 0 0 1 .7-1.27A6 6 0 0 0 11 5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther38 svgRef={ref} {...props} />);
export default ForwardRef;
