import React from 'react';

const SvgPayment4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.25 12.25h-3.5v-1.37h3.5zm-3.5-4.5h3v1.37h-3zm4.75 1.13V8a2 2 0 0 0-2-2H11V4.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H6.5a.5.5 0 0 0-.5.5v.75a.5.5 0 0 0 .5.5h.25A.25.25 0 0 1 7 8v4a.25.25 0 0 1-.25.25H6.5a.5.5 0 0 0-.5.5v.75a.5.5 0 0 0 .5.5H9v1.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V14h1a2 2 0 0 0 2-2v-.88a2 2 0 0 0-.66-1.47 2 2 0 0 0 .16-.77zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment4 svgRef={ref} {...props} />);
export default ForwardRef;
