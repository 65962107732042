import React from 'react';

const SvgOther14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.85 14.12L10 17.5v-6.92l6-3.47v6.76zM8.85 2h.29L15 5.38 9 8.84 3 5.38zm8.88 3.12l-.14-.25a2.07 2.07 0 0 0-.74-.73L10.14.27a2 2 0 0 0-1-.27h-.29a2 2 0 0 0-1 .27L1.14 4.15a2 2 0 0 0-.73.73l-.14.25a2 2 0 0 0-.27 1v7.75a2 2 0 0 0 .27 1l.14.25c.18.3.43.55.73.73l6.72 3.87c.302.18.648.273 1 .27h.28a2 2 0 0 0 1-.27l6.71-3.88a1.9 1.9 0 0 0 .73-.73l.15-.25a2.05 2.05 0 0 0 .27-1V6.12a2 2 0 0 0-.27-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther14 svgRef={ref} {...props} />);
export default ForwardRef;
