import React from 'react';

const SvgActions63 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.71 4.41c.184.192.287.445.29.71V9.9a4 4 0 0 1-1.22 2.88l-7 7a.75.75 0 0 1-1.06 0l-.89-.89 7.58-7.57A2 2 0 0 0 20 9.9V4h.88c.266.004.52.107.71.29zM12 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM17.59.29l.12.12c.184.192.287.445.29.71v7.95a2 2 0 0 1-.59 1.42l-7.22 7.22a1 1 0 0 1-1.41 0L.29 9.22a1 1 0 0 1 0-1.41L7.51.59A2 2 0 0 1 8.93 0h7.95c.266.004.52.107.71.29zM16 2H8.93L2.41 8.51l7.08 7.08L16 9.07z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions63 svgRef={ref} {...props} />);
export default ForwardRef;
