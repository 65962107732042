import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { api } from '../../../../services';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MobileWelcome from './MobileWelcome';
import DesktopWelcome from './DesktopWelcome';
import installersData from '../installersData';

@withRouter
@inject('translationsStore', 'moveinStore')
@observer
export default class MoVeInWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installersLoading: false,
      installers: []
    };
  }

  componentDidMount = () => {
    this.loadInstallersList();
  };

  onIdAdesioneSubmit = () => {
    const { moveinStore, history } = this.props;
    moveinStore.draftIdAdesione !== '' &&
      moveinStore.welcomeSubmit().then(() => {
        if (moveinStore.idAdesione !== null) {
          history.push('/movein/registrazione');
          window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
        }
      });
  };

  onEmailClick = () => {
    // window.location.href = 'mailto:movein@myair.io';
    window.open('mailto:movein@myair.io', '_blank');
    return null;
  };

  loadInstallersList = () => {
    this.setState({ installersLoading: true });
    api('getInstallers')
      .then(response => {
        let installers = response.map(installer => {
          let firstPop;
          const operationalOffice = installer.addresses.find(address => address.type === 'operational_office');

          if (operationalOffice) {

            firstPop = operationalOffice.node;
          }
          return {
            code: installer.code,
            businessName: installer.company_name,
            taxCode: installer.uin,
            address: firstPop ? `${firstPop.street} ${firstPop.building_number}` : '',
            admdiv3: firstPop ? firstPop.city : '',
            admdiv2: firstPop ? firstPop.province.toUpperCase() : '',
            postCode: firstPop ? firstPop.postcode : '',
            phone: installer.phone,
            managerName: installer.reference,
            email: installer.email,
            lat: (firstPop && firstPop.position) ? firstPop.position.latitude : 0,
            lng: (firstPop && firstPop.position) ? firstPop.position.longitude : 0
          };
        });

        this.setState({ installers });
      })
      .finally(() => this.setState({ installersLoading: false }));
  };

  render() {
    const { translationsStore } = this.props;

    const dataCollection = {
      handlers: { onIdAdesioneSubmit: this.onIdAdesioneSubmit, onEmailClick: this.onEmailClick },
      installers: this.state.installers,
      installersLoading: this.state.installersLoading,
      installersData
    };

    return (
      <BreakpointsMedia>
        {({ breakpoints, currentBreakpoint }) => {
          return currentBreakpoint === 'extrasmall' || currentBreakpoint === 'small' ? (
            <MobileWelcome dataCollection={dataCollection} onIdAdesioneSubmit={this.onIdAdesioneSubmit} onEmailClick={this.onEmailClick} />
          ) : (
            <DesktopWelcome dataCollection={dataCollection} onIdAdesioneSubmit={this.onIdAdesioneSubmit} onEmailClick={this.onEmailClick} />
          );
        }}
      </BreakpointsMedia>
    );
  }
}
