import React from 'react';

const SvgTransport12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 10H9V4h5zm0 4.5a1.5 1.5 0 0 1-1.5 1.5H12a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm-12 0V14a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-.5A1.5 1.5 0 0 1 2 14.5zM2 4h5v6H2zm13.27-2.73l-.54-.54A2.49 2.49 0 0 0 13 0H3a2.49 2.49 0 0 0-1.77.73l-.54.54A2.49 2.49 0 0 0 0 3v12c-.002.664.26 1.3.73 1.77l.54.54A2.49 2.49 0 0 0 3 18h1l-1 1.62a.26.26 0 0 0 .22.38h1.47a.73.73 0 0 0 .65-.38L6.28 18h3.44l.94 1.62a.73.73 0 0 0 .65.38h1.44a.26.26 0 0 0 .22-.38L12 18h1a2.49 2.49 0 0 0 1.77-.73l.54-.54A2.49 2.49 0 0 0 16 15V3a2.49 2.49 0 0 0-.73-1.73z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport12 svgRef={ref} {...props} />);
export default ForwardRef;
