import React from 'react';

const SvgAbstract12 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 13" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.996 1.899a.5.5 0 0 1-.148.355l-10.6 10.6a.5.5 0 0 1-.71 0l-5.39-5.39a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0l1.84 1.88 7-7.05a.51.51 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71l-7 7 1.08 1.08 9.55-9.55a.51.51 0 0 1 .71 0l.7.71a.5.5 0 0 1 .148.355zM1.558 6.054l5.75 5.732-1.06 1.058a.5.5 0 0 1-.71 0L.148 7.462a.499.499 0 0 1 0-.71l.7-.698a.5.5 0 0 1 .71 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract12 svgRef={ref} {...props} />);
export default ForwardRef;
