import React from 'react';

const SvgActions37 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.4 12.9c-.2.2-.5.2-.7 0l-.5-.5c-.2-.2-.2-.5 0-.7l6.5-6.5c.2-.2.5-.2.7 0l.5.5c.1.2.2.5 0 .7zM16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions37 svgRef={ref} {...props} />);
export default ForwardRef;
