import React from 'react';

const SvgTransport11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7-14V2.5a.5.5 0 0 0-.5-.5H12a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2H.5a.5.5 0 0 0-.5.5V4a2 2 0 0 0 2 2v2H.5a.5.5 0 0 0-.5.5V10a2 2 0 0 0 2 2v2H.5a.5.5 0 0 0-.5.5V16a2 2 0 0 0 2 2 2 2 0 0 0 2 2h6a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-1.5a.5.5 0 0 0-.5-.5H12v-2a2 2 0 0 0 2-2V8.5a.5.5 0 0 0-.5-.5H12V6a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgTransport11 svgRef={ref} {...props} />);
export default ForwardRef;
