import React from 'react';

const SvgOther60 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 8.69v.5a.5.5 0 0 1-.5.5h-4.08l2.43 2.43a.48.48 0 0 1 0 .7l-.35.36a.51.51 0 0 1-.71 0L11.3 9.69H9.69v1.49l3.49 3.49a.5.5 0 0 1 0 .7l-.36.36a.5.5 0 0 1-.7 0L9.69 13.3v4.23a.47.47 0 0 1-.47.47h-.57a.47.47 0 0 1-.46-.47v-4l-2.31 2.3a.48.48 0 0 1-.7 0l-.36-.35a.51.51 0 0 1 0-.71l3.37-3.36V9.69H6.82l-3.49 3.49a.5.5 0 0 1-.7 0l-.36-.36a.5.5 0 0 1 0-.7L4.7 9.69H.47A.47.47 0 0 1 0 9.22v-.57a.47.47 0 0 1 .47-.46h4L2.15 5.88a.48.48 0 0 1 0-.7l.35-.36a.51.51 0 0 1 .71 0l3.36 3.37h1.62V6.7L4.82 3.33a.5.5 0 0 1 0-.7l.36-.36a.5.5 0 0 1 .7 0l2.31 2.3V.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v4.08l2.43-2.43a.48.48 0 0 1 .7 0l.36.35a.51.51 0 0 1 0 .71L9.69 6.7v1.49h1.61l3.37-3.37a.5.5 0 0 1 .7 0l.36.36a.5.5 0 0 1 0 .7l-2.3 2.31h4.07a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther60 svgRef={ref} {...props} />);
export default ForwardRef;
