import React from 'react';

const SvgFiles19 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.5 6h-7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-2 4h-5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm8.5 4H2V2h7.17l1.13 1.12a3 3 0 0 0 2.11.88H18zm0-12h-5.59a1 1 0 0 1-.7-.29L10.29.29a1 1 0 0 0-.7-.29H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles19 svgRef={ref} {...props} />);
export default ForwardRef;
