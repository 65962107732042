import React from 'react';

const SvgUser9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.382 18l-1.17-2.34a3.001 3.001 0 0 0-2.68-1.66H6.468a3.001 3.001 0 0 0-2.68 1.66L2.617 18zm2.47 1.523a1 1 0 0 1-.85.477H.998a1 1 0 0 1-.89-1.45l1.89-3.79A5.002 5.002 0 0 1 6.47 12h7.062a5.002 5.002 0 0 1 4.471 2.76l1.891 3.79a1 1 0 0 1-.04.973zM7 5a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm3 5a5 5 0 1 1 5.002-5A5 5 0 0 1 10 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser9 svgRef={ref} {...props} />);
export default ForwardRef;
