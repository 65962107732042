import React from 'react';

const SvgActions1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.5 9H11V6.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V9H6.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H9v2.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V11h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions1 svgRef={ref} {...props} />);
export default ForwardRef;
