import React from 'react';

const SvgActions55 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 15.979a1.996 1.996 0 0 0-2-1.993c-1.104 0-2 .892-2 1.993 0 1.1.896 1.993 2 1.993 1.105 0 2-.893 2-1.993zm-10 0a1.996 1.996 0 0 0-2-1.993c-1.104 0-2 .892-2 1.993 0 1.1.896 1.993 2 1.993 1.105 0 2-.893 2-1.993zm12 .005a3.998 3.998 0 0 1-4 3.996c-2.209 0-4-1.79-4-3.996a1 1 0 0 0-2 0 4 4 0 0 1-7.79 1.293 3.994 3.994 0 0 1 1.93-4.81 4.003 4.003 0 0 1 5.062 1.14 2.922 2.922 0 0 1 3.6 0 4.001 4.001 0 0 1 3.2-1.62c2.209 0 4 1.79 4 3.997zm-.16-6.843a.5.5 0 0 1-.35.849H.5a.5.5 0 0 1-.35-.85L1 8.283a1 1 0 0 1 .71-.29h14.57a1 1 0 0 1 .71.29l.84.859zM2 5.415L2.762.839a1 1 0 0 1 1-.839h.441c.466 0 .926.109 1.341.32l1.711.829a4.01 4.01 0 0 0 3.503 0l1.711-.83c.415-.21.875-.32 1.341-.319h.44a1 1 0 0 1 1.001.84l.76 4.575a.499.499 0 0 1-.5.579H2.5a.5.5 0 0 1-.5-.58z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions55 svgRef={ref} {...props} />);
export default ForwardRef;
