import React from 'react';

const SvgDevices21 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3 2h10v4h-2V4.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H7V4.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H3zm12 4V1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v5a1 1 0 0 0-1 1v5.34a4 4 0 0 0 1.17 2.83l.66.66c.232.234.494.436.78.6A2.65 2.65 0 0 1 4 18.69V19a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-.31a2.65 2.65 0 0 1 1.39-2.26 3.69 3.69 0 0 0 .78-.6l.66-.66A4 4 0 0 0 16 12.34V7a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices21 svgRef={ref} {...props} />);
export default ForwardRef;
