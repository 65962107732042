import React from 'react';

const SvgActions16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 4v10a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h12a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zM2 4h14v8H2zm16 8V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions16 svgRef={ref} {...props} />);
export default ForwardRef;
