import React from 'react';

const SvgArrows8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.636 7.15a.509.509 0 0 1 .709 0l2.995 3a.5.5 0 0 1-.35.85H7a.5.5 0 0 1-.36-.85zM10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10a10 10 0 0 0 10 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows8 svgRef={ref} {...props} />);
export default ForwardRef;
