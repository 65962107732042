import React from 'react';

const SvgActions60 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.592.292a1.05 1.05 0 0 0-.71-.29h-7.95a2 2 0 0 0-1.42.59l-1.6 1.6 9.9 9.9 1.6-1.6a2 2 0 0 0 .59-1.42v-7.95a1.05 1.05 0 0 0-.29-.71zM1.382.152a.48.48 0 0 0-.7 0l-.53.53a.48.48 0 0 0 0 .7l3.28 3.29-3.14 3.14a1 1 0 0 0 0 1.41l8.49 8.49a1 1 0 0 0 1.41 0l3.14-3.14 3.29 3.28a.48.48 0 0 0 .7 0l.53-.53a.48.48 0 0 0 0-.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions60 svgRef={ref} {...props} />);
export default ForwardRef;
