import React from 'react';

const SvgFiles3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9 18.18V14a1 1 0 0 1 1-1h4.18zM8.5 10h-5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zM3 4.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5zM14 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h7.17a2 2 0 0 0 1.42-.59l4.82-4.82a2 2 0 0 0 .59-1.42V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles3 svgRef={ref} {...props} />);
export default ForwardRef;
