import React from 'react';

const SvgOther61 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.51 6.69a1.09 1.09 0 0 0-1.22 0L6.8 8.44a.88.88 0 0 0-.35 1l1 2.89a1 1 0 0 0 1 .65h3a1 1 0 0 0 1-.65l1-2.89a.88.88 0 0 0-.35-1zm5.95 8.01h-2.32a1 1 0 0 0-1 .72l-.67 2.22a7.89 7.89 0 0 1-5 0l-.67-2.22a1 1 0 0 0-1-.72H3.48A8 8 0 0 1 2 10l1.91-1.34a1 1 0 0 0 .38-1.16l-.76-2.18a8 8 0 0 1 4-2.93L9.39 3.8a1 1 0 0 0 1.22 0l1.85-1.41a8 8 0 0 1 4 2.93l-.75 2.18a1 1 0 0 0 .38 1.16L18 10a8 8 0 0 1-1.54 4.7zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther61 svgRef={ref} {...props} />);
export default ForwardRef;
