import React from 'react';

const SvgActions95 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm10 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-.72-7.5a1 1 0 0 1-.75.5H8.3a1 1 0 0 1-.86-.5L4.87 4h12.92c-.05.12-.09.25-.15.37zM19.5 2H3.72l-.56-1a2 2 0 0 0-1.74-1H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.92L5.7 9.49c.078.125.168.243.27.35l-1.86 3.7a.77.77 0 0 0 0 .71l.22.38A.76.76 0 0 0 5 15h12.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H6.62l1-2.09c.209.055.424.085.64.09h6.23A2.94 2.94 0 0 0 17 9.49l2.35-4.13A4.76 4.76 0 0 0 20 3v-.5a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions95 svgRef={ref} {...props} />);
export default ForwardRef;
