import React from 'react';

const SvgActions53 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.88 7.56a2.89 2.89 0 0 1-2 2.73l-.88.31a.13.13 0 0 0-.08.12v.78a.5.5 0 0 1-.5.5h-.76a.5.5 0 0 1-.5-.5v-.78a1.88 1.88 0 0 1 1.29-1.78l.95-.31a1.13 1.13 0 0 0 .76-1.07V7A1.12 1.12 0 0 0 11 5.88H9A1.12 1.12 0 0 0 7.88 7v.5a.5.5 0 0 1-.5.5h-.76a.5.5 0 0 1-.5-.5V7A2.88 2.88 0 0 1 9 4.12h2A2.88 2.88 0 0 1 13.88 7zM11 15.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions53 svgRef={ref} {...props} />);
export default ForwardRef;
