import React from 'react';

const SvgArrows45 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M1.922 11.17a.5.5 0 0 1-.71 0l-1.06-1.06a.48.48 0 0 1 0-.7L9.342.22a.75.75 0 0 1 .53-.22h.26c.198 0 .389.08.53.22l9.19 9.19a.48.48 0 0 1 0 .7l-1.06 1.06a.5.5 0 0 1-.71 0l-8.08-8.08z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows45 svgRef={ref} {...props} />);
export default ForwardRef;
