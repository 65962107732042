import React from 'react';

const SvgActions49 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 11 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M1.5 0h-1a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5zm9.428 10.795a.5.5 0 0 0-.148-.355L6.33 6l4.45-4.44a.49.49 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.71 0L4.22 5.28a.77.77 0 0 0-.22.53v.38c0 .199.08.39.22.53l5.14 5.13a.5.5 0 0 0 .71 0l.71-.7a.5.5 0 0 0 .148-.355z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions49 svgRef={ref} {...props} />);
export default ForwardRef;
