import React from 'react';

const SvgCommunication24 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 14V7l6.35 4.44a2.88 2.88 0 0 0 3.3 0L18 7v7zM18 2v2.9l-7.35 5.15a1.14 1.14 0 0 1-1.3 0L2 4.9V2zm0-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication24 svgRef={ref} {...props} />);
export default ForwardRef;
