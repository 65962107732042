import React from 'react';

const SvgAbstract16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 10a7.92 7.92 0 0 1 1.69-4.9L14.9 16.31A7.92 7.92 0 0 1 10 18a8 8 0 0 1-8-8zm16 0a7.92 7.92 0 0 1-1.69 4.9L5.1 3.69A7.92 7.92 0 0 1 10 2a8 8 0 0 1 8 8zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract16 svgRef={ref} {...props} />);
export default ForwardRef;
