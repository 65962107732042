import React from 'react';

const SvgCommunication21 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5.05 16.28l.73-1.45A1.5 1.5 0 0 1 7.12 14h3.76a1.5 1.5 0 0 1 1.34.83l.78 1.45a.5.5 0 0 1-.45.72h-7a.5.5 0 0 1-.5-.72zM11 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM2 4v5H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H2v5H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication21 svgRef={ref} {...props} />);
export default ForwardRef;
