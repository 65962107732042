import React from 'react';

const SvgActions42 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.65 9.85a.49.49 0 0 1-.36.15H5.71a.49.49 0 0 1-.36-.15l-.2-.2a.5.5 0 0 1 0-.71l4.5-4.79a.48.48 0 0 1 .7 0l4.5 4.79a.5.5 0 0 1 0 .71zM15 13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions42 svgRef={ref} {...props} />);
export default ForwardRef;
