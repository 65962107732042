import React from 'react';

const SvgPayment14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13 7.75a.5.5 0 0 1-.5.5h-.75a.5.5 0 0 1-.5-.5v-1h-2.5v.88a.22.22 0 0 0 .09.19l3.43 2.83a2 2 0 0 1 .73 1.54V13a2 2 0 0 1-2 2v.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V15a2 2 0 0 1-2-2v-.75a.5.5 0 0 1 .5-.5h.75a.5.5 0 0 1 .5.5v1h2.5v-.88a.22.22 0 0 0-.09-.19L7.73 9.35A2 2 0 0 1 7 7.81V7a2 2 0 0 1 2-2v-.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V5a2 2 0 0 1 2 2zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment14 svgRef={ref} {...props} />);
export default ForwardRef;
