import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MobileReg from './MobileReg';
import DesktopReg from './DesktopReg';
import AirModal from '../../../../common/components/AirModal';
import MyPageButton from '../../../../common/components/MyPageButton';
import K from '../../../../constants';

import './MyPageReg.css';
import { Form } from 'antd';

@withRouter
@inject('moveinStore', 'translationsStore')
@observer
class MyPageReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleExistingVehicleNewIdAdesioneModal: false
    };
    this.props.moveinStore.setRegistered(false);
  }

  componentDidMount = () => {
    const { moveinStore, history } = this.props;
    if (moveinStore.idAdesione === null) {
      history.push('/movein/idadesione');
      window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
    }
  };

  onClickReg = (email, mobile, marketingAgreement, marketingThirdPartiesAgreement) => {
    const {
      moveinStore: { existingData },
    } = this.props;
    return this.props.moveinStore.registerUser(email, mobile, marketingAgreement, marketingThirdPartiesAgreement).then(() => {
      if (this.props.moveinStore.lastError == null) {
        let modalVisibleState = {
          visible: true,
        };
        if (existingData !== undefined) {
          modalVisibleState = {
            visibleExistingVehicleNewIdAdesioneModal: true,
          };
        }
        this.setState(modalVisibleState);
        return true;
      }
    });
  };

  render() {
    return (
      <div>
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            return currentBreakpoint === 'extrasmall' || currentBreakpoint === 'small' ? (
              <MobileReg onClickReg={this.onClickReg} form={this.props.form} withLegal={this.props.moveinStore.company !== undefined} />
            ) : (
              <DesktopReg onClickReg={this.onClickReg} form={this.props.form} withLegal={this.props.moveinStore.company !== undefined} />
            );
          }}
        </BreakpointsMedia>
        <AirModal
          className="MyPageRegModal"
          visible={this.state.visible}
          onOk={this.handleOk}
          closeButton={false}
          width={1100}
        >
          <div className={'SuccessModalContainer'}>
            <div style={{display: "flex", justifyContent: 'center', color: '#0cb458', fontSize: 18, marginBottom : '15px'}}>
              {this.props.translationsStore.translate('registration.form.SuccessModal.registrationCompleted')}
            </div>
            <a style={{fontSize: 18, fontWeight: 'bold', textDecoration: 'underline', color: 'black'}}>{this.props.translationsStore.translate('registration.form.SuccessModal.weSentEmailWithCredential')}</a>
            <a style={{fontSize: 16, color: '#474747'}}>{this.props.translationsStore.translate('registration.form.SuccessModal.passwordReminder')}</a>
            <a style={{fontSize: 16, color: '#474747'}}>{this.props.translationsStore.translate('registration.form.SuccessModal.ifNotPresentCheckYourSpam')}</a>
            <a style={{fontSize: 16, color: '#474747'}}>{this.props.translationsStore.translate('registration.form.SuccessModal.accessInstruction')}</a>
            <a style={{fontSize: 16, fontWeight: 'bold', color: '#474747'}}>{this.props.translationsStore.translate('movein.stepWelcome.appInstruction')}</a>
          </div>
        </AirModal>
        <AirModal
          className="MoVeInExistingVehicleNewIdAdesioneModal"
          visible={this.state.visibleExistingVehicleNewIdAdesioneModal}
          onCancel={() => {
            this.setState({ visibleExistingVehicleNewIdAdesioneModal: false });
          }}
          width={700}
        >
          <div className="title">{this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.title')}</div>
          <div dangerouslySetInnerHTML={{ __html: this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.description1')}} />
          <div dangerouslySetInnerHTML={{ __html: this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.description2')}} />
          <ol>
            <li dangerouslySetInnerHTML={{ __html: this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.description2_1')}} />
            <li dangerouslySetInnerHTML={{ __html: this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.description2_2')}} />
            <li dangerouslySetInnerHTML={{ __html: this.props.translationsStore.translate('movein.stepRegistration.existingVehicleNewIdAdesione.description2_3')}} />
          </ol>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <MyPageButton
              theme="primary"
              solid
              style={{ height: 40, textWrap: 'no-wrap', fontSize: 16, width: 'auto', backgroundColor: 'var(--action-info-color)' }}
              title={'MyPage'}
              onClick={() => {
                this.setState({ visibleExistingVehicleNewIdAdesioneModal: false }, () => {
                  window.location = `${K.AIR_MYPAGE_URL}`;
                });
              }}
            />
            <MyPageButton
              theme="primary"
              solid
              style={{ height: 40, textWrap: 'no-wrap', fontSize: 16, width: 'auto' }}
              title={this.props.translationsStore.translate('common.close')}
              onClick={() => {
                this.setState({ visibleExistingVehicleNewIdAdesioneModal: false });
              }}
            />
          </div>
        </AirModal>
      </div>
    );
  }
}

export default Form.create()(MyPageReg);
