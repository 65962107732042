import React from 'react';

const SvgUser8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.497 6h-2.5V3.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6h-2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2.5v2.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V8h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-13.5 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm6.45 3.66a3 3 0 0 0-2.69-1.66h-7.52a3 3 0 0 0-2.69 1.66l-1.44 2.89A1 1 0 0 0 .997 16h14a1 1 0 0 0 .89-1.45z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser8 svgRef={ref} {...props} />);
export default ForwardRef;
