import React from 'react';

const SvgOther37 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 8.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6.73a5 5 0 0 1-4-4.9C2 7.06 5 3.78 7 2c2 1.74 5 5 5 8.29a5 5 0 0 1-4 4.94zM7.94.23A1 1 0 0 0 7.3 0h-.6a1 1 0 0 0-.64.23C4.15 1.81 0 5.76 0 10.33a7 7 0 0 0 6 6.92v2.25a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-2.25a7 7 0 0 0 6-6.92C14 5.76 9.85 1.81 7.94.23z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther37 svgRef={ref} {...props} />);
export default ForwardRef;
