import React from 'react';

const SvgAbstract78 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M10.72 4.22a.77.77 0 0 0-.53-.22h-.38a.75.75 0 0 0-.53.22L6.15 7.36a.5.5 0 0 0 0 .71l.7.71a.5.5 0 0 0 .71 0L9 7.33v8.17a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V7.33l1.44 1.44a.49.49 0 0 0 .7 0l.71-.71a.5.5 0 0 0 0-.71zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract78 svgRef={ref} {...props} />);
export default ForwardRef;
