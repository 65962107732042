import React from 'react';

const SvgCommunication28 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 2v8h-3.76a1.5 1.5 0 0 0-1.41 1 3 3 0 0 1-5.66 0 1.5 1.5 0 0 0-1.41-1H2V2zm0-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication28 svgRef={ref} {...props} />);
export default ForwardRef;
