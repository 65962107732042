import Joi from "joi";
import tlds from './tlds'

const emailValidator = (value) => {
  const emailSchema = Joi.string().email({ tlds: { allow: tlds } });
  const { error } = emailSchema.validate(value);

  return !error;

}

export default emailValidator;
