import React from 'react';

const SvgOther35 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.356 11.29a4.05 4.05 0 0 0 .63-2.16V2H8.99v7.12a3.927 3.927 0 0 0 .641 2.16L11.373 14h-6.77zm4.957-1.09a1.998 1.998 0 0 1-.32-1.08V2h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-7.01a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.5v7.13a1.998 1.998 0 0 1-.32 1.08L.217 17.12c-.29.48-.29 1.08 0 1.56l.32.57c.272.461.767.746 1.303.75h12.326a1.522 1.522 0 0 0 1.302-.75l.33-.58a1.488 1.488 0 0 0-.04-1.56z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther35 svgRef={ref} {...props} />);
export default ForwardRef;
