import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MoVeIn from './MoVeIn';

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Redirect from="/" exact to="/movein" />
          <Route path="/movein" render={() => <MoVeIn />} />
        </Switch>
      </Router>
    );
  }
}
