import React from 'react';

const SvgAbstract52 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 18H2v-8h12zm0-10H4V6a4 4 0 1 1 8 0h2A6 6 0 1 0 2 6v2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract52 svgRef={ref} {...props} />);
export default ForwardRef;
