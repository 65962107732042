import React from 'react';

const SvgArrows15 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.06 15.138a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.15-.36v-3.58H.5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5H8v-3.58a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .71 0l6.79 6.79a.48.48 0 0 1 0 .7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows15 svgRef={ref} {...props} />);
export default ForwardRef;
