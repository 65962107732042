import React from 'react';

const SvgFiles16 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 4v14a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h9a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zM7 6a1 1 0 0 1-1 1H1.82L7 1.82zm8 10V2a2 2 0 0 0-2-2H6.83a2 2 0 0 0-1.42.59L.59 5.41A2 2 0 0 0 0 6.83V16a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles16 svgRef={ref} {...props} />);
export default ForwardRef;
