import React from 'react';

const SvgActions106 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.843 16.622a.48.48 0 0 1 .01.7l-.53.53a.48.48 0 0 1-.699 0l-2.629-2.62a7.935 7.935 0 0 1-3.398 1.55.53.53 0 0 1-.42-.1.53.53 0 0 1-.18-.39v-1a.51.51 0 0 1 .38-.49 5.916 5.916 0 0 0 2.179-1L4.2 5.432a5.942 5.942 0 0 0 1.1 8.27l1.548-1.55a.49.49 0 0 1 .36-.15h.29a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5v-.29a.49.49 0 0 1 .15-.36l1.72-1.73a8.002 8.002 0 0 1-1.1-11.12L.151 1.382a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0zM9.997 5.502v-5a.5.5 0 0 1 .5-.5h4.99a.5.5 0 0 1 .499.5v.29a.49.49 0 0 1-.12.36l-1.717 1.72a8.01 8.01 0 0 1 2.066 9.62l-1.527-1.53a6.007 6.007 0 0 0-1.996-6.66l-1.547 1.55a.489.489 0 0 1-.36.15h-.289a.5.5 0 0 1-.499-.5zm-2.949-2.16L5.52 1.812a7.584 7.584 0 0 1 1.88-.64.53.53 0 0 1 .419.1.53.53 0 0 1 .18.39v1a.51.51 0 0 1-.38.49c-.19.07-.38.12-.57.19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions106 svgRef={ref} {...props} />);
export default ForwardRef;
