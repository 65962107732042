import React from 'react';

const SvgFiles6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 19 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 4v14a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h9a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zm-9 8a1 1 0 0 1 1-1h4.18L8 16.18zm6.41.59a2 2 0 0 0 .59-1.42V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6.17a2 2 0 0 0 1.42-.59z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles6 svgRef={ref} {...props} />);
export default ForwardRef;
