import React from 'react';

const SvgActions58 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.44 15.56a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM17 0h-1.41a1 1 0 0 0-.7.29L7.71 7.47A5.4 5.4 0 0 0 5.5 7a5.5 5.5 0 1 0 5.04 3.3l.88-.88a1 1 0 0 0 .29-.7V8c0-.199.08-.39.22-.53l.26-.26a.75.75 0 0 1 .53-.22h1.78a.5.5 0 0 0 .5-.5V4.73c0-.199.08-.39.22-.53h.05a.49.49 0 0 1 .36-.2h1.87a.5.5 0 0 0 .5-.5V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions58 svgRef={ref} {...props} />);
export default ForwardRef;
