import React from 'react';

const SvgCommunication20 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M19 2h-1v16h1a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM3.75 15a.75.75 0 0 1-.67-1.09L4 12.1A2 2 0 0 1 5.77 11h4.61A1.8 1.8 0 0 1 12 12.1l.9 1.81a.75.75 0 0 1-.65 1.09zm6.5-8a2.25 2.25 0 0 1-4.5 0A2.24 2.24 0 0 1 8 4.75 2.25 2.25 0 0 1 10.25 7zM14 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication20 svgRef={ref} {...props} />);
export default ForwardRef;
