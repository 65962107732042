import React from 'react';

const SvgDevices56 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 14" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3.5 5.88a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v.76a.5.5 0 0 1-.5.5zM19.5 2h-.09a1 1 0 0 0-.7.29L16 5V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9l2.71 2.71a1 1 0 0 0 .7.29h.09a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices56 svgRef={ref} {...props} />);
export default ForwardRef;
