import React from 'react';

const SvgCommunication13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.06 8.62l-3.89 2.6A.75.75 0 0 1 8 10.6V5.4a.75.75 0 0 1 1.17-.62l3.89 2.6a.74.74 0 0 1 0 1.24zM18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5.09a1 1 0 0 1 .7.29l1.49 1.49c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22l1.49-1.49a1 1 0 0 1 .7-.29H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication13 svgRef={ref} {...props} />);
export default ForwardRef;
