import React from 'react';

const SvgActions97 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 2.5V3a4.76 4.76 0 0 1-.65 2.36L17 9.49A2.94 2.94 0 0 1 14.49 11H8.26a2.74 2.74 0 0 1-.64-.09l-1 2.09H17.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5a.76.76 0 0 1-.67-.37l-.22-.38a.77.77 0 0 1 0-.71l1.86-3.7a2.191 2.191 0 0 1-.27-.35L1.42 2H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 0h.92a2 2 0 0 1 1.74 1l.56 1H19.5a.5.5 0 0 1 .5.5zM8 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm10 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions97 svgRef={ref} {...props} />);
export default ForwardRef;
