import React from 'react';

const SvgArrows26 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.498 20h-1a.5.5 0 0 1-.5-.5V3.33l-4.44 4.45a.5.5 0 0 1-.71 0l-.7-.71a.5.5 0 0 1 0-.71L6.278.22a.75.75 0 0 1 .53-.22h.38a.77.77 0 0 1 .53.22l6.13 6.14a.5.5 0 0 1 0 .71l-.71.71a.49.49 0 0 1-.7 0l-4.44-4.45V19.5a.5.5 0 0 1-.5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows26 svgRef={ref} {...props} />);
export default ForwardRef;
