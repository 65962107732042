import React from 'react';

const SvgOther13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0a10 10 0 0 1 10 10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther13 svgRef={ref} {...props} />);
export default ForwardRef;
