import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withWindowSize } from 'air-with-window-size';
import { PulseLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import MyPageButton from '../../../../../common/components/MyPageButton';
import LabelInput from '../../../../../common/components/LabelInput';
import MoVeInDesktopHeader from '../../../../../common/components/MoVeInDesktopHeader';
import MoVeInDesktopFooter from '../../../../../common/components/MoVeInDesktopFooter';
import SupportTile from '../SupportTile';
import { EmailOutlinedIcon, ErrorIcon, IphoneIcon, PagesIcon, TimeIcon } from '../../../../../common/icons';
import MyPageAlert from '../../../../../common/components/MyPageAlert';
import InstallersCard from '../InstallersCard/InstallersCard';

import './DesktopWelcome.css';
import WhereIsIdAdesioneModal from "../WhereIsIdAdesioneModal";

@inject('translationsStore', 'moveinStore')
@observer
class DesktopWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleWhereIsIdAdesioneModal: false,
    };
  }

  handleEnter = e => {
    if (e.key === 'Enter') {
      this.props.onIdAdesioneSubmit();
    }
  };

  onContactusClick = () => {
    // window.location.href = 'tel:800101222';
    window.open('tel:800101222', '_blank');
    return null;
  };

  render() {
    const { translationsStore, moveinStore, dataCollection } = this.props;
    const { isVisibleWhereIsIdAdesioneModal } = this.state;

    const cls = classNames({
      DesktopWelcome: true
    });

    const style1 = {
      color: 'var(--black-color)',
      fontSize: '22px',
      fontWeight: 600,
      letterSpacing: '-0.7px',
      lineHeight: '32px'
    };

    return (
      <div className={cls}>
        <MoVeInDesktopHeader />
        <div className="WelcomeView">
          <div style={{ ...style1, marginBottom: 16 }}>{translationsStore.translate('movein.stepWelcome.labelWelcomeIntro')}</div>
          <div style={{ marginBottom: 5, fontWeight: 600 }}>{translationsStore.translate('movein.stepWelcome.appInstruction')}</div>
          <div style={{ marginBottom: 16 }}>{translationsStore.translate('movein.stepWelcome.labelWelcomeHint')}</div>
          <LoadingOverlay
            active={moveinStore.loadingCheckIdAdesione}
            spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
            styles={{
              overlay: base => ({
                ...base,
                background: 'var(--grey-light-color-50)'
              })
            }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <LabelInput
                placeholder={'Inserisci codice di 6 cifre: ID MoveIn / ID Adesione'}
                value={moveinStore.draftIdAdesione}
                onChange={e => {
                  moveinStore.setDraftIdAdesione(e.target.value);
                }}
                onKeyUp={this.handleEnter}
                style={{ marginRight: 16, flexGrow: 2, flexBasis: 0 }}
              />
              <MyPageButton
                className={'WelcomeSubmit'}
                theme="primary"
                solid
                disabled={moveinStore.draftIdAdesione === '' || moveinStore.loadingCheckIdAdesione}
                onClick={this.props.onIdAdesioneSubmit}
                title={translationsStore.translate(`movein.stepWelcome.labelWelcomeSubmit`)}
                style={{ flexGrow: 1, flexBasis: 0, height: 40 }}
              />
            </div>
            <div className={'WhereIsIdAdesioneLink'} onClick={() => this.setState({isVisibleWhereIsIdAdesioneModal: true})}>
              {translationsStore.translate('movein.stepWelcome.whereIsIdAdesione')}
            </div>
            <WhereIsIdAdesioneModal visible={isVisibleWhereIsIdAdesioneModal} onCancel={()=> this.setState({isVisibleWhereIsIdAdesioneModal: false})}/>
          </LoadingOverlay>
          {moveinStore.lastError !== null && (
            <MyPageAlert
              style={{ marginTop: 16 }}
              message={translationsStore.translate('movein.stepWelcome.errorIdAdesioneInput')}
              description={translationsStore.translate('movein.stepWelcome.errorIdAdesioneInputDescription')}
              type="error"
              closable
              showIcon
              icon={<ErrorIcon width={20} height={20} />}
            />
          )}
          <div style={{ marginTop: 40, marginBottom: 16 }}>{translationsStore.translate('movein.stepWelcome.labelWelcomeSupport')}</div>
          <div className={'SupportTileContainer'}>
            <div className={'SupportTileGroup'}>
              <SupportTile
                icon={<PagesIcon width={24} height={24} />}
                themeColor="var(--action-custom-color)"
                title={translationsStore.translate('movein.stepWelcome.tilesSupport.weekdays.title')}
                description={translationsStore.translate('movein.stepWelcome.tilesSupport.weekdays.description')}
              />
              <SupportTile
                icon={<TimeIcon width={24} height={24} />}
                themeColor="var(--action-warning-color)"
                title={translationsStore.translate('movein.stepWelcome.tilesSupport.time.title')}
                description={translationsStore.translate('movein.stepWelcome.tilesSupport.time.description')}
              />
              <SupportTile
                icon={<IphoneIcon width={24} height={24} />}
                themeColor="var(--action-success-color)"
                title={translationsStore.translate('movein.stepWelcome.tilesSupport.phone.landlineFirst')}
                description={translationsStore.translate('movein.stepWelcome.tilesSupport.phone.tollfree')}
                style={{ cursor: 'pointer' }}
                onClick={this.onContactusClick}
              />
              <SupportTile icon={<EmailOutlinedIcon width={24} height={24} />} themeColor="var(--action-info-color)" title={'movein@myair.io'} onClick={this.props.onEmailClick} />
            </div>
          </div>
          <div style={{ marginTop: 40, marginBottom: 16 }}>{translationsStore.translate('movein.stepWelcome.labelInstallersIntro')}</div>
          <InstallersCard theme="desktop" width={this.props.componentWidth} dataCollection={dataCollection} />
        </div>
        <MoVeInDesktopFooter />
      </div>
    );
  }
}

export default withWindowSize(DesktopWelcome);
