import React from 'react';

const SvgPayment20 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.7 13.25h-3.35l.53-2.64 1.76-.51a.5.5 0 0 0 .36-.48v-1a.26.26 0 0 0-.32-.24l-1.43.41.69-3.45A.25.25 0 0 0 11.7 5H9.41a.5.5 0 0 0-.49.4l-.85 4.26-1.71.49a.5.5 0 0 0-.36.48v1a.26.26 0 0 0 .32.24l1.38-.39-.64 3.22a.25.25 0 0 0 .24.3h5.94a.5.5 0 0 0 .49-.4l.21-1.05a.25.25 0 0 0-.24-.3zM10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment20 svgRef={ref} {...props} />);
export default ForwardRef;
