import React from 'react';

const SvgActions115 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M9.187 6.119a.51.51 0 0 0-.178.38v8.932a.51.51 0 0 0 .581.499 4.999 4.999 0 0 0 4.458-4.965A4.999 4.999 0 0 0 9.59 6a.511.511 0 0 0-.403.119zM2.002 11c0-3.866 3.14-7 7.012-7 3.873 0 7.013 3.134 7.013 7s-3.14 7-7.013 7c-3.872 0-7.012-3.134-7.012-7zm14.015-5.63l1.131-1.13a.5.5 0 0 0 0-.7l-.71-.71a.5.5 0 0 0-.711 0L14.615 4a9.013 9.013 0 0 0-3.603-1.77V1a1 1 0 0 0-1.001-1H8.008a1 1 0 0 0-1 1v1.23A9.002 9.002 0 0 0 0 11c.055 4.848 3.9 8.803 8.75 9a9.01 9.01 0 0 0 8.31-4.996 8.992 8.992 0 0 0-1.043-9.634z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions115 svgRef={ref} {...props} />);
export default ForwardRef;
