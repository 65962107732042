import React from 'react';

const SvgPayment3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12 13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5zM18 0H2a2 2 0 0 0-2 2v2.5A1.5 1.5 0 0 0 1.5 6a.5.5 0 0 0 .5-.5V2h2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2h2v3.5a.5.5 0 0 0 .5.5A1.5 1.5 0 0 0 20 4.5V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment3 svgRef={ref} {...props} />);
export default ForwardRef;
