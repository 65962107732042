import React from 'react';

const SvgDevices11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 18H2V4h6zM8 2H7V1a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices11 svgRef={ref} {...props} />);
export default ForwardRef;
