import React from 'react';

const SvgCommunication43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 4h12v4.81l-5.5 3.85a.91.91 0 0 1-.5.15.85.85 0 0 1-.5-.16L4 8.81zm15.15 1.77L18 5V4a2 2 0 0 0-2-2h-2.24L11.65.52a2.88 2.88 0 0 0-3.3 0L6.24 2H4a2 2 0 0 0-2 2v1l-1.15.81A2 2 0 0 0 0 7.41V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7.41a2 2 0 0 0-.85-1.64z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication43 svgRef={ref} {...props} />);
export default ForwardRef;
