import React from 'react';

const SvgActions78 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17.496 6.997h-5a.5.5 0 0 1-.5-.5v-.29a.49.49 0 0 1 .15-.36l1.78-1.78a6.93 6.93 0 0 0-4.93-2.07 7 7 0 1 0 7 7.47.5.5 0 0 1 .5-.47h1a.52.52 0 0 1 .36.16.5.5 0 0 1 .13.37 9 9 0 1 1-2.62-6.89l1.49-1.49a.49.49 0 0 1 .35-.15h.29a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions78 svgRef={ref} {...props} />);
export default ForwardRef;
