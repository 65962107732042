import React from 'react';

const SvgDevices41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 11.004a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm12-1.507a.499.499 0 0 1-.5.498h-3a.499.499 0 0 1-.5-.498v-.994a.5.5 0 0 1 .5-.498h3a.5.5 0 0 1 .5.498zm0 4.006a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zm0 3.998a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zm-12 .49c-2.761 0-5-2.235-5-4.993a4.996 4.996 0 0 1 5-4.993c2.761 0 5 2.236 5 4.993a4.996 4.996 0 0 1-5 4.993zM21 6.006H7l7.78-4.628a.5.5 0 0 0 .17-.68l-.25-.44a.51.51 0 0 0-.7-.19L3.4 6.007H1a1 1 0 0 0-1 1V19a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7.006a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices41 svgRef={ref} {...props} />);
export default ForwardRef;
