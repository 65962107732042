import React from 'react';

const SvgOther75 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 3.686v.32a4.003 4.003 0 0 1-4 4.007 3.885 3.885 0 0 1-1.56-.32l-10 10.017a1 1 0 0 1-.7.29h-.21a1 1 0 0 1-.7-.29l-.54-.541a1.003 1.003 0 0 1-.29-.702v-.18a1 1 0 0 1 .29-.701l10-10.017A3.902 3.902 0 0 1 10 4.007 4.003 4.003 0 0 1 14 0h.32a.51.51 0 0 1 .36.15l.17.17a.501.501 0 0 1 0 .712l-1.56 1.562a.501.501 0 0 0 0 .701l1.42 1.423a.51.51 0 0 0 .7 0L17 3.155a.5.5 0 0 1 .71 0l.17.17c.085.1.128.23.12.361z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther75 svgRef={ref} {...props} />);
export default ForwardRef;
