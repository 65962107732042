import React from 'react';

const SvgDevices13 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h1a2 2 0 0 1 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices13 svgRef={ref} {...props} />);
export default ForwardRef;
