import React from 'react';

const SvgActions21 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 2v12H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm16 12V2h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM16 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2zm-2 0H6v12h8z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions21 svgRef={ref} {...props} />);
export default ForwardRef;
