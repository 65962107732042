import React, { Component } from 'react';
import { Modal } from 'antd';

import { ClearIcon } from '../../../common/icons';

import './AirModal.css';
export default class AirModal extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        closable={false}
        footer={false}
        className={`AirModal ${this.props.className || ''}`}
        centered={true}
        onCancel={this.props.onCancel}
        visible={this.props.visible}
        width={this.props.width}
      >
        {(!this.props.hasOwnProperty('closeButton') || this.props.closeButton === true) && (
          <div className="close">
            <ClearIcon onClick={this.props.onCancel} />
          </div>
        )}
        <div className="top">
          <div className="icon">{this.props.icon}</div>
          <div className="title">{this.props.title}</div>
          <div className="subtitle">{this.props.subtitle}</div>
        </div>
        <div className="content">{this.props.children}</div>
        <div className="buttons" style={!!this.props.cancelButton && !!this.props.okButton ? { justifyContent: 'space-between' } : { justifyContent: 'center' }}>
          {!!this.props.cancelButton && (
            <div className="cancelButton" onClick={this.props.onClickCancel || this.props.onCancel}>
              {this.props.cancelButton}
            </div>
          )}
          {!!this.props.okButton && (
            <div className="okButton" onClick={this.props.onOk}>
              {this.props.okButton}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
