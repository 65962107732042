import React from 'react';

const SvgArrows36 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 10 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.152 1.608a.5.5 0 0 1 0-.71l.71-.71a.49.49 0 0 1 .7 0l3.44 3.44 3.44-3.45a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71l-4.13 4.14a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22zm9.7 12.79a.48.48 0 0 1 0 .7l-.71.71a.5.5 0 0 1-.7 0l-3.44-3.45-3.44 3.45a.5.5 0 0 1-.71 0l-.7-.71a.48.48 0 0 1 0-.7l4.13-4.15a.75.75 0 0 1 .53-.22h.38a.77.77 0 0 1 .53.22z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows36 svgRef={ref} {...props} />);
export default ForwardRef;
