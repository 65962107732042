import React from 'react';

const SvgArrows19 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.15 10.354a.509.509 0 0 1 0-.708l3-2.996A.5.5 0 0 1 11 7v5.99a.5.5 0 0 1-.85.36zM20 10c0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10 10-4.477 10-10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows19 svgRef={ref} {...props} />);
export default ForwardRef;
