import React from 'react';

const SvgActions125 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 21 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20.853 17.152l-.7.7a.5.5 0 0 1-.71 0l-4-3.999a2.6 2.6 0 0 1-.3-.36l-.75-1a7.001 7.001 0 0 1-4.39 1.51 6.912 6.912 0 0 1-3-.69.49.49 0 0 1-.3-.31.51.51 0 0 1 .14-.44l.76-.759a.51.51 0 0 1 .54-.11 5.001 5.001 0 0 0 5.745-1.493 4.997 4.997 0 0 0-5.316-7.936 4.999 4.999 0 0 0-3.57 4.74h1.75a.5.5 0 0 1 .35.85l-2.74 2.75a.51.51 0 0 1-.71 0l-2.75-2.75a.5.5 0 0 1 .36-.85h1.74A7.001 7.001 0 0 1 15.81 3.092a6.996 6.996 0 0 1-.377 8.323l1 .69c.156.1.3.217.43.35l4 3.999a.5.5 0 0 1-.01.7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions125 svgRef={ref} {...props} />);
export default ForwardRef;
