import React from 'react';

const SvgCommunication41 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM18 12.83a.49.49 0 0 1-.5.5.48.48 0 0 1-.4-.2L12.65 7.2a.51.51 0 0 0-.8 0L9 10.91a.51.51 0 0 1-.4.2.49.49 0 0 1-.38-.18L6.74 9.18A.5.5 0 0 0 6.35 9a.52.52 0 0 0-.39.19l-3.07 3.94a.48.48 0 0 1-.39.19.49.49 0 0 1-.5-.5V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zM16 0H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication41 svgRef={ref} {...props} />);
export default ForwardRef;
