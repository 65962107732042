import K from '../constants';
import restHelpers from '../helpers/restHelper';

const getDataFromIdAdesioneFn = (idAdesione) => restHelpers.withBearer.get(`${K.API_URL}/move-in/memberships/${idAdesione}`, {});
const registerUser = (marketing_consent_accepted, id_adesione, email, emailType, mobile, vehicle, user, company, moveinUserReregistration, regionCodes, marketing_third_parties_consent_accepted) => {
  const data = { privacy_policy_accepted: true, marketing_consent_accepted, id_adesione, email, emailType, mobile, vehicle, user, company, moveinUserReregistration, regionCodes, marketing_third_parties_consent_accepted };
  return restHelpers.withBearer.post(`${K.API_URL}/move-in/registration`, { data });
};
const getInstallersFn = () => restHelpers.withBearer.get(`${K.API_URL}/move-in/installers`, {});
const getInstallersByProvinceFn = (province) => restHelpers.withBearer.get(`${K.API_URL}/move-in/installers?province=${province.prov}`, {});
const chooseInstallerFn = (userUuid, vehicleUuid, installerUuid) =>
  restHelpers.withBearer.post(
    `${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleUuid}/installers`,
      { data: {installer_identifier: installerUuid}}
      );


export default {
  getDataFromIdAdesione: getDataFromIdAdesioneFn,
  registerUser: registerUser,
  getInstallers: getInstallersFn,
  getInstallersByProvince: getInstallersByProvinceFn,
  chooseInstaller: chooseInstallerFn
};
