import React from 'react';

const SvgFiles22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.29.29l1.42 1.42a1 1 0 0 0 .7.29H18a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h7.59a1 1 0 0 1 .7.29zM2 4v10h16V4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles22 svgRef={ref} {...props} />);
export default ForwardRef;
