import React from 'react';

const SvgActions32 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.09 10.86a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0L9 10.24l-1.86 1.85a.48.48 0 0 1-.7 0l-.53-.53a.48.48 0 0 1 0-.7L7.76 9 5.91 7.14a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0L9 7.76l1.86-1.85a.48.48 0 0 1 .7 0l.53.53a.48.48 0 0 1 0 .7L10.24 9zM16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions32 svgRef={ref} {...props} />);
export default ForwardRef;
