import React from 'react';

const SvgActions76 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 17" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.86 6.652a.5.5 0 0 1 0-.71L6.65.152a.48.48 0 0 1 .7 0l5.79 5.79a.5.5 0 0 1 0 .71l-.2.2a.49.49 0 0 1-.36.15H10v5.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-5.5H1.42a.49.49 0 0 1-.36-.15zM14 15.502v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions76 svgRef={ref} {...props} />);
export default ForwardRef;
