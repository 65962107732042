import React from 'react';

const SvgActions33 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11.848 10.438a.5.5 0 0 1 0 .71l-.7.7a.5.5 0 0 1-.71 0l-4.44-4.44-4.44 4.44a.5.5 0 0 1-.71 0l-.7-.7a.5.5 0 0 1 0-.71l4.44-4.44-4.44-4.44a.5.5 0 0 1 0-.71l.7-.7a.5.5 0 0 1 .71 0l4.44 4.44 4.44-4.44a.5.5 0 0 1 .71 0l.7.7a.5.5 0 0 1 0 .71l-4.44 4.44z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions33 svgRef={ref} {...props} />);
export default ForwardRef;
