import React from 'react';

const SvgActions88 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.5 14.88a.5.5 0 0 0 .5-.5v-.76a.5.5 0 0 0-.5-.5h-1.62V11.5a.5.5 0 0 0-.5-.5h-.76a.5.5 0 0 0-.5.5v1.62H8.5a.5.5 0 0 0-.5.5v.76a.5.5 0 0 0 .5.5h1.62v1.62a.5.5 0 0 0 .5.5h.76a.5.5 0 0 0 .5-.5v-1.62zm8.47-5.38a.5.5 0 0 1-.5.5H21l-1.68 7.65A3 3 0 0 1 16.4 20H5.6a3 3 0 0 1-2.89-2.35L1 10H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 8h4L10.27.3a.77.77 0 0 1 .6-.3h.26a.77.77 0 0 1 .6.3L17.5 8h4a.5.5 0 0 1 .5.5zM15 8l-4-5.33L7 8z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions88 svgRef={ref} {...props} />);
export default ForwardRef;
