import React from 'react';

const SvgActions43 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M.15 7.652a.5.5 0 0 1 0-.71l6.5-6.79a.48.48 0 0 1 .7 0l6.5 6.79a.5.5 0 0 1 0 .71l-.2.2a.49.49 0 0 1-.36.15H.71a.49.49 0 0 1-.36-.15zM14 10.502v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions43 svgRef={ref} {...props} />);
export default ForwardRef;
