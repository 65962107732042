import React from 'react';

const SvgCommunication9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM18 12.83a.49.49 0 0 1-.5.5.48.48 0 0 1-.4-.2L12.65 7.2a.51.51 0 0 0-.8 0L9 10.91a.51.51 0 0 1-.4.2.49.49 0 0 1-.38-.18L6.74 9.18A.5.5 0 0 0 6.35 9a.52.52 0 0 0-.39.19l-3.07 3.94a.48.48 0 0 1-.39.19.49.49 0 0 1-.5-.5V2h16zM18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5.09a1 1 0 0 1 .7.29l1.49 1.49c.14.14.331.22.53.22h.38a.75.75 0 0 0 .53-.22l1.49-1.49a1 1 0 0 1 .7-.29H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication9 svgRef={ref} {...props} />);
export default ForwardRef;
