import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Form, Radio } from 'antd';
import AirModal from '../../../../../common/components/AirModal';
import './AdditionServiceConditions.css';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageButton from '../../../../../common/components/MyPageButton';
import K from "../../../../../constants";

@inject('translationsStore', 'moveinStore')
@observer
export default class AdditionServiceConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedAll: undefined,
      generalConditionsVisible: false,
      privacyVisible: false
    };
  }

  toggleAcceptAll = () => {
    this.setState(prevState => {
      const isAccepted = !prevState.acceptedAll ? true : undefined;

      this.props.form.setFieldsValue({ air_club_profiling: isAccepted, air_club_marketing: isAccepted, air_club_third_parties: isAccepted });
      return {
        acceptedAll: !!isAccepted
      };
    });
  };

  unFlagAcceptAllIfChangedAfterAcceptedAll = () => {
    this.setState(prevState => {
      if (prevState.acceptedAll) {
        return {
          acceptedAll: false
        };
      }
    });
  };

  onSubmit = () => {
    const { moveinStore, form } = this.props;
    const { getFieldsValue } = form;

    const airclub_general = getFieldsValue(['airclub_general'])['airclub_general'];
    const air_club_profiling = getFieldsValue(['air_club_profiling'])['air_club_profiling'];
    const air_club_marketing = getFieldsValue(['air_club_marketing'])['air_club_marketing'];
    const air_club_third_parties = getFieldsValue(['air_club_third_parties'])['air_club_third_parties'];

    moveinStore.setVehicleAdditionalServiceConsent({ reminder_product_consent: true, airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties });

    this.props.onSubmit();
  };

  render() {
    const { translationsStore, form, isSubmitWithAdditionalServiceDisabled } = this.props;
    const { acceptedAll } = this.state;
    return (
      <div className={'OptionalServicesView'}>
        <div style={{ display: 'flex' }}>
          <div className="OptionalServicePrivacy">
            <div className={'GeneralConditionsContainer'}>
              <div className={'GeneralConditionRow'}>
                <Form.Item>
                  {form.getFieldDecorator('airclub_general', {
                    valuePropName: 'checked',
                    initialValue: undefined
                  })(<Checkbox />)}
                </Form.Item>
                <h4 className="PrivacyHeadTitle">
                  Confermo di aver preso visione e di accettare integralmente le{' '}
                  <span onClick={() => this.setState({ generalConditionsVisible: true })} style={{ fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer' }}>
                    Condizioni Generali per l'Adesione ai Servizi Aggiuntivi AIR
                  </span>
                  .
                </h4>
              </div>
              <div className={'GeneralConditionRow'}>
                <Form.Item>
                  {form.getFieldDecorator('carefully_read', {
                    valuePropName: 'checked',
                    initialValue: undefined
                  })(<Checkbox />)}
                </Form.Item>
                <h4>
                  Ai sensi e per gli effetti di cui agli articoli 1341 e 1342 Codice Civile, dichiaro di aver letto attentamente e di approvare specificatamente le seguenti
                  clausole di cui alle Condizioni Generali: 1.4; 1.5; 2.6; 2.11; 2.12; 3.2, 3.3; 5.3, 5.4; 6.2; 8; 10; 15.2.
                </h4>
              </div>
            </div>
            <h4 style={{ marginTop: 16 }}>
              Dichiaro di aver preso visione e di aver letto attentamente l'
              <span onClick={() => this.setState({ privacyVisible: true })} style={{ fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer' }}>
                informativa privacy di AIR
              </span>
            </h4>
            <p>
              <b>Consenso al trattamento dei dati personali</b>
            </p>
            <p>
              Il sottoscritto, informato in merito al trattamento dei propri dati personali da parte di AIR S.r.l. (la “Società”), come da informativa da quest’ultima predisposta
              ai sensi dell’art. 13 del Regolamento UE 2016/679 che conferma di aver letto e ben compreso, conferma di aver preso atto delle conseguenze del rifiuto a conferire i
              Dati Personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in qualsiasi momento. Inoltre il sottoscritto dichiara quanto segue:
            </p>
            <p>
              Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per l’individuazione e lo studio dei dati sulle concrete modalità di utilizzo del veicolo,
              quali anche acquisiti tramite Dispositivo di cui all’articolo 2 lett. e) dell’informativa (profilazione):
            </p>
            <div className={'CheckboxContainer'}>
              <Form.Item>
                {form.getFieldDecorator('air_club_profiling', {
                  initialValue: undefined
                })(
                  <Radio.Group onChange={this.unFlagAcceptAllIfChangedAfterAcceptedAll}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
            <p>
              Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per attività promozionali e di vendita di cui all’articolo 2 lett. f) dell’informativa
              (marketing): comunicazione ed iniziative promozionali e di vendita di prodotti e/o servizi della società o di soggetti terzi appartenenti alle categorie sopra
              menzionate via email, SMS o altri sistemi automatizzati e per posta, nonché per ricerche di mercato e rilevazioni statistiche. Il consenso per marketing deve
              intendersi esteso anche alle modalità tradizionali di contatto:
            </p>
            <div className={'CheckboxContainer'}>
              <Form.Item>
                {form.getFieldDecorator('air_club_marketing', {
                  initialValue: undefined
                })(
                  <Radio.Group onChange={this.unFlagAcceptAllIfChangedAfterAcceptedAll}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
            <p>
              Con riferimento al trasferimento dei Dati Personali, compresi quelli raccolti tramite Dispositivo, a soggetti terzi (imprese di assicurazione, broker, agenti
              assicurativi, banche e intermediari finanziari, operatori del settore automobilistico e della componentistica, carrozzerie e autofficine, fornitori di prodotti e
              servizi relativi al settore automotive, compresi fornitori di servizi di navigazione e viabilità), per lo svolgimento da parte di questi ultimi di finalità loro
              proprie, ove del caso anche di marketing, come indicato all’articolo 2 lett. g) dell’informativa:
            </p>
            <div className={'CheckboxContainer'}>
              <Form.Item>
                {form.getFieldDecorator('air_club_third_parties', {
                  initialValue: undefined
                })(
                  <Radio.Group onChange={this.unFlagAcceptAllIfChangedAfterAcceptedAll}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
            <div className={'LineSeparator'} />
            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '14px' }}>
              <Checkbox className="OptionalServicePrivacyCheck" onChange={this.toggleAcceptAll} checked={!!acceptedAll} />
              <p>Cliccando qui date il vostro consenso a tutti e tre i trattamenti che precedono</p>
            </div>
          </div>
        </div>
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) =>
            breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
              <>
                <div className={'AdditionalServiceProceedButtonContainer'}>
                  <div className={'AdditionalServiceSelectButtonContainer'}>
                    <div style={{ zIndex: 3 }}>
                      <MyPageButton
                        theme="brand"
                        className={'AdditionalServiceSelectButton'}
                        solid
                        disabled={isSubmitWithAdditionalServiceDisabled}
                        title={translationsStore.translate('movein.stepRegistration.freeService.proceedToSelect')}
                        onClick={this.onSubmit}
                      />
                    </div>
                  </div>
                  <div className={'AdditionalServiceDontSelectButtonContainer'}>
                    <MyPageButton
                      theme="gray"
                      className={'AdditionalServiceDontSelectButton'}
                      solid
                      title={translationsStore.translate('movein.stepRegistration.freeService.proceedWithout')}
                      onClick={this.props.onCancelFreeService}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={'AdditionalServiceProceedButtonsMobileContainer'}>
                  <MyPageButton
                    theme="brand"
                    className={'AdditionalServiceSelectButton'}
                    style={{ fontWeight: 600 }}
                    solid
                    disabled={isSubmitWithAdditionalServiceDisabled}
                    title={translationsStore.translate('movein.stepRegistration.freeService.proceedToSelect')}
                    onClick={this.onSubmit}
                  />
                  <div>
                    <MyPageButton
                      theme="gray"
                      className={'AdditionalServiceDontSelectMobileButton'}
                      style={{ fontWeight: 600 }}
                      solid
                      title={translationsStore.translate('movein.stepRegistration.freeService.proceedWithout')}
                      onClick={this.props.onCancelFreeService}
                    />
                  </div>
                </div>
              </>
            )
          }
        </BreakpointsMedia>

        <AirModal
          className="EmbeddedDocumentModal"
          width="90vw"
          visible={this.state.generalConditionsVisible}
          onCancel={() => {
            this.setState({ generalConditionsVisible: false });
          }}
        >
          <iframe src={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')} width="100%" height="540" />
        </AirModal>
        <AirModal
          className="EmbeddedDocumentModal"
          width="90vw"
          visible={this.state.privacyVisible}
          onCancel={() => {
            this.setState({ privacyVisible: false });
          }}
        >
          <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540" />
        </AirModal>
      </div>
    );
  }
}
