import React from 'react';

const SvgCommunication45 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 15" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7.002 12.578a.49.49 0 0 1-.15.36l-.2.2a.5.5 0 0 1-.71 0l-5.79-5.79a.48.48 0 0 1 0-.7l5.79-5.79a.5.5 0 0 1 .71 0l.2.2a.49.49 0 0 1 .15.36v2.58a11.22 11.22 0 0 1 11 10.54.5.5 0 0 1-.12.33.46.46 0 0 1-.32.13h-.1a.5.5 0 0 1-.49-.41c-.47-2.59-5.25-4.59-9.97-4.59z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication45 svgRef={ref} {...props} />);
export default ForwardRef;
