import React from 'react';

const SvgArrows32 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 12 7" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M6.718 6.778a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22l-5.13-5.14a.5.5 0 0 1 0-.71l.71-.71a.49.49 0 0 1 .7 0l4.44 4.45 4.44-4.45a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows32 svgRef={ref} {...props} />);
export default ForwardRef;
