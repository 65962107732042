import React from 'react';

const SvgUser14 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.895 14.104c.108.095.172.23.177.373v3.883a.49.49 0 0 1-.85.35l-1-.998a7.011 7.011 0 0 1-6.95 2.062 6.995 6.995 0 0 1-5.055-5.187.528.528 0 0 1 .1-.42.49.49 0 0 1 .39-.19h1a.5.5 0 0 1 .481.32 5.008 5.008 0 0 0 8.533 1.997l-1.46-1.468a.498.498 0 0 1 .35-.848h3.89a.52.52 0 0 1 .394.126zm2.957-2.6a1 1 0 0 1-.85.477h-4.18a2.99 2.99 0 0 0-.13-2.347l-.951-1.886a4.88 4.88 0 0 0-.48-.76h3c1.134 0 2.172.638 2.682 1.648l.95 1.897a.997.997 0 0 1-.04.972zm-7.122-7.24A2.498 2.498 0 0 1 12.5 0a2.498 2.498 0 0 1 2.501 2.496 2.498 2.498 0 0 1-2.5 2.496 2.493 2.493 0 0 1-1.772-.728zm.12 7.24a1 1 0 0 1-.85.477H.997a1 1 0 0 1-.89-1.448l.95-1.897A3.002 3.002 0 0 1 3.738 6.99H7.26a3.002 3.002 0 0 1 2.63 1.646l1 1.897a.997.997 0 0 1-.04.972zM2.998 2.497A2.498 2.498 0 0 1 5.498 0 2.499 2.499 0 0 1 8 2.496a2.503 2.503 0 1 1-4.27 1.765 2.494 2.494 0 0 1-.732-1.765z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgUser14 svgRef={ref} {...props} />);
export default ForwardRef;
