import React from 'react';

const SvgAbstract56 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 14" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M17.5 14H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zm0-6H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h17a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zm0-6H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 0h17a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract56 svgRef={ref} {...props} />);
export default ForwardRef;
