import React from 'react';

const SvgOther2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15 18H3v-2.13a4 4 0 0 0 2.26-1.59 4.93 4.93 0 0 0 7.48 0A4 4 0 0 0 15 15.87zm1-11h-6V4.72A2 2 0 0 0 11 3 6.65 6.65 0 0 0 9.54.2a.48.48 0 0 0-.4-.2h-.28a.48.48 0 0 0-.4.2A6.65 6.65 0 0 0 7 3a2 2 0 0 0 1 1.72V7H2a2 2 0 0 0-2 2v6a1 1 0 0 0 1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther2 svgRef={ref} {...props} />);
export default ForwardRef;
