import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import './PrivacyPolicyModal.css';
import { Form, Input, Spin } from 'antd';
import AirModal from '../../../../../../common/components/AirModal';
import K from '../../../../../../constants'

const NaturalPersonPrivacyText = () => (
  <Fragment>
    <div className="title">Informativa sulla privacy</div>
    <div>
      <p>
        Informato in merito al trattamento dei propri dati personali da parte AIR S.r.l. (il "*Titolare*"), come da informativa da quest'ultima predisposta ai sensi dell'art. 13
        del Regolamento UE 2016/679 relativamente al Progetto MOVE-IN e riportata sul sito http://movein.regione.lombardia.it/, che confermo di aver visionato e ben compreso,
        confermo di aver preso atto delle conseguenze del rifiuto a conferire i dati personali per determinati trattamenti e di{' '}
        <b>essere consapevole che il consenso è revocabile in qualsiasi momento</b>.
      </p>
      <p>
        Inoltre, dichiaro quanto segue:
        <ul style={{ paddingLeft: 20 }}>
          <li>
            con riferimento al trattamento dei dati da parte di AIR S.r.l. per attività promozionali e di vendita di cui al punto 4 del paragrafo 2 dell'informativa (
            <i>marketing</i>): comunicazione ed iniziative promozionali e di vendita di prodotti e/o servizi del Titolare o di soggetti terzi appartenenti alle categorie sopra
            menzionate via email, SMS o altri sistemi automatizzati e per posta, o mediante offerta tramite il sito www.myair.io nonché per ricerche di mercato e rilevazioni
            statistiche. Il consenso per marketing deve intendersi esteso anche alle modalità tradizionali di contatto.
          </li>
        </ul>
      </p>
    </div>
  </Fragment>
);

const LegalPersonPrivacyText = () => (
  <Fragment>
    <div className="title">Informativa sulla privacy</div>
    <div>
      <p>
        Informato in merito al trattamento dei propri dati personali da parte AIR S.r.l. (il "*Titolare*"), come da informativa da quest'ultima predisposta ai sensi dell’art. 13
        del Regolamento UE 2016/679 relativamente al Progetto MOVE-IN e riportata sul sito http://movein.regione.lombardia.it/, che confermo di aver visionato e ben compreso,
        confermo di aver preso atto delle conseguenze del rifiuto a conferire i dati personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in
        qualsiasi momento.
      </p>
      <p>
        Inoltre, dichiaro quanto segue:
        <ul style={{ paddingLeft: 20 }}>
          <li>
            con riferimento al trattamento dei dati da parte di AIR S.r.l. per attività promozionali e di vendita di cui al punto 4 del paragrafo 2 dell'informativa (<i>marketing</i>):
            comunicazione ed iniziative promozionali e di vendita di prodotti e/o servizi del Titolare o di soggetti terzi appartenenti alle categorie sopra menzionate via email,
            SMS o altri sistemi automatizzati e per posta, o mediante offerta tramite il sito www.myair.io nonché per ricerche di mercato e rilevazioni statistiche. Il consenso per
            marketing deve intendersi esteso anche alle modalità tradizionali di contatto.
          </li>
        </ul>
      </p>
    </div>
  </Fragment>
);

//TODO Move and replace text of privacy policy
@inject('translationsStore', 'moveinStore')
@observer
class PrivacyPolicyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visiblePrivacyPolicyModal: true,
      done: false,
      visible: this.props.visible
    };
  }

  onCancel = e => {
    this.props.onCancel(e);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { translationsStore, assetName = 'marketing-policy.pdf' } = this.props;

    return (
      <AirModal className="PrivacyPolicyModal" width="90vw" visible={this.props.visible} onOk={this.handleOk} onCancel={this.onCancel}>
        <iframe src={K.POLICY_BASE_URL.concat(assetName)} width="100%" height="540">
        </iframe>
      </AirModal>
    );
  }
}

export default Form.create()(PrivacyPolicyModal);
