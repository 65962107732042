import React from 'react';

const SvgOther32 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M11 5a1 1 0 0 1-.38.78L9.15 7h-4.3L3.38 5.82A1 1 0 0 1 3 5V2h8zm-.38 9.14A1 1 0 0 1 11 15H3a1 1 0 0 1 .38-.78L7 11.28zM13 5V2h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H1v3a3 3 0 0 0 1.13 2.38L5.4 10l-3.27 2.62A3 3 0 0 0 1 15v3H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H13v-3a3 3 0 0 0-1.13-2.34L8.6 10l3.27-2.62A3 3 0 0 0 13 5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther32 svgRef={ref} {...props} />);
export default ForwardRef;
