import React from 'react';

const SvgPremium18 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14.351 7.739L8.73 13.367a.48.48 0 0 1-.7 0l-2.381-2.354a.501.501 0 0 1 0-.71l.53-.532a.48.48 0 0 1 .7 0l1.5 1.493 4.742-4.747a.5.5 0 0 1 .7 0l.53.53a.501.501 0 0 1 0 .692zm5.262 3.428a3.199 3.199 0 0 1 0-2.32l.17-.46a3.27 3.27 0 0 0-1.71-4.15l-.45-.23a3.31 3.31 0 0 1-1.621-1.62l-.2-.45A3.271 3.271 0 0 0 11.65.227l-.46.17c-.747.29-1.575.29-2.321 0l-.46-.17a3.271 3.271 0 0 0-4.182 1.71l-.23.45a3.31 3.31 0 0 1-1.62 1.62l-.45.2a3.27 3.27 0 0 0-1.711 4.15l.17.46c.29.746.29 1.574 0 2.32l-.17.46a3.27 3.27 0 0 0 1.71 4.15l.45.2a3.31 3.31 0 0 1 1.621 1.68l.2.45a3.271 3.271 0 0 0 4.151 1.71l.46-.17a3.202 3.202 0 0 1 2.321 0l.46.17a3.272 3.272 0 0 0 4.152-1.71l.2-.45a3.31 3.31 0 0 1 1.68-1.62l.45-.2a3.27 3.27 0 0 0 1.711-4.15z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium18 svgRef={ref} {...props} />);
export default ForwardRef;
