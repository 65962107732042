import React from 'react';

const SvgFiles9 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M14 18H2V8h5a1 1 0 0 0 1-1V2h6zM2 20h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H7.24a3 3 0 0 0-2.12.88L.88 5.12A3 3 0 0 0 0 7.24V18a2 2 0 0 0 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles9 svgRef={ref} {...props} />);
export default ForwardRef;
