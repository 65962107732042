import React from 'react';

const SvgAbstract62 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 4 16" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0-6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract62 svgRef={ref} {...props} />);
export default ForwardRef;
