import React from 'react';

const SvgPayment7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 14V9h10a4.93 4.93 0 0 0 5 .58V14zM2 3h8.42A4.94 4.94 0 0 0 10 5c.003.336.036.67.1 1H2zm12.12-.5a.5.5 0 0 1 .5-.5h.76a.5.5 0 0 1 .5.5v1.62h1.62a.5.5 0 0 1 .5.5v.76a.5.5 0 0 1-.5.5h-1.62V7.5a.5.5 0 0 1-.5.5h-.76a.5.5 0 0 1-.5-.5V5.88H12.5a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h1.62zM15 0a4.94 4.94 0 0 0-3 1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2V8a4.94 4.94 0 0 0 1-3 5 5 0 0 0-5-5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPayment7 svgRef={ref} {...props} />);
export default ForwardRef;
