import React from 'react';

const SvgAlerts7 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.772 9.78a.25.25 0 0 1-.249.22H9.467a.25.25 0 0 1-.249-.22L8.82 6.56A.501.501 0 0 1 9.318 6h1.354a.497.497 0 0 1 .498.56zM11 13.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm8.613-2.34a3.199 3.199 0 0 1 0-2.32l.17-.46a3.27 3.27 0 0 0-1.71-4.15l-.45-.23a3.31 3.31 0 0 1-1.621-1.62l-.2-.45A3.261 3.261 0 0 0 11.65.22l-.46.17c-.38.142-.785.213-1.19.21-.396 0-.79-.071-1.16-.21L8.38.22a3.261 3.261 0 0 0-4.152 1.71l-.23.45A3.31 3.31 0 0 1 2.378 4l-.45.2A3.27 3.27 0 0 0 .216 8.35l.17.46c.29.746.29 1.573 0 2.32l-.17.46a3.27 3.27 0 0 0 1.71 4.15l.45.2a3.31 3.31 0 0 1 1.621 1.68l.2.45a3.261 3.261 0 0 0 4.151 1.71l.46-.17a3.312 3.312 0 0 1 2.321 0l.46.17a3.261 3.261 0 0 0 4.152-1.71l.2-.45a3.31 3.31 0 0 1 1.68-1.62l.45-.2a3.27 3.27 0 0 0 1.711-4.15z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAlerts7 svgRef={ref} {...props} />);
export default ForwardRef;
