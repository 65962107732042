import React from 'react';

const SvgCommunication60 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M17 11.5a.5.5 0 0 1-.5.5h-.09a1 1 0 0 1-.7-.29L13 9v2.5a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 3 11.5v-7A1.5 1.5 0 0 1 4.5 3h7A1.5 1.5 0 0 1 13 4.5V7l2.71-2.71a1 1 0 0 1 .7-.29h.09a.5.5 0 0 1 .5.5zM20 4a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v15.5a.5.5 0 0 0 .5.5.49.49 0 0 0 .35-.15l3-3A3 3 0 0 1 6 16h10a4 4 0 0 0 4-4z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication60 svgRef={ref} {...props} />);
export default ForwardRef;
