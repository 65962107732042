import React from 'react';

const SvgFiles1 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M2 2h12v10H9a1 1 0 0 0-1 1v5H2zm12-2H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h6.76a3 3 0 0 0 2.12-.88l4.24-4.24a3 3 0 0 0 .88-2.12V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles1 svgRef={ref} {...props} />);
export default ForwardRef;
