import React, { Component } from 'react';
import './WhereIsIdAdesioneModal.css';
import AirModal from '../../../../../common/components/AirModal';
import {inject, observer} from "mobx-react";
import {withWindowSize} from "air-with-window-size";

@inject('translationsStore')
@observer
class WhereIsIdAdesioneModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onCancel = e => {
    this.props.onCancel(e);
  };

  render() {
    const { translationsStore, windowWidth } = this.props;

    return (
      <div>
        <AirModal
          className="WhereIsIdAdesioneModal"
          width={windowWidth > 840 ? "40vw" : "90vw"}
          visible={this.props.visible}
          onCancel={this.onCancel}
          title={translationsStore.translate('movein.stepWelcome.WhereIsIdAdesioneModal.title')}
          subtitle={translationsStore.translate('movein.stepWelcome.WhereIsIdAdesioneModal.subTitle')}
        >
          <div className="Subtitle">{translationsStore.translate('movein.stepWelcome.WhereIsIdAdesioneModal.subTitle')}</div>
          <div className={'WhereIsIdAdesioneImagesContainer'}>
            <img className={'WhereIsIdAdesioneImage'} src={'/WhereIsIdAdesioneModalAssets/AttachedDocumentInRegionEmail.png'} alt={'AttachedDocumentInRegionEmail'}/>
            <img className={'WhereIsIdAdesioneImage'} style={{marginTop: '15px'}} src={'/WhereIsIdAdesioneModalAssets/EmailFromRegion.png'} alt={'EmailFromRegion'}/>
          </div>
        </AirModal>
      </div>

    );
  }
}

export default withWindowSize(WhereIsIdAdesioneModal);
