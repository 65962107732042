import React from 'react';

const SvgFiles8 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10.5 13.88H8.88v1.62a.5.5 0 0 1-.5.5h-.76a.5.5 0 0 1-.5-.5v-1.62H5.5a.5.5 0 0 1-.5-.5v-.76a.5.5 0 0 1 .5-.5h1.62V10.5a.5.5 0 0 1 .5-.5h.76a.5.5 0 0 1 .5.5v1.62h1.62a.5.5 0 0 1 .5.5v.76a.5.5 0 0 1-.5.5zM1.82 7L7 1.82V6a1 1 0 0 1-1 1zM14 0H6.83a2 2 0 0 0-1.42.59L.59 5.41A2 2 0 0 0 0 6.83V18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles8 svgRef={ref} {...props} />);
export default ForwardRef;
