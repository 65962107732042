import React from 'react';

const SvgDevices57 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M10 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4.58-3.58a.5.5 0 0 1-.69 0A6 6 0 0 0 10.48 4a.5.5 0 0 1-.48-.47v-1a.5.5 0 0 1 .52-.5 8 8 0 0 1 4.75 2 .5.5 0 0 1 .02.68zM10 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 3.47a.5.5 0 0 1-.52.5A8 8 0 0 1 4.73 16a.5.5 0 0 1 0-.73l.71-.71a.5.5 0 0 1 .69 0A6 6 0 0 0 9.52 16a.5.5 0 0 1 .48.49zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices57 svgRef={ref} {...props} />);
export default ForwardRef;
