import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AppBrand from '../../../components/AppBrand';
import NumeroVerde from '../NumeroVerde';
import NumeroMobile from '../NumeroMobile';
import LogoRegioneLombardia from '../../icons/LogoRegioneLombardia';
import LogoRegionePiemonte from '../../icons/LogoRegionePiemonte';

import './MoVeInDesktopHeader.css';

@withRouter
@inject('translationsStore', 'moveinStore')
@observer
export default class MoVeInDesktopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportInTollFreeVersion: true
    };
  }

  componentDidMount = () => {
    this.timeoutCheckSupportVersion = setTimeout(this.toggleSupportVersionFn, 5000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timeoutCheckSupportVersion);
  };

  toggleSupportVersionFn = () => {
    this.setState({ supportInTollFreeVersion: !this.state.supportInTollFreeVersion }, () => {
      const timeout = this.state.supportInTollFreeVersion ? 5000 : 15000;
      this.timeoutCheckSupportVersion = setTimeout(this.toggleSupportVersionFn, timeout);
    });
  };

  onSupportClick = () => {
    const { supportInTollFreeVersion } = this.state;
    const url = supportInTollFreeVersion ? 'tel:800101222' : 'tel:+393459782557';
    // window.location.href = url;
    window.open(url, '_blank');
    return null;
  };

  render() {
    const { translationsStore, moveinStore, history } = this.props;
    const { supportInTollFreeVersion } = this.state;

    const cls = classNames({
      MoVeInDesktopHeader: true,
      [this.props.className]: !!this.props.className
    });

    return (
      <div className={cls}>
        <AppBrand style={{ color: 'var(--action-brand-color)', fontSize: 128 }} />
        {supportInTollFreeVersion ? <NumeroVerde width={160} height={80} onClick={this.onSupportClick} /> : <NumeroMobile width={160} height={80} onClick={this.onSupportClick} />}
      </div>
    );
  }
}
