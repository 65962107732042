import React from 'react';

const SvgPremium4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 4.33l-2 2a.49.49 0 0 1-.36.15h-1.26a.49.49 0 0 1-.36-.15l-4-4-4 4a.49.49 0 0 1-.36.15H4.38A.49.49 0 0 1 4 6.35l-2-2V10h16zm2-2.83v10a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 .5 1h.38a1.5 1.5 0 0 1 1.06.44L5 4.5 9.28.22A.75.75 0 0 1 9.81 0h.38c.199 0 .39.08.53.22L15 4.5l3.06-3.06A1.5 1.5 0 0 1 19.12 1h.38a.5.5 0 0 1 .5.5zm0 13v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgPremium4 svgRef={ref} {...props} />);
export default ForwardRef;
