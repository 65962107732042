import React from 'react';

const SvgOther57 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 14 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M7 2.007c2.762 0 5 2.236 5 4.993a4.996 4.996 0 0 1-5 4.993c-2.76 0-5-2.235-5-4.993a4.996 4.996 0 0 1 5-4.992zM7.34.008A6.998 6.998 0 0 0 6 13.922v2.08H4.5a.5.5 0 0 0-.5.5v.999a.5.5 0 0 0 .5.5H6v1.5a.5.5 0 0 0 .5.499h1a.5.5 0 0 0 .5-.5V18h1.5a.5.5 0 0 0 .5-.499v-1a.5.5 0 0 0-.5-.5H8v-2.078A6.998 6.998 0 0 0 7.34.008z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther57 svgRef={ref} {...props} />);
export default ForwardRef;
