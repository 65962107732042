import React from 'react';

const SvgDevices31 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M3.678 5.27l-2.12-2.12a.5.5 0 0 0-.71 0l-.7.7a.5.5 0 0 0 0 .71l2.12 2.12a.48.48 0 0 0 .7 0l.71-.68a.48.48 0 0 0 0-.73zm11.47-2.12a.5.5 0 0 0-.71 0l-2.12 2.12a.48.48 0 0 0 0 .7l.71.71a.48.48 0 0 0 .7 0l2.12-2.12a.5.5 0 0 0 0-.71zM7.498 4h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5zm1.5 13.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5zm4-8a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v1.67a2 2 0 0 0 .59 1.42l1.12 1.12a1 1 0 0 1 .29.7v7.09a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7.09a1 1 0 0 1 .29-.7l1.12-1.12a2 2 0 0 0 .59-1.42z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices31 svgRef={ref} {...props} />);
export default ForwardRef;
