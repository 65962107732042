import React from 'react';

const SvgActions120 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 4H2V2h14zm-3.12 6.39l-.21.21a.5.5 0 0 1-.35.14h-1.327v3.76a.5.5 0 0 1-.5.5H7.497a.5.5 0 0 1-.5-.5v-3.76H5.67a.5.5 0 0 1-.35-.14l-.21-.21a.51.51 0 0 1 0-.71l3.536-3.53a.48.48 0 0 1 .7 0l3.535 3.53a.51.51 0 0 1 0 .71zM16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions120 svgRef={ref} {...props} />);
export default ForwardRef;
