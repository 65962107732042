import React from 'react';

const SvgDevices22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 12v4a2 2 0 0 1-2 2h-2a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1V7A5 5 0 0 0 4 7v3a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2V7a7 7 0 0 1 14 0v3a2 2 0 0 1 2 2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices22 svgRef={ref} {...props} />);
export default ForwardRef;
