import React from 'react';

const SvgAbstract6 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.942.72A2.994 2.994 0 0 0 .72 4.944a.28.28 0 0 0 .19.08.24.24 0 0 0 .18-.07L4.961 1.09a.24.24 0 0 0 .06-.18.28.28 0 0 0-.08-.19zM17.001.031a2.994 2.994 0 0 0-1.917.69.28.28 0 0 0-.09.189.24.24 0 0 0 .07.18l3.873 3.874a.24.24 0 0 0 .18.07.28.28 0 0 0 .19-.09A2.996 2.996 0 0 0 17 .03zm-7.52 12.98a.498.498 0 0 0-.496.5v.998c0 .275.222.499.497.499h.993a.498.498 0 0 0 .496-.5v-.998a.498.498 0 0 0-.466-.499zm.003-1.997h1.058a.25.25 0 0 0 .25-.22l.4-3.215a.5.5 0 0 0-.5-.559H9.334a.5.5 0 0 0-.499.56l.4 3.214a.25.25 0 0 0 .25.22zm-6.458-.005a6.986 6.986 0 0 1 6.987-6.984 6.986 6.986 0 0 1 6.988 6.984 6.986 6.986 0 0 1-6.988 6.984 6.986 6.986 0 0 1-6.987-6.984zm6.987-8.98a8.984 8.984 0 0 0-8.983 8.985 8.985 8.985 0 0 0 17.967 0 8.985 8.985 0 0 0-8.984-8.986z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract6 svgRef={ref} {...props} />);
export default ForwardRef;
