import React from 'react';

const SvgArrows22 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 8" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M0 4.493v-1a.5.5 0 0 1 .5-.5h15.505v-2a.5.5 0 0 1 .85-.35l3.001 3a.51.51 0 0 1 0 .71l-3 3a.5.5 0 0 1-.851-.36v-2H.5a.5.5 0 0 1-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgArrows22 svgRef={ref} {...props} />);
export default ForwardRef;
