import React from 'react';

const SvgCommunication11 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 2v12a2 2 0 0 1-2 2h-5.09a1 1 0 0 0-.7.29l-1.49 1.49a.75.75 0 0 1-.53.22h-.38a.75.75 0 0 1-.53-.22l-1.49-1.49a1 1 0 0 0-.7-.29H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2zm-2 0H2v12h5.09a3 3 0 0 1 2.12.88l.79.79.79-.79a3 3 0 0 1 2.12-.88H18z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication11 svgRef={ref} {...props} />);
export default ForwardRef;
