import React from 'react';

const SvgAbstract48 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M18 16v-3.5c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5V16H2v-3.5c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9.7-2.2c.1.1.3.2.5.2h.4c.2 0 .4-.1.5-.2l5.1-5.1c.2-.2.2-.5 0-.7l-.7-.7c-.2-.2-.5-.2-.7 0L10 10.7V.5c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v10.2L4.6 7.3C4.4 7 4 7 3.9 7.2l-.7.7c-.2.2-.2.5 0 .7z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract48 svgRef={ref} {...props} />);
export default ForwardRef;
