import React from 'react';

const SvgActions107 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M12.605 17.615a.51.51 0 0 1 0 .71L9.06 21.859a.5.5 0 0 1-.71 0l-.2-.2a.49.49 0 0 1-.151-.36v-2.292A8.009 8.009 0 0 1 1.07 15a8.003 8.003 0 0 1 .003-8.002.52.52 0 0 1 .37-.26.5.5 0 0 1 .42.14l.741.74a.51.51 0 0 1 .09.59 5.832 5.832 0 0 0-.7 2.793A6.005 6.005 0 0 0 8 17.005v-2.362a.49.49 0 0 1 .15-.36l.2-.2a.5.5 0 0 1 .711 0zM7.85.348a.49.49 0 0 1 .15.36v2.29A8.01 8.01 0 0 1 14.93 7a7.993 7.993 0 0 1-.003 7.997.52.52 0 0 1-.37.24.5.5 0 0 1-.42-.14l-.731-.73a.5.5 0 0 1-.09-.59c.453-.857.69-1.811.69-2.78 0-3.314-2.689-6-6.006-6v2.36a.49.49 0 0 1-.15.36l-.2.2a.5.5 0 0 1-.711 0l-3.544-3.53a.51.51 0 0 1 0-.71L6.94.148a.5.5 0 0 1 .71 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions107 svgRef={ref} {...props} />);
export default ForwardRef;
