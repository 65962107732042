import React from 'react';

const SvgOther27 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M16 9h-6V7h6zm-2 7h-4v-5h4zM4 11h4v5H4zM2 7h6v2H2zm2-4a1 1 0 0 1 1-1c1.09 0 3 1.91 3 3H6a2 2 0 0 1-2-2zm9-1a1 1 0 0 1 1 1 2 2 0 0 1-.88 1.66A2 2 0 0 1 12 5h-2c0-1.09 1.91-3 3-3zm3 3h-.54A4 4 0 0 0 16 3a3 3 0 0 0-3-3 5.67 5.67 0 0 0-4 2.44A5.67 5.67 0 0 0 5 0a3 3 0 0 0-3 3 4 4 0 0 0 .54 2H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h1v5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-5h1a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther27 svgRef={ref} {...props} />);
export default ForwardRef;
