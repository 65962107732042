import React from 'react';

const SvgActions30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M13.09 11.86a.48.48 0 0 1 0 .7l-.53.53a.48.48 0 0 1-.7 0L10 11.24l-1.86 1.85a.48.48 0 0 1-.7 0l-.53-.53a.48.48 0 0 1 0-.7L8.76 10 6.91 8.14a.48.48 0 0 1 0-.7l.53-.53a.48.48 0 0 1 .7 0L10 8.76l1.86-1.85a.48.48 0 0 1 .7 0l.53.53a.48.48 0 0 1 0 .7L11.24 10zM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions30 svgRef={ref} {...props} />);
export default ForwardRef;
