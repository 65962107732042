import React from 'react';

const SvgActions123 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M21.87 8.274l-.89-1.782.68-1.3a.491.491 0 0 0-.22-.671l-.889-.46a.489.489 0 0 0-.669.21l-.77 1.48c-.072.143-.11.301-.11.461v.57c0 .16.038.319.11.461l.87 1.762-.91 1.762c-.072.142-.11.3-.11.46v.57c0 .16.038.319.11.461l.77 1.492a.509.509 0 0 0 .68.21l.889-.46a.501.501 0 0 0 .21-.671l-.64-1.321.91-1.762c.072-.143.11-.3.109-.46v-.581a1.073 1.073 0 0 0-.13-.46zM2.118 4.27a.49.49 0 0 0-.669-.21l-.89.46a.49.49 0 0 0-.219.671L1 6.492.09 8.244a1.073 1.073 0 0 0-.09.46v.581c0 .16.037.318.11.46L1 11.508.32 12.82a.501.501 0 0 0 .21.67l.89.46a.509.509 0 0 0 .678-.21l.77-1.49c.08-.141.124-.3.13-.461v-.57c0-.16-.037-.319-.11-.461l-.89-1.752.91-1.752c.067-.147.098-.308.09-.47v-.57c0-.16-.037-.319-.11-.461zM14.997 16H6.994V2h8.003zm-.019-16H6.992a1.998 1.998 0 0 0-1.996 2v14c0 1.105.894 2 1.996 2h7.986a1.998 1.998 0 0 0 1.997-2V2c0-1.105-.894-2-1.997-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgActions123 svgRef={ref} {...props} />);
export default ForwardRef;
