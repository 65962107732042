import React from 'react';

const SvgDevices30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 24 16" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4 2h16v11H4zm19.5 12H22V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v13H.5a.5.5 0 0 0-.5.5v.5a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-.5a.5.5 0 0 0-.5-.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgDevices30 svgRef={ref} {...props} />);
export default ForwardRef;
