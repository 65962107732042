import React from 'react';

const SvgCommunication3 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 18 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.9 4.647l-3-2.996a.5.5 0 0 0-.9.35v1.997H9.5a.5.5 0 0 0-.5.5v.998a.5.5 0 0 0 .5.5H12v1.996a.5.5 0 0 0 .85.35l3-2.996a.509.509 0 0 0 .01-.699zm-1.23 13.355a2 2 0 0 0 1.42-.59l1.69-1.69a.75.75 0 0 0-.15-1.18l-3.86-2.25a1 1 0 0 0-1.21.16l-1.89 1.89a.5.5 0 0 1-.59.09l-.66-.36a13.53 13.53 0 0 1-5.5-5.49l-.35-.66a.5.5 0 0 1 .09-.59l1.89-1.89a1 1 0 0 0 .16-1.21L3.46.372a.75.75 0 0 0-1.18-.15L.59 1.912A2 2 0 0 0 0 3.332v.67a10.07 10.07 0 0 0 1.23 4.78l.25.45a18 18 0 0 0 7.33 7.33l.45.25a10.07 10.07 0 0 0 4.74 1.19z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication3 svgRef={ref} {...props} />);
export default ForwardRef;
