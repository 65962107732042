import React from 'react';

const SvgOther59 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 12" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M15.47 8.002A8 8 0 0 1 8 .532a.51.51 0 0 1 .13-.37.52.52 0 0 1 .37-.16h1a.5.5 0 0 1 .5.46 6 6 0 0 0 5.57 5.54.5.5 0 0 1 .46.5v1a.52.52 0 0 1-.16.37.51.51 0 0 1-.37.13zm3 0a.49.49 0 0 1-.47-.44 4 4 0 0 0-3.56-3.54.49.49 0 0 1-.44-.49v-1.04a.5.5 0 0 1 .4-.49 2 2 0 0 0 1.57-1.6.5.5 0 0 1 .49-.4h1a.49.49 0 0 1 .42.17.49.49 0 0 1 .12.4 4 4 0 0 1-1.08 2.18 6 6 0 0 1 3.08 4.7.56.56 0 0 1-.13.39.52.52 0 0 1-.37.16zM17 9.502v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h16a.5.5 0 0 1 .5.5zm3 0v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther59 svgRef={ref} {...props} />);
export default ForwardRef;
