import React from 'react';

const SvgAbstract69 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 18" ref={svgRef} {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path
      d="M10.002 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm9-4.94l-2.78-5a4 4 0 0 0-3.5-2.06h-5.44a4 4 0 0 0-3.5 2.06l-2.78 5a4 4 0 0 0 0 3.88l2.78 5a4 4 0 0 0 3.5 2.06h5.44a4 4 0 0 0 3.5-2.06l2.78-5a4 4 0 0 0 0-3.88z"
      fill="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgAbstract69 svgRef={ref} {...props} />);
export default ForwardRef;
