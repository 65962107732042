import React from 'react';

const SvgCommunication38 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 18" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M20 4v10a2 2 0 0 1-2 2H4a2 2 0 0 0 2 2h12a4 4 0 0 0 4-4V6a2 2 0 0 0-2-2zM9 10a2.83 2.83 0 0 1-1.65-.52L2 5.73V3.6l6.35 4.45c.39.271.91.271 1.3 0L16 3.6v2.13l-5.35 3.75A2.83 2.83 0 0 1 9 10zm9 2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgCommunication38 svgRef={ref} {...props} />);
export default ForwardRef;
