import React from 'react';

const SvgOther4 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M8 9.5a.5.5 0 0 1-.78.42l-1.94-1.3a.52.52 0 0 0-.56 0l-1.94 1.3A.5.5 0 0 1 2 9.5v-7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM2 17a1 1 0 0 1 1-1h11v2H3a1 1 0 0 1-1-1zM15 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12.5a.5.5 0 0 0 .5-.5V1a1 1 0 0 0-1-1z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther4 svgRef={ref} {...props} />);
export default ForwardRef;
