import React from 'react';

const SvgOther34 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 22 10" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M5 8a3 3 0 0 1 0-6c1.8 0 3.23 1.38 4.67 3C8.23 6.62 6.8 8 5 8zm12-8a6.86 6.86 0 0 0-4.79 2.24c.29.32.58.63.86.95.28.32.32.35.48.53A5.1 5.1 0 0 1 17 2a3 3 0 0 1 0 6c-1.8 0-3.23-1.38-4.67-3l-.58-.66-.75-.85C9.35 1.66 7.56 0 5 0a5 5 0 0 0 0 10c2.56 0 4.35-1.66 6-3.49C12.65 8.34 14.44 10 17 10a5 5 0 0 0 0-10z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther34 svgRef={ref} {...props} />);
export default ForwardRef;
