import React from 'react';

const SvgFiles2 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <path d="M4.5 10h4a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm0-4h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zm9.5 6H9a1 1 0 0 0-1 1v5H2V2h12zm0-12H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h6.76a3 3 0 0 0 2.12-.88l4.24-4.24a3 3 0 0 0 .88-2.12V2a2 2 0 0 0-2-2z" fill="currentColor" />
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles2 svgRef={ref} {...props} />);
export default ForwardRef;
